import DataTable from 'react-data-table-component';
import styled, { css } from "styled-components";

interface CellStepProps {
    color?: string;
}

interface RegisterDataProps {
    color?: string;
}

interface CellDueProps {
    color?: string;
    complete?: boolean;
}

interface TaskTagProps {
    color: string;
}

interface AggregationItemProps {
    color: string;
}

export const TaskHeaderLeft = styled.div`
  display: flex;
  justify-content: start;
  align-content: start;
  margin-bottom: 5px;

`;

export const TaskTag = styled.span<TaskTagProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    font-size: 11px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 5px;
    background-color: #f23b5c;
    color: white;
    min-width: 40px;
    min-height: 20px;
    font-weight: 500;
    margin-right: 5px;

    ${(props) => props.color && css`
      background-color: ${props.color} !important;
    `}


`;

export const DataTableCange = styled(DataTable)`

    .rdt_TableCol_Sortable {
        div {
            background-color: #f4f5f7;
            padding: 5px 10px;
            border-radius: 5px;
            font-weight: 500;
        }
    }

    .rdt_TableCell {
        //min-width: 175px;
        overflow: hidden; 
    }

    .rdt_TableCol {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rdt_TableCol_Sortable {
        display: flex;
        justify-content: center;
        width: 100%;
        //margin-left: 18px;
        //overflow: hidden;
        align-items: center;
        text-align: center;
    }

    .rdt_TableRow {
        min-height: 45px;
    }
`;

export const CellStep = styled.div<CellStepProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    white-space: nowrap;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        
        svg {
            font-size: 14px;
        }
        
    }
    
    ${(props) => props.color && css`
        background-color: ${props.color};
    `}
`;

export const NoRegister = styled.span`
    color: gray;
    font-style: italic;
`;

export const RegisterData = styled.span<RegisterDataProps>`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;

    ${(props) => props.color && css`
      background-color: ${props.color + "1e"};
    `}
`

export const LinkData = styled.div<RegisterDataProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;

    ${(props) => props.color && css`
      background-color: ${props.color + "1e"};
    `}
`
export const LinkDataUrl = styled.a`
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ListViewContainer = styled.div`
`;

export const LoaderContainer = styled.div`
    margin-bottom: 15px;

    svg {
        max-width: 100%;
    }
`;

export const AvatarAdd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed gray;
    padding: 9px;
    border-radius: 20px;
    font-size: 15px;

    svg {
        color: gray;
    }
    
`;

export const CellDueAdd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed gray;
    padding: 8px;
    border-radius: 20px;
    font-size: 16px;
    width: 100px;

    svg {
        color: gray;        
    }
    
`;

export const CellTagAdd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed gray;
    padding: 7px;
    border-radius: 20px;
    font-size: 18px;
    height: 35px;
    max-height: 35px;
    width: 80px;

    svg {
        color: gray;        
    }
    
`;

export const CellDue = styled.div<CellDueProps>`

    ${(props) => props.color && css`
        color: ${props.color};
        font-weight: 500;
    `}

`;


export const AggregationContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-bottom: 10px;
`;

export const AggregationItem = styled.div<AggregationItemProps>`
    display: flex;
    justify-content: flex-start;
    align-content: center;
    min-width: 215px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 15px;

    background-color: #9d37ed1e;
    border-left: 10px solid #9d37ed;
    ${(props) => props.color && css`
      background-color: ${props.color + "1e"};
      border-left: 10px solid ${props.color};
    `}

    :hover {
        .aggregateConfig {
            display: flex;
        }
    }

`;

export const AggregateItemLeft = styled.div`
    width: 100%;
    margin-right: 10px;
`;

export const AggregationName = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: gray;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const AggregationNameText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
`;

export const AggregationValue = styled.div`
    font-size: 25px;
    font-weight: 500;
    color: #4d4d4d;
    margin-top: 5px;
    overflow: hidden;
`;

export const AggregateItemRight = styled.div`
    font-size: 15px;
    color: gray;
    min-width: 15px;
`;

export const AggregateConfig = styled.div`
    display: none;
`;

export const AggregationNameType = styled.span`
    font-size: 11px;
    color: gray;
    margin-left: 5px;
    text-transform: lowercase;
    font-style: italic;
`;

export const ArchevedDetail = styled.span`
    margin-right: 5px;
    font-size: 10px;
    color: white;
    padding: 7px;
    border-radius: 10px;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TitleCell = styled.div`
    font-size: 15px;
    font-weight: 500;    
`;
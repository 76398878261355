import { FieldProps, Fields } from "../components/Forms/Fields/FieldBuilder";

export const getItemComponentConfig = (type: string) => {
    const relationFields = {
        [Fields.TEXT_SHORT_FIELD]: TEXT_SHORT_FIELD_CFG,
        [Fields.TEXT_LONG_FIELD]: TEXT_LONG_FIELD_CFG,
        [Fields.MAIL_FIELD]: MAIL_FIELD_CFG,
        [Fields.COMBO_BOX_FIELD]: COMBO_BOX_FIELD_CFG,
        [Fields.RADIO_BOX_FIELD]: RADIO_BOX_FIELD_CFG,
        [Fields.CHECK_BOX_FIELD]: CHECK_BOX_FIELD_CFG,
        [Fields.DATE_PICKER_FIELD]: DATE_PICKER_FIELD_CFG,
        [Fields.SWITCH_FIELD]: SWITCH_FIELD_CFG,
        [Fields.INPUT_LIST_FIELD]: INPUT_LIST_FIELD_CFG,
        [Fields.COLOR_PICKER_FIELD]: COLOR_PICKER_FIELD_CFG,
        [Fields.ICON_PICKER_FIELD]: ICON_PICKER_FIELD_CFG,
        [Fields.COMBO_BOX_USER_FIELD]: COMBO_BOX_USER_FIELD_CFG,
        [Fields.TITLE_FIELD]: TITLE_FIELD_CFG,
        [Fields.DESCRIPTION_FIELD]: DESCRIPTION_FIELD_CFG,
        [Fields.DIVIDER_FIELD]: DIVIDER_FIELD_CFG,
        [Fields.DUE_DATE_FIELD]: DUE_DATE_FIELD_CFG,
        [Fields.CURRENCY_FIELD]: CURRENCY_FIELD_CFG,
        [Fields.NUMBER_FIELD]: NUMBER_FIELD_CFG,
        [Fields.PASSWORD_FIELD]: PASSWORD_FIELD_CFG,
        [Fields.PHONE_FIELD]: PHONE_FIELD_CFG,
        [Fields.DOC_FIELD]: DOC_FIELD_CFG,
        [Fields.COMBO_BOX_REGISTER_FIELD]: COMBO_BOX_REGISTER_FIELD_CFG,
        [Fields.COMBO_BOX_FLOW_FIELD]: COMBO_BOX_FLOW_FIELD_CFG,
        [Fields.INPUT_ATTACH_FIELD]: INPUT_ATTACH_FIELD_CFG,
        [Fields.FORMULA_FIELD]: FORMULA_FIELD_CFG,
        [Fields.INPUT_RICH_TEXT_FIELD]: INPUT_RICH_TEXT_FIELD_CFG,
        [Fields.CHECK_LIST_FIELD]: CHECK_LIST_FIELD_CFG,
        [Fields.DYNAMIC_TEXT_FIELD]: DYNAMIC_TEXT_FIELD_CFG,
        [Fields.ID_FIELD]: ID_FIELD_CFG,
        [Fields.LINK_FIELD]: LINK_FIELD_CFG
    };

    return relationFields[type];
};

export const DYNAMIC_TEXT_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        max_length: 150,
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_formula",
        type: Fields.CUSTOM_SELECT_FIELD,
        required: true,
        index: 1,
        title: "Conteúdo Dinâmico",
        description: "Digite o seu conteúdo dinâmico abaixo",
        placeholder: "Digite aqui...",
        help_text: "Utilize de valores fixos e campos dinâmicos para exibir informações personalizadas",
        variation: "6",
        turn_on_field: false,
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Conteúdo Dinâmico é obrigatório!"]
            },
            {
                type: "min",
                params: [1, "Verifique a expressão digitada, este campo deve ser preenchido!"]
            }
        ]
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    }
];

export const ID_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        max_length: 150,
        title: "Nome",
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    }
];

export const LINK_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        max_length: 150,
        title: "Nome",
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    }
];

export const TEXT_SHORT_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        max_length: 150,
        title: "Nome",
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_variation",
        type: Fields.COMBO_BOX_FIELD,
        required: false,
        turn_on_field: true,
        index: 6,
        title: "Formatação",
        description: "Escolha a formatação do campo",
        placeholder: "Selecione...",
        help_text: "Selecione uma formatação para o o texto que será inserido no campo. Isto irá definir o formato de exibição do campo.",
        options: [
            { value: "1", label: "Normal" },
            { value: "2", label: "Maiúscula" },
            { value: "3", label: "Minúscula" }
        ]
    },
    {
        name: "field_unique",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Valor único",
        description: "Este campo deve ter valor único?",
        help_text: "Está opção quando positiva irá acrescentar uma validação para que o valor inserido neste campo seja único e não se repita",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 8,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 9,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_formula",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        turn_on_field: true,
        index: 10,
        max_length: 150,
        title: "Validação personalizada",
        description: "Utilize uma expressão Regex para validar o campo",
        placeholder: "^d{5}-d{3}$",
        help_text: "Digite uma expressão regular para validar o campo de texto /n Exemplos </br> Exemplos: </br> - Apenas letras: ^[a-zA-Z]+$ </br> - Apenas números: ^[0-9]+$ </br> - Letras e números: ^[a-zA-Z0-9]+$",
    },
];

export const PHONE_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        max_length: 150,
        title: "Nome",
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_unique",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Valor único",
        description: "Este campo deve ter valor único?",
        help_text: "Está opção quando positiva irá acrescentar uma validação para que o valor inserido neste campo seja único e não se repita",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 8,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const DOC_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        max_length: 150,
        title: "Nome",
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_variation",
        type: Fields.RADIO_BOX_FIELD,
        required: true,
        index: 2,
        title: "Tipo do Documento",
        description: "Escolha o tipo do documento que será inserido",
        options: [
            { value: "1", label: "CPF" },
            { value: "2", label: "CNPJ" },
            { value: "3", label: "CPF ou CNPJ" }
        ]
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 3,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 4,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 5,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 6,
        title: "",
        description: ""
    },
    {
        name: "field_unique",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Valor único",
        description: "Este campo deve ter valor único?",
        help_text: "Está opção quando positiva irá acrescentar uma validação para que o valor inserido neste campo seja único e não se repita",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 8,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 9,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const PASSWORD_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    }
];

export const TEXT_LONG_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const MAIL_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_unique",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Valor único",
        description: "Este campo deve ter valor único?",
        help_text: "Está opção quando positiva irá acrescentar uma validação para que o valor inserido neste campo seja único e não se repita",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 8,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const COMBO_BOX_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_options",
        type: Fields.INPUT_LIST_FIELD,
        required: true,
        index: 1,
        title: "Opções",
        description: "Insira as opções do campo",
        help_text: "Insira as opções que estarão disponíveis para os usuários escolherem",
        validation_type: "array",
        validations: [
            {
                type: "min",
                params: [1, "Você deve inserir no mínimo uma opção!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 2,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 3,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 4,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 5,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 6,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 8,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const COMBO_BOX_FLOW_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_flow_id",
        type: Fields.COMBO_BOX_FLOW_FIELD,
        required: true,
        index: 1,
        title: "Meu Fluxo",
        description: "Escolha um fluxo para criar o relacionamento",
        placeholder: "Adicione um Fluxo",
        //register_id: -1,
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Meu Fluxo é obrigatório!"]
            }
        ]
    },
    {
        name: "field_variation",
        type: Fields.RADIO_BOX_FIELD,
        required: true,
        index: 2,
        title: "Função do campo",
        description: "Escolha a função que este campo exercerá",
        help_text: "Este campo irá definir o comportamento do campo dentro do formulário",
        options: [
            { value: "1", label: "Busca cartões existentes no fluxo" },
            { value: "2", label: "Criar novo(s) cartões no fluxo" },
            { value: "3", label: "Buscar ou criar novo(s) cartões no fluxo" }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 3,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_max_length",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 4,
        title: "Tipo da Seleção",
        description: "Escolha se será vinculado um ou mais cartões",
        help_text: "Este campo irá definir se será possível vincular um ou mais cartões no campo",
        options: [
            { value: "1", label: "Único Item" },
            { value: "2", label: "Múltiplos Itens" }
        ]
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 5,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 6,
        title: "Texto do botão",
        description: "Digite um texto que aparecerá no botão para a escolha do registro",
        placeholder: "Digite aqui...",
        help_text: "Insira um texto customizado para que apareça no campo de seleção",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 7,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 8,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 9,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 10,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const INPUT_ATTACH_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_max_length",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 2,
        title: "Tipo da Seleção",
        description: "Escolha se será anexado um ou mais arquivos",
        help_text: "Este campo irá definir se será possível anexar um ou mais arquivos no campo",
        options: [
            { value: "1", label: "Único Item" },
            { value: "2", label: "Múltiplos Itens" }
        ]
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 3,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto do botão",
        description: "Digite um texto que aparecerá no botão para a escolha do registro",
        placeholder: "Digite aqui...",
        help_text: "Insira um texto customizado para que apareça no campo de seleção",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 5,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 6,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 8,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_variation",
        type: Fields.COMBO_BOX_FIELD,
        required: false,
        turn_on_field: true,
        index: 9,
        title: "Nome do arquivo",
        description: "Escolha a regra para geração do nome do arquivo no download",
        placeholder: "Selecione...",
        help_text: "Selecione uma regra de geração de nome para o arquivo no momento em que será baixado",
        options: [
            { value: "1", label: "Sem prefixo" },
            { value: "2", label: "Título do cartão + Nome do Arquivo" }            
        ]
    },
    {
        name: "field_formula",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 10,
        title: "Prefixo fixo do arquivo",
        description: "Digite um valor para ser adicionado ao nome do arquivo",
        placeholder: "Digite aqui...",
        help_text: "Ao inserir um valor customizado neste campo, sempre que um arquivo for armazenado, este valor será adicionado no início do nome do arquivo",
        turn_on_field: true
    },
];

export const COMBO_BOX_REGISTER_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_register_id",
        type: Fields.COMBO_BOX_REGISTER_FIELD,
        required: true,
        index: 1,
        title: "Meu Cadastro",
        description: "Escolha um cadastro para criar o relacionamento",
        placeholder: "Adicione um Cadastro",
        //register_id: -1,
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Meu Cadastro é obrigatório!"]
            }
        ]
    },
    {
        name: "field_variation",
        type: Fields.RADIO_BOX_FIELD,
        required: true,
        index: 2,
        title: "Função do campo",
        description: "Escolha a função que este campo exercerá",
        help_text: "Este campo irá definir o comportamento do campo dentro do formulário",
        options: [
            { value: "1", label: "Busca registro(s) existentes no cadastro" },
            { value: "2", label: "Criar novo(s) registro(s) no cadastro" },
            { value: "3", label: "Buscar ou criar novo(s) registro(s) no cadastro" }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 3,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_max_length",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 4,
        title: "Tipo da Seleção",
        description: "Escolha se será vinculado um ou mais registros",
        help_text: "Este campo irá definir se será possível vincular um ou mais registros no campo",
        options: [
            { value: "1", label: "Único Item" },
            { value: "2", label: "Múltiplos Itens" }
        ]
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 5,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 6,
        title: "Texto do botão",
        description: "Digite um texto que aparecerá no botão para a escolha do registro",
        placeholder: "Digite aqui...",
        help_text: "Insira um texto customizado para que apareça no campo de seleção",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 7,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 8,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 9,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 10,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const COMBO_BOX_USER_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 4,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 5,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const RADIO_BOX_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_options",
        type: Fields.INPUT_LIST_FIELD,
        required: true,
        index: 1,
        title: "Opções",
        description: "Insira as opções do campo",
        help_text: "Insira as opções que estarão disponíveis para os usuários escolherem",
        validation_type: "array",
        validations: [
            {
                type: "min",
                params: [1, "Você deve inserir no mínimo uma opção!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 2,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 3,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 4,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const CHECK_BOX_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_options",
        type: Fields.INPUT_LIST_FIELD,
        required: true,
        index: 1,
        title: "Opções",
        description: "Insira as opções do campo",
        help_text: "Insira as opções que estarão disponíveis para os usuários escolherem",
        validation_type: "array",
        validations: [
            {
                type: "min",
                params: [1, "Você deve inserir no mínimo uma opção!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 2,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 3,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 4,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const DATE_PICKER_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome", max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_variation",
        type: Fields.RADIO_BOX_FIELD,
        required: true,
        index: 2,
        title: "Tipo",
        description: "Escolha qual será o tipo do campo",
        help_text: "Este campo possibilita escolher se você deseja armazenar Data ou Data e Hora",
        options: [
            { value: "1", label: "Data" },
            { value: "2", label: "Data e Hora" }
        ]
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 3,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 4,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const SWITCH_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const INPUT_LIST_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const CHECK_LIST_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_variation",
        type: Fields.RADIO_BOX_FIELD,
        options: [
            { value: "1", label: "Sim" },
            { value: "0", label: "Não" },
        ],
        required: false,
        index: 2,
        title: "Desabilitar a edição",
        description: "Escolha se a edição do campo será desabilitada",
        help_text: "Escolha se a edição deste campo será desabilitada ou não",
        turn_on_field: true
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 3,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 4,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const COLOR_PICKER_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    }
];

export const ICON_PICKER_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "fiel_icon",
        type: Fields.ICON_PICKER_FIELD,
        required: false,
        index: 3,
        title: "Ícone",
        description: "Escolha um ícone",
        turn_on_field: true
    }
];

export const TITLE_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o que será exibido no título",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    }
];

export const DESCRIPTION_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        description: "Digite o que será exibido na descrição",
        placeholder: "Digite aqui...",
        validation_type: "string",
        max_length: 150,
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    }
];

export const DUE_DATE_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_variation",
        type: Fields.RADIO_BOX_FIELD,
        required: true,
        index: 2,
        title: "Tipo",
        description: "Escolha qual será o tipo do campo",
        help_text: "Este campo possibilita escolher se você deseja armazenar Data ou Data e Hora",
        options: [
            { value: "1", label: "Data" },
            { value: "2", label: "Data e Hora" }
        ]
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 5,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 6,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 8,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const DIVIDER_FIELD_CFG: FieldProps[] = [
];

export const CURRENCY_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const NUMBER_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_unique",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Valor único",
        description: "Este campo deve ter valor único?",
        help_text: "Está opção quando positiva irá acrescentar uma validação para que o valor inserido neste campo seja único e não se repita",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 8,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const FORMULA_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_formula",
        type: Fields.TEXT_FORMULA_FIELD,
        required: true,
        index: 1,
        title: "Fórmula",
        description: "Digite a sua fórmula abaixo",
        placeholder: "Digite aqui...",
        help_text: "Utilize os campos e funções disponíveis para criar um campo que será gerado a partir de suas fórmulas.",
        turn_on_field: false,
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Fórmula é obrigatório!"]
            },
            {
                type: "min",
                params: [1, "Verifique a expressão digitada, este campo deve ser preenchido e a fórmula válida!"]
            }
        ]
    },
    {
        name: "field_variation",
        type: Fields.COMBO_BOX_FIELD,
        required: true,
        index: 2,
        title: "Formatação",
        description: "Escolha a formatação do campo",
        placeholder: "Selecione...",
        help_text: "Selecione uma formatação para o resultado do campo. Isto irá definir o formato de exibição do campo.",
        turn_on_field: false,
        options: [
            { value: "1", label: "Número" },
            { value: "2", label: "Moeda (R$)" },
            { value: "3", label: "Percentual (%)" }
        ],
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Formatação é obrigatório!"]
            }
        ]
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 3,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 4,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];

export const INPUT_RICH_TEXT_FIELD_CFG: FieldProps[] = [
    {
        name: "field_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome",
        max_length: 150,
        description: "Digite o nome do campo",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "field_required",
        type: Fields.SWITCH_FIELD,
        required: false,
        index: 1,
        title: "Campo obrigatório",
        description: "Escolha se o campo deve ser obrigatório",
        help_text: "Escolha se o preenchimento do campo deve ser obrigatório ou não"
    },
    {
        name: "field_description",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 2,
        title: "Descrição",
        description: "Digite uma descrição para o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_help_text",
        type: Fields.TEXT_LONG_FIELD,
        required: false,
        index: 3,
        title: "Texto de ajuda",
        description: "Digite um texto de ajuda para auxiliar o usuário a preencher o campo",
        placeholder: "Digite aqui...",
        help_text: "Insira uma descrição para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_place_holder",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto de exemplo",
        description: "Digite um texto de exemplo que será exibido ao usuário",
        placeholder: "Digite aqui...",
        help_text: "Insira um exemplo para auxiliar o usuário a preencher o campo",
        turn_on_field: true
    },
    {
        name: "field_divider",
        type: Fields.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "",
        description: ""
    },
    {
        name: "field_updated",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Permitir edição",
        description: "Este campo pode ser alterado após ser inserido?",
        help_text: "Esta opção irá definir se este campo em questão pode ser alterado após ser inserido",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
    {
        name: "field_show_on_form",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 7,
        title: "Ocultar no formulário",
        description: "Este campo deve ser ocultado na inserção do formulário?",
        help_text: "Esta opção irá definir se este campo em questão deve ser ocultado na inserção do formulário, somente permitirá a edição posteriormente",
        turn_on_field: true,
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ]
    },
];
import AppBarHeader, { NavigationBarItemType } from "../../../components/AppBarHeader";
import {
    BreakArea,
    ButtonNextStep,
    Container,
    ContainerAdrress,
    ContainerFields,
    ContainerPreview,
    Content,
    ContentList,
    CopyBtn,
    Footer,
    FormPreview,
    TitleAddress
} from "./style";
import {
    FieldProps
} from "../../../components/Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from 'react-router-dom';

import { BiSave } from "react-icons/bi";
import { BsGearFill } from "react-icons/bs";
import ContentLoader from "react-content-loader"
import ContentPreview from "../../../components/ContentPreview";
import Description from "../../../components/Description";
import { FaTable } from "react-icons/fa";
import { FiArrowLeft } from 'react-icons/fi';
import Fields from "../../../components/Fields";
import { Form } from "../../../interfaces/Form";
import { FormHandles } from "@unform/core";
import { IconList } from "react-fa-icon-picker";
import { IoMdListBox } from "react-icons/io";
import LayoutContainer from '../../../components/LayoutContainer';
import ListContainer from '../../../components/ListContainer';
import { Register } from "../../../interfaces/Register";
import Title from "../../../components/Title";
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { TbCheck, TbCircleKey, TbCopy } from "react-icons/tb";
import { getFeatureControlByPlan } from "../../../middlewares/FeatureControl";
import { useAuth } from "../../../hooks/auth";

interface RouteParams {
    hash: string;
    origin: string;
}

const LoaderField = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={70}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="70" />
    </ContentLoader>
)

const LoaderBtnField = () => (
    <ContentLoader
        speed={2}
        width={180}
        height={36}
        viewBox="0 0 180 36"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="180" height="36" />
    </ContentLoader>
)

interface objectInit {
    [x: string]: string | object
}

const SecStep: React.FC = () => {

    const formRefPreview = useRef<FormHandles>(null);
    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);

    const { params } = useRouteMatch<RouteParams>();

    const history = useHistory();

    const { addToast } = useToast();
    const { user } = useAuth();

    const [statePage, setStatePage] = React.useState(0);
    const [register, setRegister] = React.useState<Register>();

    /* Loading Variables */
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingItems] = useState<number[]>([1, 2, 3]);

    /* Field Array */
    const [fields, setFields] = React.useState([] as FieldProps[]);
    const [form, setForm] = React.useState({} as Form);

    /* Dialog controller */
    const [open, setOpen] = React.useState(false);
    const [stateDialog, setStateDialog] = React.useState("INS");
    const [selectedValue, setSelectedValue] = React.useState({} as FieldProps);

    const [copied, setCopied] = useState<boolean>(false);


    const handleCopyClick = () => {

        let values: objectInit = {}

        //Run into fields and append to values as a key-value
        fields.forEach((field) => {
            values[field.name] = "ReplaceByYourValue";
        });

        const objApi = {
            id_form: register?.form_id,
            origin: "/NomeDaSuaApi",
            values: values,
            register_id: register?.id_register,
        }

        //Set to string the objApi
        const toString = (obj: object) => {
            return JSON.stringify(obj, null, 2);
        };

        navigator.clipboard.writeText(toString(objApi));

        setCopied(true);
    };

    const savePageApi = useCallback(async (urlRedirec?: string) => {

        if (fields.length > 0) {

            let fieldsNew = fields.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

            for (let index = 0; index < fieldsNew.length; index++) {

                fieldsNew[index].index = index;

            }

            //Monta o objeto para enviar na API
            const dataToApi: Form = {
                id_form: form.id_form,
                name: "RE",
                fields: fieldsNew,
                hashRegister: params.hash
            }

            setLoadingInsert(true);
            await api
                .post('/form', dataToApi)
                .then(response => {
                    if (response.data !== null) {
                        setForm(response.data as Form);
                        setFields(response.data.fields as FieldProps[]);
                    }

                    if (urlRedirec !== undefined) {
                        history.push(urlRedirec);
                    }
                    setLoadingInsert(false);
                }).catch(error => {
                    setLoadingInsert(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o cadastro',
                        description: 'Ocorreu um erro ao salvar o cadastro!',
                    });
                });
        }

    }, [addToast, fields, form.id_form, params.hash, history]);

    const handleSubmitPage = useCallback(async () => {

        if (fields.length > 0) {

            savePageApi('/register/' + params.hash);

        } else {
            addToast({
                type: 'error',
                title: 'Sem campos adicionados!',
                description: 'Para prosseguir, você deve adicionar ao menos um campo no seu cadastro!',
            });
        }

    }, [fields, addToast, params.hash, savePageApi]);

    const handleBackPage = useCallback(async () => {

        if (params.origin === "new") {

            await savePageApi();

            history.push('/register/1/' + params.hash + "/new");

        } else {
            history.push('/register/' + params.hash);
        }

    }, [history, params.hash, params.origin, savePageApi]);

    const handleSubmit = useCallback(async (data: object[]) => {

    }, []);

    const dialogNewFieldDelete = useCallback(async (name: string, id_field?: number) => {
        setOpen(false);

        if (id_field !== undefined && id_field > 0) { //Já inserido no BD

            await api
                .delete('/field?id_field=' + id_field)
                .then(response => {

                    let fieldsNew = fields.filter((field) => field.name !== name);

                    fieldsNew = fieldsNew.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

                    for (let index = 0; index < fieldsNew.length; index++) {

                        fieldsNew[index].index = index;

                    }

                    setFields(fieldsNew);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao excluir o campo',
                        description: 'Ocorreu um erro ao excluir o campo!',
                    });
                });


        } else { //Somente em memória

            const fieldsNew = fields.filter((field) => field.name !== name);

            setFields(fieldsNew);

        }


    }, [fields, addToast]);

    const dialogNewFieldOpen = useCallback(async () => {
        setSelectedValue({} as FieldProps);
        setOpen(true);
        setStateDialog("INS");
        setStatePage(Math.random());
    }, []);

    const dialogNewFieldClose = useCallback(async (value: FieldProps) => {
        setOpen(false);
        setSelectedValue(value);
    }, []);

    const dialogNewFieldSubmit = useCallback(async (data: FieldProps) => {

        if (stateDialog === "INS") {
            data.index = fields.length === 0 ? 0 : (fields.length - 1) + 1;

            if (fields.length > 0) {
                setFields((fields) => {
                    return [...fields, data]
                });
            } else {
                setFields([data]);
            }

        } else if (stateDialog === "EDI") {

            let newFields = fields;

            newFields[data.index] = data;

            setFields(newFields);
        }

        setOpen(false);
        setSelectedValue(data);
    }, [fields, stateDialog]);

    /* Field Controller */
    const handleEditField = useCallback(async (index: number) => {
        setSelectedValue(fields[index]);
        setOpen(true);
        setStateDialog("EDI");
    }, [fields]);

    const handleMoveField = useCallback(async (type: string, index: number) => {

        let newFields = fields;

        if (type === "up") { //Se clicou para cima 

            if (index > 0) {
                let upFil = fields[index];
                upFil.index = index - 1;

                let downFil = fields[index - 1];
                downFil.index = index;

                newFields[index] = upFil;
                newFields[index - 1] = downFil;
            }

        } else if (type === "down") { //Se clicou para baixo

            if (index < fields.length - 1) {
                let downFil = fields[index];
                downFil.index = index + 1;

                let upFil = fields[index + 1];
                upFil.index = index;

                newFields[index] = downFil;
                newFields[index + 1] = upFil;
            }

        }

        setFields(newFields);
        setStatePage(Math.random());

    }, [fields]);

    const goToGeneralConfTab = useCallback(async () => {

        if (params.origin === "new") {
            savePageApi("/register/1/" + params.hash + "/new");
        } else {
            savePageApi("/register/1/" + params.hash + "/edit");
        }

    }, [params.origin, params.hash, savePageApi]);

    const navBarItems: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Geral",
            icon: BsGearFill,
            onClick: () => goToGeneralConfTab()
        },
        {
            index: 2,
            description: "Campos",
            icon: IoMdListBox,
            active: true,
            onClick: () => {}
        }
    ]

    useEffect(() => {

        setLoading(true);

        api.get(`/register`, {
            params: {
                hash: params.hash
            }
        }).then(response => {

            if (response.data.form_id !== null &&
                response.data.form_id !== undefined) {

                const registerApi: Register = response.data;

                setRegister(registerApi);

                api.get(`/form/by-register`, {
                    params: {
                        hash: params.hash
                    }
                }).then(response => {
                    if (response.data !== null) {
                        setForm(response.data as Form);

                        setFields(response.data.fields as FieldProps[]);
                    }
                    setLoading(false);
                }).catch(error => {
                    setLoading(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao buscar os dados do Cadastro',
                        description: 'Erro ao buscar os dados do Cadastro!',
                    });
                });
            } else {
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
        });

    }, [addToast, params.hash, params.origin]);

    useEffect(() => {
        //Valid if close the window
        window.onbeforeunload = confirmExit;
        function confirmExit() {
            return "show warning";
        }
    }, [])

    return (
        <LayoutContainer>
            <title>{register !== undefined ? register.name : "Novo Cadastro"} | Cange</title>
            <ListContainer statePage={statePage}>

                {/* Header Page */}
                <AppBarHeader
                    icon={params.origin === "new" ? FaTable : IoMdListBox}
                    iconDynamic={register !== undefined ? register.icon as IconList : undefined}
                    iconDynamicColor={register !== undefined ? register.color : undefined}
                    title={params.origin === "new" ? "Campos do Cadastro" : register !== undefined && register.name !== undefined ? register.name : "Campos do Cadastro"}
                    navBarItems={navBarItems}
                >

                    <ButtonNextStep
                        color="gray"
                        height="36px"
                        margin="0px 30px 0px 0px"
                        icon={FiArrowLeft}
                        icon_side="left"
                        onClick={handleBackPage}
                        isLoading={loadingInsert}
                    >
                        Voltar
                    </ButtonNextStep>

                    <ButtonNextStep
                        type="submit"
                        color="#23cd78"
                        height="36px"
                        margin="0px 30px 0px 0px"
                        icon={BiSave}
                        onClick={handleSubmitPage}
                        isLoading={loadingInsert}
                    >
                        Salvar
                    </ButtonNextStep>
                </AppBarHeader>

                {/* Inputs Form */}
                <Container>
                    <Content container>

                        {/* Forms */}
                        <ContainerFields item xs={12} md={6}>

                            <ContentList>

                                <BreakArea />

                                {/* Campos do Formulário */}
                                <Title
                                    title="Campos do cadastro"
                                    required={false}
                                    help_text="Os campos serão ideais para você armazenar os dados relacionados"
                                />
                                <Description>Para finalizar o seu cadastro, crie campos para armazenar as informações necessárias</Description>

                                {loading ?
                                    <>
                                        {loadingItems.map((item) => {
                                            return (
                                                <div key={item} style={{ marginBottom: '15px' }}>
                                                    <LoaderField />
                                                </div>
                                            )
                                        })}
                                        <LoaderBtnField />
                                    </> :
                                    <Fields
                                        fields={fields}
                                        origin="register"
                                        register={register}
                                        open={open}
                                        selectedValue={selectedValue}
                                        stateDialog={stateDialog}
                                        handleMoveField={handleMoveField}
                                        handleEditField={handleEditField}
                                        dialogNewFieldSubmit={dialogNewFieldSubmit}
                                        dialogNewFieldOpen={dialogNewFieldOpen}
                                        dialogNewFieldClose={dialogNewFieldClose}
                                        dialogNewFieldDelete={dialogNewFieldDelete}
                                    />
                                }

                            </ContentList>

                        </ContainerFields>

                        {/* Pré-Visualização/Design/Simulação */}
                        <ContainerPreview item xs={12} md={6}>
                            <ContentPreview>
                                <FormPreview id="previewAllForm" formRef={formRefPreview} fields={fields} handleSubmit={handleSubmit} register_id={register?.id_register} />
                                {form !== undefined && form.id_form !== undefined && getFeatureControlByPlan(3, user.company) && (
                                    <ContainerAdrress>
                                        <TitleAddress>
                                            <TbCircleKey />
                                            <h3>OBJETO API</h3>
                                            <CopyBtn onClick={handleCopyClick}>
                                                {copied ?
                                                    <>
                                                        <TbCheck />
                                                        Copiado
                                                    </> :
                                                    <>
                                                        <TbCopy />
                                                        Copiar
                                                    </>
                                                }
                                            </CopyBtn>
                                        </TitleAddress>
                                    </ContainerAdrress>
                                )}
                            </ContentPreview>
                        </ContainerPreview>

                    </Content>
                </Container>

                <Footer></Footer>
            </ListContainer>
        </LayoutContainer>
    );
}

export default SecStep;
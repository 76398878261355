import {
    AddNewFilter,
    Animation,
    BackBtn,
    BottomAction,
    BoxContainer,
    ButtonCancel,
    ButtonConfirm,
    CleanFilter,
    ConfigTitle,
    Container,
    ContainerLoader,
    ContentConfig,
    ContentElement,
    DeleteButton,
    FilterBottom,
    FilterButton,
    FilterContainer,
    GoFilter,
    LeftSide,
    RightSide,
    SectionScroll,
    SelectComparator,
    SelectInputFilter,
    TitleContainer,
    TitleInput
} from "./style";
import { DataElementProps, ElementProps, getElementComponent, getElementObject } from "../../../components/ElementBuilder";
import { FieldOptionsProps, FieldProps, Fields, getComponentComparationType, getFieldObject } from "../../../components/Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { BsGearWideConnected } from "react-icons/bs";
import { Flow } from "../../../interfaces/Flow";
import { IoIosArrowRoundBack } from "react-icons/io";
import api from "../../../services/api";
import { getElementComponentConfig } from "./ConfigSectionElement";
import { useToast } from "../../../hooks/toast";
import { MdOutlineFilterList } from "react-icons/md";
import FilterInput from "../../../components/FilterBar/FilterComponents/FilterInput";
import FilterDate from "../../../components/FilterBar/FilterComponents/FilterDate";
import FilterSelect from "../../../components/FilterBar/FilterComponents/FilterSelect";
import { FaPlus, FaTrash } from "react-icons/fa";
import { AiFillThunderbolt } from "react-icons/ai";
import lottie from "lottie-web";

interface ElementConfigProps {
    flow: Flow;
    element: ElementProps;
    isTestMode: boolean;
    isEditing: boolean;
    onUpdateElement: (element: ElementProps | undefined) => void;
    onAddNewElement: (element: ElementProps) => void;
    onBackToStore: () => void;
    onClose: () => void;
}

export interface FilterConditionItem {
    index: number;
    fields: FieldProps[];
    comparators: string[];
    selectedField: FieldProps;
    selectedComparator: string;
    value: string;
    valueOptions?: string[];
    options?: FieldOptionsProps[];
    nameComponent?: string;
}

const ElementConfig: React.FC<ElementConfigProps> = ({ flow, element, isTestMode, isEditing, onUpdateElement, onAddNewElement, onClose, onBackToStore }) => {

    const { addToast } = useToast();
    const lottieContainer = useRef<HTMLDivElement>(null);

    const [elementSelected, setElementSelected] = useState<ElementProps>(element);
    const [dataPreview, setDataPreview] = useState<DataElementProps[]>();
    const [fieldsAxisX, setFieldsAxisX] = useState<FieldProps[]>([]);
    const [fieldsAxisY, setFieldsAxisY] = useState<FieldProps[]>([]);

    const ElementComponent = getElementComponent(element.type);
    const ElementComponentConfig = getElementComponentConfig(element.type);

    const [openFilter, setOpenFilter] = useState(false);
    const [filterFields, setFilterFields] = useState<FieldProps[]>([]);
    const [filterState, setFilterState] = useState<boolean>();
    const [filterStateMessage, setFilterStateMessage] = useState<string>();
    const [conditionItems, setConditionItems] = useState<FilterConditionItem[]>([]);

    const [title, setTitle] = useState(element.title);

    const [loading, setLoading] = useState(false);

    /* Relationship */
    const ComponentField = {
        TEXT_FILTER: "TEXT_FILTER",
        DATE_FILTER: "DATE_FILTER",
        OPTION_FILTER: "OPTION_FILTER",
    };

    const getFilterComponent = (type: string) => {
        const relationFields = {
            [ComponentField.TEXT_FILTER]: FilterInput,
            [ComponentField.DATE_FILTER]: FilterDate,
            [ComponentField.OPTION_FILTER]: FilterSelect,
        };

        return relationFields[type];
    };

    const getFilterNameComponent = (type: string) => {
        const relationFields = {
            [Fields.TEXT_SHORT_FIELD]: "TEXT_FILTER",
            [Fields.TEXT_LONG_FIELD]: "TEXT_FILTER",
            [Fields.MAIL_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.RADIO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_FIELD]: "OPTION_FILTER",
            [Fields.DATE_PICKER_FIELD]: "DATE_FILTER",
            [Fields.SWITCH_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_LIST_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_ONE_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_USER_FIELD]: "OPTION_FILTER",
            [Fields.DUE_DATE_FIELD]: "DATE_FILTER",
            [Fields.CURRENCY_FIELD]: "TEXT_FILTER",
            [Fields.NUMBER_FIELD]: "TEXT_FILTER",
            [Fields.PHONE_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_REGISTER_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_FLOW_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_RICH_TEXT_FIELD]: "TEXT_FILTER",
            [Fields.ID_FIELD]: "TEXT_FILTER",
            [Fields.LINK_FIELD]: "TEXT_FILTER",
        };

        return relationFields[type];
    }

    const onSelectAxisX = useCallback((id: number, title?: string | null) => {

        if (id > 0) {

            //Find the field to get the type
            const field = fieldsAxisX.find((field) => field.id_field === id);

            setElementSelected(element => {
                return {
                    ...element,
                    axis_x_field_id: id,
                    axis_x_field_type: field !== undefined && field.type !== undefined ? field.type : undefined                    
                }
            });

        } else {

            setElementSelected(element => {
                return {
                    ...element,
                    axis_x_field_id: id,
                    axis_y_field_id: -1,
                    axis_x_field_type: undefined
                }
            });

        }

    }, [fieldsAxisX]);

    const onSelectAxisY = useCallback((id: number) => {

        if (id === -2) { //If is "Tempo para Conclusão"

            setElementSelected(element => {
                return {
                    ...element,
                    axis_y_field_id: id,
                    calc_function: "avg",
                    axis_y_field_type: undefined,
                    axis_y_field_variation: undefined
                }
            });

        } else if (id > 0) { //If is a field

            //Find the field to get the type
            const field = fieldsAxisY.find((field) => field.id_field === id);

            setElementSelected(element => {
                return {
                    ...element,
                    axis_y_field_id: id,
                    axis_y_field_type: field !== undefined && field.type !== undefined ? field.type : undefined,
                    axis_y_field_variation: field !== undefined && field.variation !== undefined ? field.variation : undefined
                }
            });

        } else {

            setElementSelected(element => {
                return {
                    ...element,
                    axis_y_field_id: id,
                    axis_y_field_type: undefined,
                    axis_y_field_variation: undefined
                }
            });

        }

    }, [fieldsAxisY]);

    const onSelectGroupBy = useCallback((id: number, title?: string | null) => {

        if (id > 0) {

            //Find the field to get the type
            const field = fieldsAxisX.find((field) => field.id_field === id);

            setElementSelected(element => {
                return {
                    ...element,
                    group_by_field_id: id,
                    group_by_field_type: field !== undefined && field.type !== undefined ? field.type : undefined
                }
            });

        } else {

            setElementSelected(element => {
                return {
                    ...element,
                    group_by_field_id: id,
                    group_by_field_type: undefined
                }
            });

        }

    }, [fieldsAxisX]);

    const onSelectGroupByDate = useCallback((group_date: string) => {

        setElementSelected(element => {
            return {
                ...element,
                group_by_field_date: group_date
            }
        });

    }, []);

    const onSelectCalcFunction = useCallback((calc_function: string) => {

        setElementSelected(element => {
            return {
                ...element,
                calc_function: calc_function
            }
        });

    }, []);

    const onSelectShowTime = useCallback((show_time: string) => {

        setElementSelected(element => {
            return {
                ...element,
                show_time: show_time
            }
        });

    }, []);

    const onSelectGroupDate = useCallback((group_date: string) => {

        setElementSelected(element => {
            return {
                ...element,
                group_date: group_date
            }
        });

    }, []);

    const onSelectColor = useCallback((color: string) => {

        setElementSelected(element => {
            return {
                ...element,
                color: color
            }
        });

    }, []);

    const onSelectSortBy = useCallback((sort_by: string) => {

        setElementSelected(element => {
            return {
                ...element,
                sort_by: sort_by
            }
        });

    }, []);

    const onChangeEmbed = useCallback((embed?: string) => {

        if (embed !== undefined) {

            setElementSelected(element => {
                return {
                    ...element,
                    embed: embed
                }
            });

        }

    }, []);

    const onChangeTitle = useCallback((title?: string) => {

        if (title !== undefined && title !== "") {
            setTitle(title);
        }

    }, []);

    const onChangeFilter = useCallback((filter?: FilterConditionItem[]) => {

        if (filter !== undefined) {

            setElementSelected(element => {
                return {
                    ...element,
                    filter: JSON.stringify(filter)
                }
            });

        }

    }, []);

    const onAddNewElementLocal = useCallback(async (element: ElementProps) => {

        if (isEditing) {

            let newElement = element;

            //Update the filter
            if (conditionItems !== undefined && conditionItems !== null && conditionItems.length > 0) {
                newElement.filter = JSON.stringify(conditionItems);
            } else {
                newElement.filter = undefined;
            }

            onUpdateElement(newElement);

        } else {

            const objElementDefault = getElementObject(element.type);

            element.width = objElementDefault.defW;
            element.height = objElementDefault.defH;

            if (element.title === objElementDefault.title) {
                //logic to change the title
            }

            onAddNewElement(element);

        }

    }, [onAddNewElement, onUpdateElement, isEditing, conditionItems]);

    const onSelectFieldFilter = (field_id: string, index: number) => {

        const fieldSelected = filterFields.filter((field) => String(field.id_field) === field_id);

        if (fieldSelected !== undefined && fieldSelected.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.comparators = getComponentComparationType(fieldSelected[0].type);
                    condition.selectedComparator = getComponentComparationType(fieldSelected[0].type)[0];
                    condition.selectedField = fieldSelected[0];
                    condition.nameComponent = getFilterNameComponent(fieldSelected[0].type)
                    condition.valueOptions = [];
                    condition.value = "";
                }

                return condition;
            }))

        }

        //onFilterFlow

    };

    const onSelectComparator = (comparatorName: string, index: number) => {

        setConditionItems(conditionItems.map((condition, idx) => {
            if (idx === index) {
                condition.selectedComparator = comparatorName;

                //Clean the value when is blank
                if (comparatorName === "Está em branco" || comparatorName === "Não está em branco") {
                    condition.value = "";
                }
            }

            return condition;
        }))

        //onFilterFlow

    };

    const onEventFieldComponent = useCallback((index: number, value?: string, conditions?: string[], version?: number) => {

        if (value !== undefined) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.value = value;
                }

                return condition;
            }))

            //onFilterFlow

        } else if (conditions !== undefined && conditions.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.valueOptions = conditions
                }

                return condition;
            }))

            //onFilterFlow

        }

    }, [conditionItems,]);

    const handleDeleteConditionFilter = useCallback((index: number) => {

        let newConditions = conditionItems.filter((condition) => condition.index !== index);

        //Rebuild the indexes
        newConditions.map((condition, idx) => {
            condition.index = idx;
            return condition;
        })

        if (newConditions.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: filterFields,
                comparators: getComponentComparationType(filterFields[0].type),
                selectedField: filterFields[0],
                selectedComparator: getComponentComparationType(filterFields[0].type)[0],
                value: "",
                valueOptions: undefined,
                options: filterFields[0].options,
                nameComponent: getFilterNameComponent(filterFields[0].type)
            }

            newConditions.push(newConditionItem);

        }

        setConditionItems(newConditions);

        //onFilterFlow

    }, [conditionItems, filterFields]);

    const addNewConditionFilter = useCallback(() => {

        let newArr = conditionItems;

        const newConditionItem: FilterConditionItem = {
            index: newArr.length,
            fields: filterFields,
            comparators: getComponentComparationType(filterFields[0].type),
            selectedField: filterFields[0],
            selectedComparator: getComponentComparationType(filterFields[0].type)[0],
            value: "",
            options: filterFields[0].options,
            nameComponent: getFilterNameComponent(filterFields[0].type)
        }

        newArr.push(newConditionItem);

        setConditionItems(newArr.map((condition) => {
            return condition;
        }));

    }, [filterFields, conditionItems]);

    const handleDeleteAllConditionFilter = useCallback(() => {

        let newConditions = [];

        const newConditionItem: FilterConditionItem = {
            index: 0,
            fields: filterFields,
            comparators: getComponentComparationType(filterFields[0].type),
            selectedField: filterFields[0],
            selectedComparator: getComponentComparationType(filterFields[0].type)[0],
            value: "",
            valueOptions: undefined,
            options: filterFields[0].options,
            nameComponent: getFilterNameComponent(filterFields[0].type)
        }

        newConditions.push(newConditionItem);

        setConditionItems(newConditions);

        //onFilterFlow

        setOpenFilter(!openFilter)

    }, [filterFields, openFilter]);

    const addDefaultItemsFilter = useCallback(() => {

        if (filterFields !== undefined && filterFields.length > 0 && conditionItems.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: filterFields,
                comparators: getComponentComparationType(filterFields[0].type),
                selectedField: filterFields[0],
                selectedComparator: getComponentComparationType(filterFields[0].type)[0],
                value: "",
                options: filterFields[0].options,
                nameComponent: getFilterNameComponent(filterFields[0].type)
            }

            setConditionItems([newConditionItem]);

        }

    }, [filterFields, conditionItems]);

    const handleSaveElement = useCallback(() => {

        let newElement = elementSelected;

        if (title !== undefined && title !== null && title !== "") {
            newElement.title = title;
        }

        onAddNewElementLocal(newElement);

    }, [elementSelected, onAddNewElementLocal, title]);

    useEffect(() => {

        let newField: FieldProps[] = [];
        let filterFields: FieldProps[] = [];

        //Data de Vencimento
        filterFields.push({
            id_field: -1,
            name: "-1",
            type: "DATE_PICKER_FIELD",
            title: "Data de Vencimento",
            description: "Data de Vencimento",
            index: -1
        });

        //Data de Criação
        filterFields.push({
            id_field: -2,
            name: "-2",
            type: "DATE_PICKER_FIELD",
            title: "Data de Criação",
            description: "Data de Criação",
            index: -2
        });

        //Responsável
        filterFields.push({
            id_field: -3,
            name: "-3",
            type: "COMBO_BOX_USER_FIELD",
            title: "Responsável",
            description: "Responsável",
            index: -3,
            form_id: flow.form_init?.id_form
        });

        //Etapa
        filterFields.push({
            id_field: -4,
            name: "-4",
            type: "COMBO_BOX_FIELD",
            title: "Etapa",
            description: "Etapa",
            index: -4,
            options: flow.flow_steps ? flow.flow_steps.map((step, index) => {
                return {
                    id_field_option: step.id_step,
                    field_id: -4,
                    value: String(step.id_step),
                    label: step.name,
                    order: String(index)
                } as FieldOptionsProps
            }) : undefined
        });

        //Etiqueta
        filterFields.push({
            id_field: -5,
            name: "-5",
            type: "COMBO_BOX_FIELD",
            title: "Etiqueta",
            description: "Etiqueta",
            index: -5,
            flow_id: flow.id_flow
        });

        //Get all fields 
        if (flow !== undefined) {

            if (flow.form_init !== undefined && flow.form_init !== null) {
                if (flow.form_init.fields !== undefined && flow.form_init.fields !== null && flow.form_init.fields.length > 0) {
                    newField.push(...flow.form_init.fields);
                }
            }

            if (flow.flow_steps !== undefined) {
                for (let index = 0; index < flow.flow_steps.length; index++) {
                    const step = flow.flow_steps[index];

                    if (step.form !== undefined) {
                        if (step.form.fields !== undefined && step.form.fields.length > 0) {
                            newField.push(...step.form.fields);
                        }
                    }

                }
            }

            filterFields.push(...newField);

            setFieldsAxisX(newField.filter((field) => getFieldObject(field.type).onFilter));
            setFieldsAxisY(newField.filter((field) => getFieldObject(field.type).onFilter && getFieldObject(field.type).onAgregateField));

            setFilterFields(filterFields.filter((f) => f.index < 0 || getFieldObject(f.type).onFilter));

        }

        if (element !== undefined) {

            //Set the default value
            if (element.title === undefined) {
                element.title = getElementObject(element.type).title;
            }

            if (element.axis_x_field_id === undefined && element.type !== "NUMBER_CHART") {
                if (element.type === "LINE_CHART_STACKED") {
                    element.axis_x_field_id = -5;
                    element.group_date = "month";
                } else {
                    element.axis_x_field_id = -1;
                }
            }

            if (element.axis_y_field_id === undefined) {
                element.axis_y_field_id = -1;
            }

            if (element.calc_function === undefined) {
                element.calc_function = "sum";
            }

            if (element.show_time === undefined) {
                element.show_time = "day";
            }

            if (element.group_date === undefined) {
                element.group_date = "day";
            }

            if (element.color === undefined) {
                if (element.type === "NUMBER_CHART") {
                    element.color = "#000000";
                } else {
                    element.color = "#f23b5c";
                }
            }

            if (element.sort_by === undefined) {
                if (element.type === "LINE_CHART_STACKED") {
                    element.sort_by = "axis_x_asc";
                } else {
                    element.sort_by = "axis_y_desc";
                }
            }

            if (element.filter !== undefined && element.filter !== null) {
                setConditionItems(JSON.parse(element.filter));
            }

            //Custom by element
            if (element.type === "LINE_CHART_STACKED") {
                element.group_by_field_id = -1;
            } else if (element.type === "HEAT_MAP_CHART") {
                if (element.group_by_field_id === undefined) {
                    element.group_by_field_id = -1;
                }

                if (element.axis_x_field_id === undefined) {
                    element.axis_x_field_id = -5;
                }

                if (element.group_date === undefined) {
                    element.group_date = "month";
                }

                if (element.sort_by === undefined) {
                    element.sort_by = "axis_x_asc";
                }
            } else if (element.type === "COLUMN_STACKED_CHART") {
                if (element.group_by_field_id === undefined) {
                    element.group_by_field_id = -1;
                }

                if (element.axis_x_field_id === undefined) {
                    element.axis_x_field_id = -5;
                }

                if (element.group_date === undefined) {
                    element.group_date = "month";
                }

                if (element.sort_by === undefined) {
                    element.sort_by = "axis_x_asc";
                }


            }

        }

    }, [flow, element])

    useEffect(() => {

        if (!openFilter) {

            setLoading(true);

            api.post(`/panel/data-element/v2`, {
                element: elementSelected,
                flow_id: flow.id_flow,
                isTestMode
            }).then(response => {
                if (response.data !== null) {

                    const respData: DataElementProps[] = response.data;

                    if (respData !== undefined) {

                        setDataPreview(respData);

                    }

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao carregar os dados do Elemento',
                    description: 'Ocorreu ao tentar carregar os dados do Elemento!',
                });
            });

        }

    }, [elementSelected, addToast, flow.id_flow, isTestMode, openFilter]);

    useEffect(() => {

        //Update the filter state
        if (conditionItems.length !== undefined && conditionItems.length >= 1) {

            let ret = false;
            let fieldsOn: string[] = [];

            conditionItems.map((cond) => {
                if (cond.value !== "" || (cond.valueOptions !== undefined && cond.valueOptions.length >= 1) || cond.selectedComparator === "Está em branco" || cond.selectedComparator === "Não está em branco") {
                    ret = true;
                    fieldsOn.push(cond.selectedField.title);
                }

                return cond;
            });

            //Update the fields and comparators
            onChangeFilter(conditionItems);

            setFilterState(ret);
            if (ret) {
                let message = "Filtrando ";

                if (fieldsOn.length === 1) {
                    fieldsOn.map((fil, index) => {

                        if (index === 0 && fieldsOn.length === 1) {
                            message += fil;
                        }

                        return fil;
                    });
                } else {
                    message += (fieldsOn.length) + " campos";
                }

                setFilterStateMessage(message);
            }

        } else {
            setFilterState(false);
        }

    }, [conditionItems, onChangeFilter]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, [loading]);


    return (
        <Container>
            <LeftSide>
                <TitleContainer>
                    <TitleInput
                        value={title}
                        maxLength={45}
                        disabled={isEditing}
                        placeholder="Clique aqui para adicionar um título"
                        onChange={(e) => onChangeTitle(e.currentTarget.value)}
                    />
                    {elementSelected.type !== "EMBED" &&
                        <FilterButton active={filterState} onClick={() => {
                            addDefaultItemsFilter();
                            setOpenFilter(!openFilter);
                        }}>
                            <MdOutlineFilterList />
                            {filterState ? filterStateMessage : "Adicionar Filtro"}
                        </FilterButton>
                    }

                    {openFilter && elementSelected.type !== "EMBED" ? (
                        <BoxContainer>

                            {conditionItems !== undefined && conditionItems.map((condition, index) => {

                                const nameComponent: string = getFilterNameComponent(condition.selectedField.type);

                                let FilterComponent = getFilterComponent(nameComponent);

                                return (

                                    <FilterContainer key={condition.index}>

                                        <SelectInputFilter value={condition.selectedField.id_field} onChange={(e) => onSelectFieldFilter(e.currentTarget.value, index)}>
                                            <optgroup label="Campos Padrões">
                                                <option key={-1} value={-1}>{"Data de Vencimento"}</option>
                                                <option key={-2} value={-2}>{"Data de Criação"}</option>
                                                <option key={-3} value={-3}>{"Responsável"}</option>
                                                <option key={-4} value={-4}>{"Etapa"}</option>
                                                <option key={-5} value={-5}>{"Etiqueta"}</option>
                                            </optgroup>
                                            {filterFields && filterFields.filter((f) => f.index >= 0).length > 0 ?
                                                <optgroup label="Campos Customizáveis">
                                                    {filterFields.filter((f) => f.index >= 0).map((field) => {
                                                        return (
                                                            <option key={field.id_field} value={field.id_field}>{field.title}</option>
                                                        )
                                                    })}
                                                </optgroup> :
                                                <></>}
                                        </SelectInputFilter>

                                        <SelectComparator value={condition.selectedComparator} onChange={(e) => onSelectComparator(e.currentTarget.value, index)}>
                                            {condition.comparators.map((comparator) => {
                                                return (
                                                    <option key={comparator} value={comparator}>{comparator}</option>
                                                )
                                            })}
                                        </SelectComparator>

                                        <FilterComponent
                                            comparator={condition.selectedComparator}
                                            onEvent={(e, cond) => onEventFieldComponent(index, e?.currentTarget.value, cond)}
                                            value={condition.value}
                                            valueOptions={condition.valueOptions}
                                            field={condition.selectedField}
                                            options={condition.selectedField.options}
                                        />

                                        <DeleteButton onClick={() => handleDeleteConditionFilter(index)}>
                                            <FaTrash />
                                        </DeleteButton>

                                    </FilterContainer>

                                )

                            })}

                            {(conditionItems === undefined || conditionItems.length <= 0) ? (
                                <div style={{ padding: '10px', fontSize: '14px' }}>Você não possuí campos para filtrar ainda, adicione novos campos para usar esta funcionalidade ;)</div>
                            ) : (

                                <FilterBottom>

                                    <AddNewFilter onClick={() => addNewConditionFilter()}>
                                        <FaPlus />
                                        Adicionar condição
                                    </AddNewFilter>

                                    <CleanFilter onClick={() => handleDeleteAllConditionFilter()}>
                                        <FaTrash />
                                        Limpar Tudo
                                    </CleanFilter>

                                    <GoFilter onClick={() => setOpenFilter(!openFilter)}>
                                        <AiFillThunderbolt />
                                        Filtrar
                                    </GoFilter>

                                </FilterBottom>

                            )}

                        </BoxContainer>
                    ) : <></>
                    }

                </TitleContainer>
                <ContentElement>

                    {loading ?
                        <ContainerLoader>
                            <Animation
                                className="lottieContainer"
                                ref={lottieContainer}
                            />
                        </ContainerLoader> :
                        <ElementComponent
                            id_panel_element={elementSelected.id_panel_element}
                            hash={elementSelected.hash}
                            panel_id={elementSelected.panel_id}
                            title={elementSelected.title}
                            help_text={elementSelected.help_text}
                            type={elementSelected.type}
                            axis_x_field_id={elementSelected.axis_x_field_id}
                            axis_x_field_type={elementSelected.axis_x_field_type}
                            axis_y_field_id={elementSelected.axis_y_field_id}
                            axis_y_field_type={elementSelected.axis_y_field_type}
                            axis_y_field_variation={elementSelected.axis_y_field_variation}
                            group_by_field_id={elementSelected.group_by_field_id}
                            group_by_field_type={elementSelected.group_by_field_type}
                            group_by_field_date={elementSelected.group_by_field_date}
                            calc_function={elementSelected.calc_function}
                            show_time={elementSelected.show_time}
                            group_date={elementSelected.group_date}
                            filter={elementSelected.filter}
                            color={elementSelected.color}
                            sort_by={elementSelected.sort_by}
                            width={elementSelected.width}
                            height={elementSelected.height}
                            embed={elementSelected.embed}
                            dataElement={dataPreview !== undefined ? dataPreview : elementSelected.dataElement}
                        />
                    }
                </ContentElement>
            </LeftSide>
            <RightSide>

                {!isEditing && (
                    <BackBtn onClick={() => onBackToStore()}>
                        <IoIosArrowRoundBack />
                        Voltar para o menu
                    </BackBtn>
                )}

                <SectionScroll>
                    <ContentConfig>

                        <ConfigTitle>
                            <BsGearWideConnected />
                            Configurações
                        </ConfigTitle>

                        <ElementComponentConfig
                            elementSelected={elementSelected}
                            fieldsAxisX={fieldsAxisX}
                            fieldsAxisY={fieldsAxisY}
                            onSelectAxisX={onSelectAxisX}
                            onSelectAxisY={onSelectAxisY}
                            onSelectGroupBy={onSelectGroupBy}
                            onSelectGroupByDate={onSelectGroupByDate}
                            onSelectSortBy={onSelectSortBy}
                            onSelectCalcFunction={onSelectCalcFunction}
                            onSelectShowTime={onSelectShowTime}
                            onSelectGroupDate={onSelectGroupDate}
                            onSelectColor={onSelectColor}
                            onChangeEmbed={onChangeEmbed}
                        />

                    </ContentConfig>

                    {/*
                    <ContentConfig>

                        <ConfigTitle>
                            <AiFillFilter />
                            Filtros
                        </ConfigTitle>

                    </ContentConfig>
                    */}

                </SectionScroll>


                <BottomAction>
                    {!isEditing ?
                        <ButtonCancel type="button" color="#f4f4f4" onClick={() => onBackToStore()}>
                            Cancelar
                        </ButtonCancel> :
                        <ButtonCancel type="button" color="#f4f4f4" onClick={() => onClose()}>
                            Fechar
                        </ButtonCancel>
                    }
                    <ButtonConfirm type="button" onClick={() => handleSaveElement()}>
                        Salvar
                    </ButtonConfirm>
                </BottomAction>
            </RightSide>
        </Container>
    );

}

export default ElementConfig;
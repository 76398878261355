import React, { CSSProperties, useCallback, useEffect, useState } from "react";

import LayoutContainer from '../../components/LayoutContainer';
import ListContainer from '../../components/ListContainer';

import {
    ButtonConfig,
    ButtonUserAdd,
    Container,
    ContainerBody,
    ContainerContent,
    ContainerLeft,
    ContainerRight,
    ContentBody,
    FlowContainer,
    UserContainer,
    AvatarContainer,
    AddNewUserAvatar,
    FlowNewItem,
    FlowNewItemContainer,
    FlowNewContainerBottom,
    DescriptionEmpty,
    EditDescriptionContainer,
    ContainerDescriptionHtml,
    FlowItem,
    FlowHeader,
    FlowHeaderLeft,
    FlowStar,
    FlowHeaderCenter,
    FlowHeaderRigth,
    FlowConfig,
    FlowItemContainer,
    FlowContainerBottom,
    FlowRemoveContainer,
    FlowRemoveBox,
    KpiContainer,
    KpiIconContainer,
    KpiInformationContainer,
    KpiValue,
    KpiDescription,
    KpiItemsContainer
} from "./styles";

import { FaCalendarDay, FaPlayCircle, FaPlus, FaStar, FaStore } from "react-icons/fa";
import Title from "../../components/Title";
import Description from "../../components/Description";
import { IoMdPersonAdd } from "react-icons/io";
import { BsGearFill } from "react-icons/bs";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import { Workspace as WorkspaceInterface } from "../../interfaces/Workspace";
import PageHeaderWorkspace from "../../components/PageHeaderWorkspace";
import AddNewWorkspace from "../../dialogs/AddNewWorkspace";
import { MdOutlineDescription } from "react-icons/md";
import AddDescription from "./AddDescription";
import AddFlow from "./AddFlow";
import { Flow } from "../../interfaces/Flow";
import { AiFillHeart, AiFillLock } from "react-icons/ai";
import getAccessControl from "../../middlewares/AccessControl";
import InviteUser from "../../dialogs/InviteUser";
import { IoClose } from "react-icons/io5";
import { RiAlertFill } from "react-icons/ri";
import AvatarCange from "../../components/AvatarCange";

interface WorkspaceProps {

}

interface RouteParams {
    hash: string;
}

interface IStats {
    tot_in_progress: number;
    tot_due: number;
    tot_overdue: number;
    avg_health: number;
}

function compare(a: Flow, b: Flow) {
    if (a.isStared !== undefined && b.isStared !== undefined) {
        if (a.isStared > b.isStared) {
            return -1;
        }
        if (a.isStared < b.isStared) {
            return 1;
        }
    }
    return 0;
}

const maskPercent = (value: string | number) => {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    if (!isNaN(numericValue)) {
        const roundedValue = Math.round(numericValue * 100);
        return `${roundedValue}%`;
    }
    return '0%'; // Valor padrão se a entrada não for um número válido
};

const linkStyle: CSSProperties = {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const Workspace: React.FC<WorkspaceProps> = () => {

    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();

    const history = useHistory();

    const [openInviteUser, setOpenInviteUser] = useState(false);
    const [openNewWorkspace, setOpenNewWorkspace] = useState<boolean>(false);
    const [openDescription, setOpenDescription] = useState<boolean>(false);
    const [openAddFlow, setOpenAddFlow] = useState<boolean>(false);
    const [editingFlows, setEditingFlows] = useState<boolean>(false);
    const [workspaceStats, setWorkspaceStats] = useState<IStats>();
    const [workspace, setWorkspace] = useState<WorkspaceInterface>();
    const [flows, setFlows] = useState<Flow[]>([]);

    const getWorkspaceStatsApi = useCallback(async (id_workspace: number) => {

        await api.get(`/workspace/stats`, {
            params: {
                id_workspace: id_workspace
            }
        }).then(response => {
            if (response.data !== null) {

                let retStats: IStats = response.data;

                setWorkspaceStats(retStats);

            }
        })

    }, []);

    const getMyFlowsApi = useCallback(async (id_workspace: number) => {

        await api.get(`/flow/my-flows/by-workspace`, {
            params: {
                workspace_id: id_workspace
            }
        }).then(response => {
            if (response.data !== null) {

                let retFlows: Flow[] = response.data;

                //Order by Favorite
                retFlows.sort(compare);

                setFlows(retFlows);

            }
        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao carregar os seus Fluxos :(',
                description: 'Ocorreu ao tentar carregar os seus fluxos!',
            });
        });

    }, [addToast]);

    const getWorkspaceApi = useCallback(async () => {

        if (params.hash !== undefined) {

            await api.get(`/workspace`, {
                params: {
                    hash: params.hash
                }
            }).then(response => {
                if (response.data !== null) {

                    const workspace: WorkspaceInterface = response.data;

                    setWorkspace(workspace);

                    getWorkspaceStatsApi(workspace.id_workspace);

                }
            }).catch(error => {
                history.push('/dashboard');
                addToast({
                    type: 'error',
                    title: 'Erro ao carregar o Worskspace',
                    description: 'Ocorreu ao tentar abrir o seu Workspace!',
                });
            });

        }

    }, [addToast, params.hash, history, getWorkspaceStatsApi]);

    const handleStarFlow = useCallback(async (flowStared: Flow) => {

        if (flowStared.id_flow !== undefined) {

            const newStared: string = flowStared.isStared === "N" ? "S" : "N";

            await api
                .post('/flow-user-preference', {
                    flow_id: flowStared.id_flow,
                    isStared: newStared
                })
                .then(response => {

                    if (workspace !== undefined) {
                        getMyFlowsApi(workspace.id_workspace);
                    }

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao favoritar o Fluxo',
                        description: 'Ocorreu um erro ao tentar favoritar o Fluxo!',
                    });
                });

        }

    }, [addToast, getMyFlowsApi, workspace]);

    const handleConfigFlow = useCallback(async (flow: Flow) => {

        if (flow.name === "Rascunho") {

            history.push('/flow/1/' + flow.hash + '/new/');

        } else {

            history.push('/flow/1/' + flow.hash + '/edit/');

        }

    }, [history]);

    const handleNewWorkspaceClose = useCallback(async (newWorkspace?: WorkspaceInterface) => {

        setOpenNewWorkspace(false)

    }, []);

    const handleRemoveFlow = useCallback(async (flow_id: number) => {

        if (workspace !== undefined && workspace.workspace_flows !== undefined && workspace.workspace_flows.length > 0) {

            //find the id_workspace_flow
            const workspaceFlow = workspace.workspace_flows.filter((wf) => wf.flow_id === flow_id);

            if (workspaceFlow !== undefined && workspaceFlow.length > 0) {

                await api.delete('/workspace/flow', {
                    params: {
                        id_workspace_flow: workspaceFlow[0].id_workspace_flow,
                        workspace_id: workspace.id_workspace,
                        flow_id: flow_id
                    }
                }).then(response => {

                    getWorkspaceApi();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao remover o relacionamento do fluxo com o workspace',
                        description: 'Ocorreu um erro ao tentar deletar o relacionamento!',
                    });
                });

            }

        }

    }, [workspace, addToast, getWorkspaceApi]);

    const dialogDeleteSubmmit = useCallback(async () => {

        if (workspace !== undefined) {

            await api.delete('/workspace', {
                params: {
                    id_workspace: workspace.id_workspace
                }
            }).then(response => {

                history.push('/dashboard');
                addToast({
                    type: 'success',
                    title: 'Workspace deletado com sucesso!',
                    description: 'Wow! O seu workspace foi deletado com sucesso! :)',
                });

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar o workspace',
                    description: 'Ocorreu um erro ao tentar deletar o workspace!',
                });
            });


        }

    }, [workspace, history, addToast]);

    useEffect(() => {

        if (!openNewWorkspace || !openDescription || !openAddFlow || !openInviteUser) {
            getWorkspaceApi();
        }

    }, [getWorkspaceApi, openNewWorkspace, openDescription, openAddFlow, openInviteUser]);

    useEffect(() => {

        if (workspace !== undefined) {
            getMyFlowsApi(workspace.id_workspace);
        }

    }, [workspace, getMyFlowsApi]);

    return (
        <LayoutContainer>
            <title>Meus Fluxos | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <PageHeaderWorkspace
                    icon={FaStore}
                    title={workspace !== undefined ? workspace.name : "Carregando..."}
                    iconDynamic={workspace !== undefined ? workspace.icon as IconList : "FaEllipsisH" as IconList}
                    iconDynamicColor={workspace !== undefined ? workspace.color : "#f23c5d"}
                >

                    {getAccessControl(64, workspace?.typeUserAccess !== undefined ? workspace?.typeUserAccess : "") ?
                        <>
                            <ButtonUserAdd
                                icon={IoMdPersonAdd}
                                type="button"
                                color="#4680B8"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                onClick={() => setOpenInviteUser(!openInviteUser)}
                            >
                                Convidar
                            </ButtonUserAdd>
                            {openInviteUser ?
                                <InviteUser
                                    open={openInviteUser}
                                    onClose={() => setOpenInviteUser(false)}
                                    workspace={workspace}
                                    typeUser={workspace?.typeUserAccess !== undefined ? workspace?.typeUserAccess : ""}
                                    title="Convide membros para colaborar"
                                /> :
                                <></>
                            }
                        </> :
                        <></>
                    }

                    {getAccessControl(65, workspace?.typeUserAccess !== undefined ? workspace?.typeUserAccess : "") ?
                        <ButtonConfig
                            icon={BsGearFill}
                            type="button"
                            color="#f4f4f4"
                            height="36px"
                            margin="0px 15px 0px 0px"
                            color_text="gray"
                            onClick={() => {
                                setOpenNewWorkspace(true);
                            }}
                        >
                        </ButtonConfig> :
                        <></>
                    }

                </PageHeaderWorkspace>

                <AddNewWorkspace
                    open={openNewWorkspace}
                    selectedWorkspace={workspace}
                    onClose={handleNewWorkspaceClose}
                    dialogDeleteSubmmit={dialogDeleteSubmmit}
                />

                <Container>
                    <ContentBody>
                        <ContainerLeft
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                            style={{ marginBottom: '10px', marginRight: '15px' }}
                        >

                            <ContainerBody>

                                <ContainerContent>

                                    <Title
                                        title="Insights"
                                        required={false}
                                    />

                                    <KpiItemsContainer>

                                        <KpiContainer color={workspaceStats?.avg_health !== undefined ?
                                            workspaceStats.avg_health >= 0.85 ? "#61bd4f1e" :
                                                workspaceStats.avg_health > 0.50 ? "#ff8c2f1e" :
                                                    workspaceStats.avg_health <= 0.50 ? "#f23b5c1e" :
                                                        "#f23b5c1e" : "#f23b5c1e"
                                        }>
                                            <KpiIconContainer
                                                color={workspaceStats?.avg_health !== undefined ?
                                                    workspaceStats.avg_health >= 0.85 ? "#61bd4f" :
                                                        workspaceStats.avg_health > 0.50 ? "#ff8c2f" :
                                                            workspaceStats.avg_health <= 0.50 ? "#f23b5c" :
                                                                "#f23b5c" : "#f23b5c"
                                                }
                                                activeMotion={true}
                                            >
                                                <AiFillHeart />
                                            </KpiIconContainer>
                                            <KpiInformationContainer>
                                                <KpiValue>
                                                    {workspaceStats?.avg_health !== undefined ? maskPercent(workspaceStats.avg_health) : 0}
                                                </KpiValue>
                                                <KpiDescription>
                                                    Saúde do seu workspace
                                                </KpiDescription>
                                            </KpiInformationContainer>
                                        </KpiContainer>

                                        <KpiContainer color="#9e37ed1e">
                                            <KpiIconContainer color="#9e37ed">
                                                <FaPlayCircle />
                                            </KpiIconContainer>
                                            <KpiInformationContainer>
                                                <KpiValue>
                                                    {workspaceStats?.tot_in_progress !== undefined ? workspaceStats?.tot_in_progress : 0}
                                                </KpiValue>
                                                <KpiDescription>
                                                    Cartões em andamento
                                                </KpiDescription>
                                            </KpiInformationContainer>
                                        </KpiContainer>

                                        <KpiContainer color="#ff8c2f1e">
                                            <KpiIconContainer color="#ff8c2f">
                                                <FaCalendarDay />
                                            </KpiIconContainer>
                                            <KpiInformationContainer>
                                                <KpiValue>
                                                    {workspaceStats?.tot_due !== undefined ? workspaceStats?.tot_due : 0}
                                                </KpiValue>
                                                <KpiDescription>
                                                    Cartões a vencer
                                                </KpiDescription>
                                            </KpiInformationContainer>
                                        </KpiContainer>

                                        <KpiContainer color="#f23b5c1e">
                                            <KpiIconContainer color="#f23b5c">
                                                <RiAlertFill />
                                            </KpiIconContainer>
                                            <KpiInformationContainer>
                                                <KpiValue>
                                                    {workspaceStats?.tot_overdue !== undefined ? workspaceStats?.tot_overdue : 0}
                                                </KpiValue>
                                                <KpiDescription>
                                                    Cartões atrasados
                                                </KpiDescription>
                                            </KpiInformationContainer>
                                        </KpiContainer>

                                    </KpiItemsContainer>

                                </ContainerContent>

                                <ContainerContent>

                                    <Title
                                        title="Fluxos"
                                        required={false}
                                    />

                                    {getAccessControl(67, workspace?.typeUserAccess !== undefined ? workspace?.typeUserAccess : "") ?
                                        !editingFlows ?
                                            <EditDescriptionContainer onClick={() => setEditingFlows(true)}>
                                                Editar
                                            </EditDescriptionContainer> :
                                            <EditDescriptionContainer onClick={() => setEditingFlows(false)}>
                                                Modo Visualização
                                            </EditDescriptionContainer> :
                                        <></>
                                    }

                                    <FlowContainer>

                                        {getAccessControl(67, workspace?.typeUserAccess !== undefined ? workspace?.typeUserAccess : "") ?
                                            <>
                                                <FlowNewItem onClick={() => setOpenAddFlow(true)}>
                                                    <FlowNewItemContainer>
                                                        <IconPickerItem
                                                            icon={'FaPlus'}
                                                            color={"white"}
                                                        />
                                                    </FlowNewItemContainer>
                                                    <FlowNewContainerBottom>
                                                        <h2>
                                                            Adicionar Fluxo
                                                        </h2>
                                                    </FlowNewContainerBottom>
                                                </FlowNewItem>

                                                <AddFlow
                                                    open={openAddFlow}
                                                    workspace_id={workspace !== undefined ? workspace.id_workspace : undefined}
                                                    onClose={() => setOpenAddFlow(false)}
                                                    currentFlows={flows}
                                                />
                                            </> :
                                            <></>
                                        }

                                        {flows.map((flow) => {
                                            return (
                                                <FlowItem key={flow.hash} color={flow.color}>
                                                    <FlowHeader>
                                                        <FlowHeaderLeft>
                                                            <FlowStar className="flow-star" isStarred={flow.isStared} onClick={() => handleStarFlow(flow)}>
                                                                <FaStar />
                                                            </FlowStar>
                                                        </FlowHeaderLeft>
                                                        <FlowHeaderCenter title="Fluxo Privado" className="flow-config">
                                                            {flow.isPrivate === "1" ?
                                                                <AiFillLock />
                                                                : <></>}
                                                        </FlowHeaderCenter>
                                                        <FlowHeaderRigth>
                                                            {getAccessControl(8, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                                <FlowConfig className="flow-config" onClick={() => handleConfigFlow(flow)}>
                                                                    <BsGearFill />
                                                                </FlowConfig> : <></>
                                                            }
                                                        </FlowHeaderRigth>
                                                    </FlowHeader>

                                                    {getAccessControl(67, workspace?.typeUserAccess !== undefined ? workspace?.typeUserAccess : "") && editingFlows ?
                                                        <FlowRemoveContainer id="remove-item" onClick={() => handleRemoveFlow(flow.id_flow)}>
                                                            <FlowRemoveBox>
                                                                <IoClose />
                                                            </FlowRemoveBox>
                                                        </FlowRemoveContainer> :
                                                        <></>
                                                    }

                                                    <Link to={() => {
                                                        if (flow.name === "Rascunho") {
                                                            return '/flow/1/' + flow.hash + '/new/';
                                                        } else {
                                                            return '/flow/' + flow.hash;
                                                        }
                                                    }} style={linkStyle}>
                                                        <div style={{ textAlign: "center", display: "contents" }}>
                                                            <FlowItemContainer color={flow.color}>
                                                                <IconPickerItem
                                                                    icon={flow.icon ? flow.icon as IconList : 'FaCodeBranch'}
                                                                    color={flow.color ? flow.color : "#f23b5c"}
                                                                />
                                                            </FlowItemContainer>
                                                            <FlowContainerBottom>
                                                                <h2>
                                                                    {flow.name}
                                                                </h2>
                                                                {flow?.total_cards !== undefined && flow?.total_cards > 0 ?
                                                                    <span>{flow?.total_cards} {flow?.total_cards > 1 ? "Cards" : "Card"}</span> :
                                                                    <></>
                                                                }
                                                            </FlowContainerBottom>
                                                        </div>
                                                    </Link>
                                                </FlowItem>
                                            )
                                        })}

                                    </FlowContainer>

                                </ContainerContent>
                            </ContainerBody>
                        </ContainerLeft>

                        <ContainerRight
                            item
                            sm={4}
                            md={4}
                            lg={4}
                            className="container-right"
                            style={{ marginBottom: '10px' }}
                        >
                            <ContainerBody>
                                <ContainerContent>

                                    <Title
                                        title="Sobre o Workspace"
                                        required={false}
                                    />
                                    {getAccessControl(66, workspace?.typeUserAccess !== undefined ? workspace?.typeUserAccess : "") ?
                                        <EditDescriptionContainer onClick={() => setOpenDescription(true)}>
                                            Editar
                                        </EditDescriptionContainer> :
                                        <></>
                                    }
                                    <div style={{ marginBottom: '20px' }} />
                                    {workspace !== undefined && workspace.description !== undefined && workspace.description !== null && workspace.description !== "" ?
                                        <Description>
                                            <ContainerDescriptionHtml style={{ fontWeight: 'normal' }} dangerouslySetInnerHTML={{ __html: workspace.description }} />
                                        </Description> :
                                        getAccessControl(66, workspace?.typeUserAccess !== undefined ? workspace?.typeUserAccess : "") ?
                                            <DescriptionEmpty onClick={() => setOpenDescription(true)}>
                                                <MdOutlineDescription />
                                                Crie uma descrição personalizada para o seu espaço de trabalho
                                            </DescriptionEmpty> :
                                            <></>
                                    }
                                </ContainerContent>

                                <AddDescription
                                    open={openDescription}
                                    selectedWorkspace={workspace}
                                    onClose={() => setOpenDescription(false)}
                                />

                                <ContainerContent>

                                    <Title
                                        title="Membros"
                                        required={false}
                                        badge={workspace?.workspace_users !== undefined ? String(workspace?.workspace_users.length) : undefined}
                                    />
                                    <Description>Abaixo estão os usuários que fazem parte deste workspace</Description>

                                    <UserContainer>

                                        {getAccessControl(64, workspace?.typeUserAccess !== undefined ? workspace?.typeUserAccess : "") ?
                                            <AvatarContainer style={{ padding: '0px' }} onClick={() => setOpenInviteUser(true)}>
                                                <AddNewUserAvatar>
                                                    <FaPlus />
                                                </AddNewUserAvatar>
                                            </AvatarContainer> :
                                            <></>
                                        }

                                        {workspace?.workspace_users !== undefined && workspace?.workspace_users.length > 0 ? workspace?.workspace_users.map((user) => {
                                            return (
                                                <AvatarContainer>
                                                    <AvatarCange
                                                        user={user.user}
                                                        size="45"
                                                    />
                                                </AvatarContainer>
                                            )
                                        }) :
                                            <></>
                                        }

                                    </UserContainer>

                                </ContainerContent>
                            </ContainerBody>
                        </ContainerRight>

                    </ContentBody>
                </Container>

            </ListContainer>

        </LayoutContainer >
    );

}

export default Workspace;
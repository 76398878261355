import { Container, InputCB, ItemCB, LabelCB, MarginTop, SpanCB } from "./styles";
import React, { InputHTMLAttributes, useCallback, useEffect, useRef } from "react";

import ErrorDescription from "../../../../ErrorDescription";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";

interface FieldOptionsProps {
  id_field_option?: number;
  field_id?: number;
  value: string;
  label: string;
  order?: string;
  hide?: string;
  old_id_field_option?: number;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  description?: string;
  options?: FieldOptionsProps[];
}

const CheckBox: React.FC<InputProps> = ({ name, options }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  if (options === undefined) {
    options = [
      {
        value: "none",
        label: "Selecione aqui..."
      }
    ];
  }

  const isInDefaultValue = useCallback((value: string): boolean => {

    if (typeof defaultValue === "string") {

      if (String(defaultValue) === value) {
        return true;
      }

    } else if (typeof defaultValue === "object") {

      const arrDefault = defaultValue as unknown as String[];

      for (let index = 0; index < arrDefault.length; index++) {
        const element = arrDefault[index];

        if (element === value) {
          return true;
        }
      }

    }

    return false;

  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs) {
        const checkeds: HTMLInputElement[] = refs.filter((ref: HTMLInputElement) => {
          return ref.checked === true;
        });

        return checkeds.map(({ value }) => value);
      },
      setValue(refs, value) {

        if (typeof value === "object") {
          const arrDefault = value as unknown as String[];

          for (let index = 0; index < arrDefault.length; index++) {
            const element = arrDefault[index];
            const item = refs.find((ref: HTMLInputElement) => ref.value === element);

            if (item) {
              item.checked = true;
            }
          }
        } else {
          const item = refs.find((ref: HTMLInputElement) => ref.value === value);

          if (item) {
            item.checked = true;
          }
        }

      },
      clearValue: (refs) => {
        return refs.map((i: any) => {
          i.checked = false;
          return i;
        })
      }
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <MarginTop>
      <Container>
        {options
          .filter((opt) => opt.hide !== "S")
          .sort((a, b) => {
            if (a.order !== undefined && b.order !== undefined) {
              return Number(a.order) - Number(b.order);
            } else {
              return 0;
            }
          })
          .map((option, index) => (
            <ItemCB key={option.value}>
              <InputCB
                id={option.value}
                ref={(elRef) => (inputRefs.current[index] = elRef as HTMLInputElement)}
                type="checkbox"
                name={fieldName}
                value={option.value}
                defaultChecked={isInDefaultValue(option.value)}
              />
              <LabelCB />
              <SpanCB htmlFor={option.value}>{option.label}</SpanCB>
            </ItemCB>
          ))}
      </Container>
      {error && (<ErrorDescription title={error} />)}
    </MarginTop>
  );
};

export default CheckBox;

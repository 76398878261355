import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DividerMenu,
    Container,
    ContainerLeft,
    ContainerRight,
    ContainerNewAutomation,
    BtnNewAutomation,
    ChartContainer,
    ChartItem,
    ChartItemTitle,
    ChartItemBody,
    ContainerAutomations,
    ContainerLoader,
    Animation,
    ButtonClose,
    AutomationsGroup,
    AutomationsGroupHeader,
    AutomationsGroupName,
    AutomationsGroupItems,
    AutomationsGroupBody,
    AutomationsItem,
    ContainerLeftBody,
    AutomationsItemIconTrigger,
    AutomationsItemIconName,
    AutomationsItemIconDivider,
    AutomationsItemIconAction,
    AutomationsItemGroupActions,
    AutomationsItemLeft,
    AutomationsItemRight,
    ContainerEmpty,
    ContainerIlustration,
    ContainerInfo,
    ButtonFirstNewAutomation,
    ButtonLink,
} from "./style";

import { Dialog } from '@material-ui/core';
import { AiOutlineClose, AiOutlineHistory } from "react-icons/ai";
import { IoFlash } from "react-icons/io5";
import NewAutomation from "./NewAutomation";
import { Flow } from "../../interfaces/Flow";
import api from "../../services/api";
import Automation from "../../interfaces/Automation";
import { useToast } from "../../hooks/toast";
import lottie from "lottie-web";
import AppBarHeader from "../../components/AppBarHeader";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { BsArrowRightSquare, BsToggleOff, BsToggleOn } from "react-icons/bs";
import AutomationsByDayChart from "./AutomationsByDayChart";
import StatUserItem from "../../components/Stats/StatUserItem";
import { useAuth } from "../../hooks/auth";
import emptyAutomations from '../../assets/empty-state/empty-automations.png';
import RunHistoryDialog from "./RunHistoryDialog";
import BadgeUpgrade from "../../components/Fremium/BadgeUpgrade";

interface AutomationProps {
    open: boolean;
    flow: Flow;
    onClose: () => void;
}

interface StatsData {
    automations_log: {
        company_id: number;
        total_executions: string;
        target?: number;
        bar_value?: number;
    }[];
    automations: {
        company_id: number;
        total_automations: string;
        target?: number;
        bar_value?: number;
    }[];
}

const plan_limits = {
    Free: {
        automations: 30,
        executions: 50,
        isAutomationsIlimited: false,
        daysLog: 14
    },
    Plus: {
        automations: 100,
        executions: 300,
        isAutomationsIlimited: false,
        daysLog: 365
    },
    Premium: {
        automations: 0,
        executions: 5000,
        isAutomationsIlimited: true,
        daysLog: 730
    },
    Enterprise: {
        automations: 0,
        executions: 10000,
        isAutomationsIlimited: true,
        daysLog: 1095
    }
}

function getLimitsPlan(plan: string) {

    if (plan === "Free") {
        return plan_limits["Free"];
    } else if (plan === "Plus") {
        return plan_limits["Plus"];
    } else if (plan === "Premium") {
        return plan_limits["Premium"];
    } else if (plan === "Enterprise") {
        return plan_limits["Enterprise"];
    }

    return plan_limits['Free'];

}

const Automations: React.FC<AutomationProps> = ({ onClose, open, flow }) => {

    const { addToast } = useToast();
    const { user } = useAuth();

    const lottieContainer = useRef<HTMLDivElement>(null);

    const [openAutomation, setOpenAutomation] = useState(false);
    const [openDialogRunHistory, setOpenDialogRunHistory] = useState(false);
    const [selectAutomation, setSelectAutomation] = useState<Automation | undefined>(undefined);
    const [statsData, setStatsData] = useState<StatsData>();
    const [userPlan, setUserPlan] = useState<string>('Free');

    const [loading, setLoading] = useState(false);
    const [automations, setAutomations] = useState<Automation[]>([]);

    //Manage the plan limits
    const [isOutExec, setIsOutExec] = useState(false);
    const [isOutAuto, setIsOutAuto] = useState(false);

    const groupAutomations: { [groupName: string]: Automation[] } = automations.reduce(
        (acc, item) => {

            if (item.active !== undefined) {
                if (acc[item.active]) {
                    acc[item.active].push(item);
                } else {
                    acc[item.active] = [item];
                }
            }

            return acc;
        },
        {} as { [groupName: string]: Automation[] }
    );


    const getApiStats = useCallback(async () => {

        if (flow !== undefined && flow.id_flow !== undefined) {
            setLoading(true);

            api.get(`/automation/stats`, {
                params: {
                    flow_id: flow.id_flow
                }
            }).then(response => {
                if (response.data !== null) {

                    let respAutomations: StatsData = response.data;

                    if (respAutomations !== undefined) {

                        //Calculate the target of the bar
                        if (respAutomations.automations_log !== undefined && respAutomations.automations_log.length > 0) {
                            const target_exec = getLimitsPlan(userPlan).executions;
                            const value_exec = Number(respAutomations.automations_log[0].total_executions);
                            const bar_exec: number = target_exec !== undefined && target_exec > 0 ? value_exec / target_exec * 100 : 0;

                            respAutomations.automations_log[0].target = target_exec;
                            respAutomations.automations_log[0].bar_value = bar_exec;

                            if (bar_exec >= 100) {
                                setIsOutExec(true);
                            } else {
                                setIsOutExec(false);
                            }
                        } else {
                            const target_exec = getLimitsPlan(userPlan).executions;
                            const value_exec = 0.0001;
                            const bar_exec: number = target_exec !== undefined && target_exec > 0 ? value_exec / target_exec * 100 : 0;

                            respAutomations.automations_log[0] = {
                                company_id: flow.company_id,
                                total_executions: '0',
                                target: target_exec,
                                bar_value: bar_exec
                            };
                        }

                        if (respAutomations.automations !== undefined && respAutomations.automations.length > 0) {
                            const target_auto = getLimitsPlan(userPlan).automations;
                            const value_auto = Number(respAutomations.automations[0].total_automations);
                            const bar_auto: number = target_auto !== undefined && target_auto > 0 ? value_auto / target_auto * 100 : 0;

                            respAutomations.automations[0].target = target_auto;
                            respAutomations.automations[0].bar_value = bar_auto;

                            if (bar_auto >= 100) {
                                setIsOutAuto(true);
                            } else {
                                setIsOutAuto(false);
                            }
                        } else {
                            const target_auto = getLimitsPlan(userPlan).automations;
                            const value_auto = 0.0001;
                            const bar_auto: number = target_auto !== undefined && target_auto > 0 ? value_auto / target_auto * 100 : 0.01;

                            respAutomations.automations[0] = {
                                company_id: flow.company_id,
                                total_automations: '0',
                                target: target_auto,
                                bar_value: bar_auto
                            };
                        }

                        setStatsData(respAutomations);
                    }

                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar os insights!',
                    description: 'Ocorreu um erro tentar buscar os insights!',
                });
            });
        }

    }, [addToast, flow, userPlan]);

    const getApiAutomation = useCallback(async () => {

        if (flow !== undefined && flow.id_flow !== undefined) {
            setLoading(true);

            api.get(`/automation/by-flow`, {
                params: {
                    flow_id: flow.id_flow
                }
            }).then(response => {
                if (response.data !== null) {

                    const respAutomations: Automation[] = response.data;

                    if (respAutomations !== undefined) {
                        setAutomations(respAutomations);
                    }

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar as automações!',
                    description: 'Ocorreu um erro tentar buscar as automações!',
                });
            });
        }

    }, [addToast, flow]);

    const handleOpenNewAutomation = useCallback(async () => {
        setOpenAutomation(true);
    }, []);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    useEffect(() => {

        if (!openAutomation) {
            getApiAutomation();
            getApiStats();
        }

    }, [getApiAutomation, getApiStats, openAutomation]);

    useEffect(() => {

        if (user !== undefined && user.company !== undefined && user.company.company_plan !== undefined) {
            if (user.company.company_plan[0].plan !== undefined) {
                setUserPlan(user.company.company_plan[0].plan.name);
            }
        }

    }, [user]);

    useEffect(() => {

        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader.json')
            });
        }

    }, [loading]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            fullScreen={true}
            open={open}
        >

            {openAutomation ?
                <NewAutomation
                    key={flow.id_flow}
                    flow={flow}
                    selectedAutomation={selectAutomation}
                    daysLog={getLimitsPlan(userPlan).daysLog}
                    onClose={() => {
                        setOpenAutomation(false)
                        setSelectAutomation(undefined);
                    }}
                /> :
                <>
                    {/* Header Page */}
                    <AppBarHeader
                        title={"Automações"}
                        icon={IoFlash}
                        iconDynamicColor="#f23b5d"
                        badgeInformation={flow.name}
                    >

                        <ButtonLink type="button" onClick={() => setOpenDialogRunHistory(true)}>
                            <AiOutlineHistory />
                            Histórico de execução
                        </ButtonLink>

                        <ButtonClose type="button" onClick={handleClose}>
                            <AiOutlineClose />
                        </ButtonClose>

                    </AppBarHeader>

                    {userPlan === "Free" || userPlan === "Trial" ? //Just for free plan
                        isOutAuto && isOutExec ? // Both out of limits
                            <BadgeUpgrade
                                title={"Você atingiu o limite de execuções mensais previstas no seu plano. Faça upgrade agora para continuar realizando as automações configuradas."}
                                description={"As suas automações não serão executadas até que seja feito o upgrade ou reestabelecido o limite de execuções mensais"}
                                buttonText={"Fazer Upgrade"}
                            /> :
                            isOutAuto ? // Out of automations
                                <BadgeUpgrade
                                    title={"Você atingiu o limite de automações previstas no seu plano. Faça o upgrade agora e siga automatizando seu processo!"}
                                    description={"Não será permitido criar novas automações até que seja feito o upgrade"}
                                    buttonText={"Fazer Upgrade"}
                                /> :
                                isOutExec ? // Out of executions
                                    <BadgeUpgrade
                                        title={"Você atingiu o limite de execuções mensais previstas no seu plano. Faça upgrade agora para continuar realizando as automações configuradas!"}
                                        description={"As suas automações não serão executadas até que seja feito o upgrade ou reestabelecido o limite de execuções mensais"}
                                        buttonText={"Fazer Upgrade"}
                                    /> :
                                    <></> :
                        <></>
                    }

                    {flow !== undefined ?
                        <RunHistoryDialog
                            open={openDialogRunHistory}
                            flow={flow}
                            days={getLimitsPlan(userPlan).daysLog}
                            onClose={() => setOpenDialogRunHistory(!openDialogRunHistory)}
                        />
                        :
                        <></>
                    }

                    <DividerMenu />

                    <Content>
                        <ContentBody container>
                            <Container>

                                <ContainerLeft
                                    item
                                    xs={12}
                                    sm={12}
                                    md={8}
                                    lg={8}
                                    style={{ marginBottom: '10px', maxHeight: '85vh', overflowY: 'auto', height: '100%' }}
                                >

                                    <ContainerNewAutomation>

                                        <BtnNewAutomation onClick={() => handleOpenNewAutomation()} disabled={isOutAuto}>
                                            <IoFlash />
                                            <span>Adicionar nova automação</span>
                                        </BtnNewAutomation>

                                    </ContainerNewAutomation>

                                    <DividerMenu />

                                    {!loading && automations.length === 0 ?
                                        <ContainerEmpty>
                                            <ContainerIlustration>
                                                <img src={emptyAutomations} alt="Sem Automações" />
                                            </ContainerIlustration>
                                            <ContainerInfo>
                                                <h3>Você ainda não possui automações neste fluxo</h3>
                                                <p>Crie novas automações para automatizar o seu trabalho e da sua equipe</p>
                                            </ContainerInfo>
                                            <ButtonFirstNewAutomation height="36px" onClick={() => handleOpenNewAutomation()}>
                                                Criar a primeira automação
                                            </ButtonFirstNewAutomation>
                                        </ContainerEmpty> :
                                        <></>
                                    }

                                    {loading ?
                                        <ContainerLeftBody>
                                            <ContainerLoader>
                                                <Animation className="lottieContainer" ref={lottieContainer} />
                                            </ContainerLoader >
                                        </ContainerLeftBody> : automations.length > 0 ?
                                            <ContainerAutomations>
                                                {Object.entries(groupAutomations).sort(
                                                    ([groupA], [groupB]) => {
                                                        if (groupA === "S") {
                                                            return -1;
                                                        }
                                                        if (groupB === "S") {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    }
                                                ).map(([group, automationItems], index) => {
                                                    return (
                                                        <AutomationsGroup key={group}>

                                                            {index > 0 ?
                                                                <DividerMenu /> :
                                                                <></>
                                                            }

                                                            <AutomationsGroupHeader>
                                                                <AutomationsGroupName>
                                                                    {group === "S" ? <BsToggleOn style={{ color: '#61bd4f' }} /> : <BsToggleOff style={{ color: 'gray' }} />}
                                                                    {group === "S" ? "Ativas" : "Inativas"}
                                                                </AutomationsGroupName>
                                                                <AutomationsGroupItems style={group === "S" ? { backgroundColor: '#61bd4f' } : { backgroundColor: 'gray' }}>
                                                                    {automationItems.length}
                                                                </AutomationsGroupItems>
                                                            </AutomationsGroupHeader>

                                                            <DividerMenu style={{ marginBottom: '10px' }} />

                                                            <AutomationsGroupBody>
                                                                {automationItems.sort((a, b) => {
                                                                    if (a.dt_last_update !== undefined && b.dt_last_update !== undefined) {
                                                                        return new Date(b.dt_last_update).getTime() - new Date(a.dt_last_update).getTime();
                                                                    }
                                                                    return 0;
                                                                }).map((automation, index) => (
                                                                    <AutomationsItem key={index} color={automation.trigger?.color} onClick={() => {
                                                                        setSelectAutomation(automation);
                                                                        setOpenAutomation(true);
                                                                    }}>
                                                                        <AutomationsItemLeft>
                                                                            <AutomationsItemIconTrigger color={automation.trigger?.color}>
                                                                                <IconPickerItem
                                                                                    icon={automation.trigger?.icon as IconList}
                                                                                    color={"white"}
                                                                                />
                                                                            </AutomationsItemIconTrigger>
                                                                            <AutomationsItemIconName>
                                                                                <h3>{automation.name}</h3>
                                                                                {automation.description !== undefined && automation.description !== null && automation.description !== '' ?
                                                                                    <span>{automation.description}</span> :
                                                                                    <span>Gatilho: {automation.trigger?.name}</span>
                                                                                }
                                                                            </AutomationsItemIconName>
                                                                        </AutomationsItemLeft>
                                                                        <AutomationsItemRight>
                                                                            <AutomationsItemIconDivider>
                                                                                <BsArrowRightSquare />
                                                                            </AutomationsItemIconDivider>
                                                                            <AutomationsItemGroupActions>
                                                                                {automation.actions !== undefined && automation.actions.map((action, index) => {
                                                                                    return (
                                                                                        index < 2 ?
                                                                                            <AutomationsItemIconAction style={{ marginRight: '-5px' }} key={index} color={action.action?.color}>
                                                                                                <IconPickerItem
                                                                                                    icon={action.action?.icon as IconList}
                                                                                                    color={"white"}
                                                                                                />
                                                                                            </AutomationsItemIconAction> :
                                                                                            index === 2 && automation.actions !== undefined ?
                                                                                                <AutomationsItemIconAction key={index} color={"gray"}>
                                                                                                    <span>+{automation.actions.length - 2}</span>
                                                                                                </AutomationsItemIconAction> :
                                                                                                <></>
                                                                                    )
                                                                                })}
                                                                            </AutomationsItemGroupActions>
                                                                        </AutomationsItemRight>
                                                                    </AutomationsItem>
                                                                ))}
                                                            </AutomationsGroupBody>
                                                        </AutomationsGroup>
                                                    )
                                                })}
                                            </ContainerAutomations> :
                                            <></>
                                    }

                                </ContainerLeft>

                                <ContainerRight
                                    item
                                    xs={1}
                                    sm={1}
                                    md={4}
                                    lg={4}
                                    style={{ marginBottom: '10px', maxHeight: '85vh', overflowY: 'auto' }}
                                >

                                    <ChartContainer>

                                        {statsData !== undefined && user !== undefined ?

                                            <ChartItem>
                                                <ChartItemTitle style={{ marginBottom: '15px' }}>
                                                    Insights de uso
                                                </ChartItemTitle>
                                                <ChartItemBody>

                                                    <StatUserItem
                                                        key={'total_executions'}
                                                        title={'Total de execuções'}
                                                        help_text={"Total de vezes que as automações foram executadas dentro do seu ambiente no mês atual"}
                                                        value={statsData !== undefined ? statsData.automations_log[0].total_executions : 0}
                                                        target={statsData !== undefined ? statsData.automations_log[0].target : 0}
                                                        bar_value={statsData !== undefined ? statsData.automations_log[0].bar_value : 0}
                                                        hideTarget={false}
                                                        fullWidth={true}
                                                    />

                                                </ChartItemBody>
                                                <ChartItemBody>

                                                    <StatUserItem
                                                        key={'total_automations'}
                                                        title={'Total de automações'}
                                                        help_text={"Total de automações que foram criadas dentro do seu ambiente dentro do Cange"}
                                                        value={statsData !== undefined ? statsData.automations[0].total_automations : 0}
                                                        target={statsData !== undefined ? statsData.automations[0].target : 0}
                                                        bar_value={statsData !== undefined ? statsData.automations[0].bar_value : 0}
                                                        hideTarget={getLimitsPlan(userPlan).isAutomationsIlimited}
                                                        fullWidth={true}
                                                    />

                                                </ChartItemBody>
                                            </ChartItem> :
                                            <></>
                                        }

                                        <ChartItem>
                                            <ChartItemTitle>
                                                Total de ações executadas (Por Dia)
                                            </ChartItemTitle>
                                            <ChartItemBody>

                                                <AutomationsByDayChart flow_id={flow.id_flow} />

                                            </ChartItemBody>
                                        </ChartItem>

                                    </ChartContainer>

                                </ContainerRight>

                            </Container>
                        </ContentBody>
                    </Content>
                </>}
        </Dialog >
    );

}

export default Automations;
import AppBarHeader, { NavigationBarItemType } from "../../../components/AppBarHeader";
import {
    ButtonNextStep,
    Container,
    ContainerFields,
    ContainerPreview,
    ContainerRightButton,
    Content,
    ContentList,
    FieldsContainer,
    FormField,
    FormPreview,
    StepContainer,
    StepItemNew,
    StepItemNewContainer,
    StepNewContainerBottom
} from "./style"
import { FiArrowLeft, FiArrowRight, FiTrash2 } from 'react-icons/fi';
import {
    FieldProps,
    Fields as FieldType
} from "../../../components/Forms/Fields/FieldBuilder";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from 'react-router-dom';

import { BiSave } from "react-icons/bi";
import { BsGearFill } from "react-icons/bs";
import Button from "../../../components/Button";
import DialogAction from "../../../dialogs/DialogAction";
import ContentLoader from "react-content-loader"
import ContentPreview from "../../../components/ContentPreview";
import Description from "../../../components/Description";
import Fields from "../../../components/Fields";
import { Flow } from "../../../interfaces/Flow";
import { FormHandles } from "@unform/core";
import { IoMdListBox } from "react-icons/io";
import LayoutContainer from '../../../components/LayoutContainer';
import ListContainer from '../../../components/ListContainer';
import { RiFlowChart } from "react-icons/ri";
import { Step } from "../../../interfaces/Step";
import StepItem from "../../../components/StepItem";
import Title from "../../../components/Title";
import api from '../../../services/api';
import getRandomColor from "../../../utils/getRandomColor";
import { useToast } from '../../../hooks/toast';
import { SlaConfiguratorProps } from "../../../components/Forms/Fields/Components/SlaConfigurator";

interface StepPropsOne {
    step_index: number;
    step_name: string;
}

interface StepPropsTwo {
    step_color?: string;
    step_icon?: string;
    step_isEndStep?: string;
    step_isAddable?: string;
    step_description?: string;
    step_user_owner?: number;
    step_due_time_config?: SlaConfiguratorProps;
    step_isRequiredTrack?: string;
}

interface RouteParams {
    hash: string;
    origin: string;
    id?: string;
}

const fieldsFlowFormOne: FieldProps[] = [
    {
        name: "step_name",
        type: FieldType.TEXT_SHORT_FIELD,
        required: true,
        index: 1,
        title: "Nome da Etapa",
        description: "Digite o nome da Etapa",
        placeholder: "Digite aqui...",
        help_text: "Neste campo é ideal que você escolha um nome claro para a sua etapa, pois este nome que será exibido dentro do sistema para identifica-la",
        max_length: 25
    }
]

const fieldsFlowFormTwo: FieldProps[] = [
    {
        name: "step_description",
        type: FieldType.TEXT_SHORT_FIELD,
        required: false,
        index: 0,
        title: "Descrição da Etapa",
        description: "Descreva aqui o que será executado nesta etapa"
    },
    {
        name: "step_isEndStep",
        type: FieldType.CHECK_BOX_ONE_FIELD,
        required: false,
        index: 1,
        title: "Opções avançadas",
        description: "Configure o comportamento desta etapa",
        placeholder: "Esta é uma etapa final?"
    },
    {
        name: "divisor1",
        type: FieldType.DIVIDER_FIELD,
        required: false,
        index: 2,
        title: "Time Tracking",
        description: "",
    },
    {
        name: "step_user_owner",
        type: FieldType.COMBO_BOX_USER_FIELD,
        required: false,
        index: 3,
        title: "Usuário Responsável",
        description: "Escolha o responsável para ser atribuído a todas as tarefas desta etapa",
        help_text: "Quando um cartão for movimentado para esta etapa o usuário escolhido neste campo será atribuído automáticamente como responsável da tarefa",
        variation: "2",
        //turn_on_field: true
    },
    {
        name: "step_due_time_config",
        type: FieldType.SLA_CONFIGURATOR_FIELD,
        required: false,
        index: 4,
        title: "Prazo de Conclusão (SLA)",
        description: "Escolha qual data você deseja adicionar dias para definir automaticamente o vencimento do cartão",
        placeholder: "5 Dias",
        help_text: "Quando um cartão for movimentado para esta etapa o prazo de conclusão configurado nestes campos será atribuído automáticamente como data de vencimento da tarefa",
        //turn_on_field: true
    },
    /*
    {
        name: "step_isAddable",
        type: FieldType.CHECK_BOX_ONE_FIELD,
        required: false,
        index: 2,
        title: "",
        description: "",
        placeholder: "É permitido inserir itens nesta etapa?"
    },
    */
    {
        name: "divisor2",
        type: FieldType.DIVIDER_FIELD,
        required: false,
        index: 5,
        title: "Time Tracking",
        description: "",
    },
    {
        name: "step_color",
        type: FieldType.COLOR_PICKER_FIELD,
        required: false,
        index: 6,
        title: "Cor da Etapa",
        description: "Escolha uma cor para a sua etapa",
    },
    {
        name: "step_icon",
        type: FieldType.ICON_PICKER_FIELD,
        required: false,
        index: 7,
        title: "Ícone da Etapa",
        description: "Escolha um ícone para a sua etapa"
    },
    {
        name: "divisor3",
        type: FieldType.DIVIDER_FIELD,
        required: false,
        index: 8,
        title: "Time Tracking",
        description: "",
    },
    {
        name: "step_isRequiredTrack",
        type: FieldType.CHECK_BOX_ONE_FIELD,
        required: false,
        index: 9,
        title: "Time Tracking",
        description: "Configure o rastreio de tempo desta etapa",
        placeholder: "Tornar a inserção de tempo obrigatória nesta etapa?"
    },
]

const LoaderField = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={70}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="70" />
    </ContentLoader>
)

const LoaderBtnField = () => (
    <ContentLoader
        speed={2}
        width={180}
        height={36}
        viewBox="0 0 180 36"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="180" height="36" />
    </ContentLoader>
)


const ThirStep: React.FC = () => {

    const formRefFlowOne = useRef<FormHandles>(null);
    const formRefFlowTwo = useRef<FormHandles>(null);
    const formRefPreview = useRef<FormHandles>(null);

    const { params } = useRouteMatch<RouteParams>();
    const history = useHistory();
    const { addToast } = useToast();

    const [statePage, setStatePage] = React.useState(0);

    /* Loading Variables */
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
    const [loadingItems] = useState<number[]>([1]);

    /* Field Array */
    const [fields, setFields] = React.useState([] as FieldProps[]);

    /* Dialog controller */
    const [open, setOpen] = React.useState(false);
    const [stateDialog, setStateDialog] = React.useState("INS");
    const [selectedValue, setSelectedValue] = React.useState({} as FieldProps);

    const [stepDefault] = React.useState<Step>({
        index: 0,
        name: "Etapa 1",
        color: getRandomColor(),
        icon: "FaBookmark",
        hash: params.hash,
        description: "",
        form: {
            name: "STP Etapa 1",
            fields: [] as FieldProps[]
        }
    });

    const [steps, setSteps] = useState([stepDefault] as Step[]);
    const [flow, setFlow] = useState<Flow>();
    const [stepSelected, setStepSelected] = useState(0);

    /* Dialog controller */
    const [stateDelStep, setStateDelStep] = React.useState(false);

    const dialogNewFieldOpen = useCallback(async () => {
        setSelectedValue({} as FieldProps);
        setOpen(true);
        setStateDialog("INS");
        setStatePage(Math.random());
    }, []);

    const dialogNewFieldClose = useCallback(async (value: FieldProps) => {
        setOpen(false);
        setSelectedValue(value);
    }, []);

    const dialogNewFieldSubmit = useCallback(async (data: FieldProps) => {

        if (stateDialog === "INS") {
            data.index = fields.length === 0 ? 0 : (fields.length - 1) + 1;

            if (fields.length > 0) {
                setFields((fields) => {
                    return [...fields, data]
                });
            } else {
                setFields([data]);
            }
        } else if (stateDialog === "EDI") {

            let newFields = fields;

            newFields[data.index] = data;

            setFields(newFields);
        }

        setOpen(false);
        setSelectedValue(data);
    }, [fields, stateDialog]);

    const handleEditField = useCallback(async (index: number) => {
        setSelectedValue(fields[index]);
        setOpen(true);
        setStateDialog("EDI");
    }, [fields]);

    const handleMoveField = useCallback(async (type: string, index: number) => {

        let newFields = fields;

        if (type === "up") { //Se clicou para cima 

            if (index > 0) {
                let upFil = fields[index];
                upFil.index = index - 1;

                let downFil = fields[index - 1];
                downFil.index = index;

                newFields[index] = upFil;
                newFields[index - 1] = downFil;
            }

        } else if (type === "down") { //Se clicou para baixo

            if (index < fields.length - 1) {
                let downFil = fields[index];
                downFil.index = index + 1;

                let upFil = fields[index + 1];
                upFil.index = index;

                newFields[index] = downFil;
                newFields[index + 1] = upFil;
            }

        }

        setFields(newFields);
        setStatePage(Math.random());

    }, [fields]);

    const dialogDelStepOpen = useCallback(async () => {
        setStateDelStep(true);
    }, []);

    const dialogDelStepClose = useCallback(async () => {
        setStateDelStep(false);
    }, []);

    const dialogDelStepSubmmit = useCallback(async () => {

        if (steps[stepSelected]?.id_step !== undefined) {

            const stepId = steps[stepSelected]?.id_step as number;
            const flowId = flow !== undefined && flow.id_flow !== undefined ? flow.id_flow : steps[stepSelected]?.id_step as number;

            if (stepId > 0 && flowId > 0) {

                await api
                    .delete('/flow-step?id_step=' + stepId + '&flow_id=' + flowId)
                    .then(response => {

                        //Deleta o step
                        let newSteps = steps.filter(step => step.index !== stepSelected);

                        //Caso não exista novos steps
                        if (newSteps.length === 0) {
                            newSteps = [stepDefault];
                        }

                        //Reordena os steps
                        newSteps.map((step, index) => {
                            return step.index = index;
                        });

                        setSteps(newSteps);
                        setStepSelected(0);

                        setStateDelStep(false);

                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao deletar a Etapa',
                            description: 'Ocorreu um erro ao deletar a Etapa!',
                        });
                    });

            } else {
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar a Etapa',
                    description: 'Erro nos parâmetros! Ocorreu um erro ao deletar a Etapa!',
                });
            }
        } else { //When not is in the bd
            //Deleta o step
            let newSteps = steps.filter(step => step.index !== stepSelected);

            //Caso não exista novos steps
            if (newSteps.length === 0) {
                newSteps = [stepDefault];
            }

            //Reordena os steps
            newSteps.map((step, index) => {
                return step.index = index;
            });

            setSteps(newSteps);
            setStepSelected(0);

            setStateDelStep(false);
        }

    }, [stepSelected, stepDefault, steps, flow, addToast]);

    const setFormStep = useCallback(async () => {

        //Seta os dados do novo step no form
        if (formRefFlowOne.current !== null &&
            formRefFlowTwo.current !== null &&
            steps[stepSelected] !== undefined) {

            const selectedStepOne: StepPropsOne = {
                step_index: steps[stepSelected].index === undefined ? 0 : steps[stepSelected].index,
                step_name: steps[stepSelected].name === undefined ? "Etapa 1" : steps[stepSelected].name
            }

            let newDueTimeConfig: SlaConfiguratorProps | undefined = undefined;
            if (steps[stepSelected].due_time !== undefined && steps[stepSelected].due_time !== null) {
                newDueTimeConfig = {
                    due_time_type: steps[stepSelected].due_time_type !== undefined && steps[stepSelected].due_time_type !== null ? Number(steps[stepSelected].due_time_type) : -1,
                    due_time: Number(steps[stepSelected].due_time),
                    due_time_bd: steps[stepSelected].due_time_bd
                }
            }

            const selectedStepTwo: StepPropsTwo = {
                step_color: steps[stepSelected].color,
                step_icon: steps[stepSelected].icon,
                step_isEndStep: steps[stepSelected].isEndStep,
                step_isAddable: steps[stepSelected].isAddable,
                step_description: steps[stepSelected].description,
                step_user_owner: steps[stepSelected].user_id_owner,
                step_due_time_config: newDueTimeConfig,
                step_isRequiredTrack: steps[stepSelected].isRequiredTrack
            }

            formRefFlowOne.current.setData(selectedStepOne);
            formRefFlowTwo.current.setData(selectedStepTwo);

            if (steps[stepSelected].form !== undefined) {
                setFields(steps[stepSelected].form.fields);
            } else {
                setFields([]);
            }
        }

    }, [stepSelected, steps]);

    const handleSubmit = useCallback(async (data: object[]) => {

    }, []);

    const saveStepHandle = useCallback(async () => {
        //Pega os dados do form atual
        if (formRefFlowOne.current !== null && formRefFlowTwo.current !== null) {
            const dataNormalizedOne = formRefFlowOne.current.getData() as unknown as StepPropsOne;
            const dataNormalizedTwo = formRefFlowTwo.current.getData() as unknown as StepPropsTwo;

            const userOwner = dataNormalizedTwo.step_user_owner !== undefined && dataNormalizedTwo.step_user_owner !== null && typeof dataNormalizedTwo.step_user_owner !== 'object' ? dataNormalizedTwo.step_user_owner : undefined;

            const saveStep: Step = {
                id_step: steps[stepSelected]?.id_step,
                index: stepSelected,
                name: dataNormalizedOne.step_name,
                color: dataNormalizedTwo.step_color,
                icon: dataNormalizedTwo.step_icon,
                hash: params.hash,
                form: {
                    id_form: steps[stepSelected]?.form.id_form, //Aqui altera o id_form
                    name: "STP " + dataNormalizedOne.step_name,
                    fields: fields
                },
                isEndStep: dataNormalizedTwo.step_isEndStep,
                isAddable: dataNormalizedTwo.step_isAddable,
                isRequiredTrack: dataNormalizedTwo.step_isRequiredTrack,
                description: dataNormalizedTwo.step_description,
                user_id_owner: userOwner,
                due_time: dataNormalizedTwo.step_due_time_config?.due_time,
                due_time_type: dataNormalizedTwo.step_due_time_config?.due_time_type,
                due_time_bd: dataNormalizedTwo.step_due_time_config?.due_time_bd
            }

            const newSteps = steps;
            newSteps[stepSelected] = saveStep;
            setSteps(newSteps);
        }
    }, [fields, stepSelected, steps, params.hash]);

    const selectStepHandle = useCallback(async (stepId: number) => {

        //Save the step
        saveStepHandle();

        setStepSelected(stepId);
        setFormStep();

    }, [setFormStep, saveStepHandle]);

    const newStepHandle = useCallback(async () => {

        //Save actual step
        saveStepHandle();

        const newIndex = steps.length;

        const newStep: Step = {
            index: steps.length,
            name: "Etapa " + (steps.length + 1),
            color: getRandomColor(),
            icon: "FaBookmark",
            hash: params.hash,
            description: "",
            form: {
                name: "STP " + (steps.length + 1),
                fields: [] as FieldProps[]
            }
        }

        if (steps.length > 0) {
            setSteps((steps) => {
                return [...steps, newStep]
            });
        } else {
            setSteps([newStep]);
        }

        //Go to the new step
        setStepSelected(newIndex);
        setFormStep();

    }, [steps, saveStepHandle, params.hash, setFormStep]);

    const saveStepsPage = useCallback(async (urlToGo: string) => {

        //Salva nos objetos o step
        saveStepHandle();

        if (steps.length > 0) {

            if (flow !== null && flow !== undefined) {
                let newFlow: Flow = flow;
                newFlow.flow_steps = steps;

                setLoadingInsert(true);
                await api
                    .post('/flow', newFlow)
                    .then(response => {
                        if (response.data !== null) {

                            setFlow(response.data);
                            setSteps(response.data.flow_steps);
                            setFields(steps[0].form.fields);

                            //Seta os dados do novo step no form
                            if (formRefFlowOne.current !== null &&
                                formRefFlowTwo.current !== null) {

                                const selectedStepOne: StepPropsOne = {
                                    step_index: response.data.flow_steps[0].index,
                                    step_name: response.data.flow_steps[0].name
                                }

                                let newDueTimeConfig: SlaConfiguratorProps | undefined = undefined;
                                if (response.data.flow_steps[0].due_time !== undefined && response.data.flow_steps[0].due_time !== null) {
                                    newDueTimeConfig = {
                                        due_time_type: response.data.flow_steps[0].due_time_type !== undefined && response.data.flow_steps[0].due_time_type !== null ? response.data.flow_steps[0].due_time_type : -1,
                                        due_time: response.data.flow_steps[0].due_time,
                                        due_time_bd: response.data.flow_steps[0].due_time_bd,
                                    }
                                }

                                const selectedStepTwo: StepPropsTwo = {
                                    step_color: response.data.flow_steps[0].color,
                                    step_icon: response.data.flow_steps[0].icon,
                                    step_isEndStep: response.data.flow_steps[0].isEndStep,
                                    step_isAddable: response.data.flow_steps[0].isAddable,
                                    step_isRequiredTrack: response.data.flow_steps[0].isRequiredTrack,
                                    step_description: response.data.flow_steps[0].description,
                                    step_user_owner: response.data.flow_steps[0].user_id_owner,
                                    step_due_time_config: newDueTimeConfig
                                }

                                formRefFlowOne.current.setData(selectedStepOne);
                                formRefFlowTwo.current.setData(selectedStepTwo);

                            }

                            history.push(urlToGo);

                        }
                        setLoadingInsert(false);
                    }).catch(error => {
                        console.log(error);
                        setLoadingInsert(false);
                        addToast({
                            type: 'error',
                            title: 'Erro ao salvar o fluxo',
                            description: 'Ocorreu um erro ao salvar o fluxo!',
                        });
                    });
            }

        } else {
            addToast({
                type: 'error',
                title: 'Sem etapas adicionados!',
                description: 'Para prosseguir, você deve adicionar ao menos uma etapa no Fluxo',
            });
        }

    }, [steps, addToast, saveStepHandle, flow, history]);

    const handleBackPage = useCallback(async () => {

        if (params.origin === "new") {

            setLoadingInsert(true);

            await saveStepsPage('/flow/2/' + params.hash + "/new");

            setLoadingInsert(false)

        } else {

            history.push('/flow/' + params.hash);

        }

    }, [history, params.origin, params.hash, saveStepsPage]);

    const handleSubmitPage = useCallback(async () => {

        saveStepsPage('/flow/' + params.hash);

    }, [saveStepsPage, params.hash]);

    const moveStep = useCallback((dragIndex: number, hoverIndex: number) => {

        setSteps(
            steps.map((step) => {
                if (step.index === dragIndex) { //DragIndex (Qual vai)

                    step.index = hoverIndex;

                } else if (step.index === hoverIndex) { //HoverIndex (Pra onde vai)

                    step.index = dragIndex;

                }
                return step;
            })
        );

        setStepSelected(0);
        setFormStep();

    }, [steps, setFormStep])

    const dialogNewFieldDelete = useCallback(async (name: string, id_field?: number) => {
        setOpen(false);

        if (id_field !== undefined && id_field > 0) { //Já inserido no BD

            await api
                .delete('/field?id_field=' + id_field)
                .then(response => {

                    let fieldsNew = fields.filter((field) => field.name !== name);

                    fieldsNew = fieldsNew.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

                    for (let index = 0; index < fieldsNew.length; index++) {

                        fieldsNew[index].index = index;

                    }

                    setFields(fieldsNew);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao excluir o campo',
                        description: 'Ocorreu um erro ao excluir o campo!',
                    });
                });


        } else { //Somente em memória

            let fieldsNew = fields.filter((field) => field.name !== name);

            fieldsNew = fieldsNew.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

            for (let index = 0; index < fieldsNew.length; index++) {

                fieldsNew[index].index = index;

            }

            setFields(fieldsNew);

        }


    }, [fields, addToast]);

    const goToGeralTab = useCallback(async () => {

        if (params.origin === "new") {
            saveStepsPage("/flow/1/" + params.hash + "/new");
        } else {
            saveStepsPage("/flow/1/" + params.hash + "/edit");
        }

    }, [params.origin, params.hash, saveStepsPage]);

    const goToFormInitTab = useCallback(async () => {

        if (params.origin === "new") {
            saveStepsPage("/flow/2/" + params.hash + "/new");
        } else {
            saveStepsPage("/flow/2/" + params.hash + "/edit");
        }

    }, [params.origin, params.hash, saveStepsPage]);


    const navBarItems: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Geral",
            icon: BsGearFill,
            onClick: () => goToGeralTab()
        },
        {
            index: 2,
            description: "Formulário Inicial",
            icon: IoMdListBox,
            onClick: () => goToFormInitTab()
        },
        {
            index: 3,
            description: "Etapas",
            icon: RiFlowChart,
            active: true,
            onClick: () => { }
        }
    ]

    useEffect(() => {

        setFormStep();

    }, [setFormStep]);

    useEffect(() => {

        setLoading(true);

        api.get(`/flow`, {
            params: {
                hash: params.hash,
                withSteps: true
            }
        }).then(response => {
            if (response.data !== null) {

                const respFlow: Flow = response.data;

                if (respFlow !== undefined) {

                    setFlow(respFlow);

                    if (respFlow.flow_steps !== undefined && respFlow.flow_steps.length > 0) {
                        let flowStepFieldsInit = response.data.flow_steps[0].form.fields;
                        let flowStepInit: Step = response.data.flow_steps[0];
                        let stepSelectedInit: number = 0;

                        setSteps(response.data.flow_steps);

                        //If the id come together
                        if (params.id !== undefined) {
                            const stepFiltred = response.data.flow_steps.filter((step: Step) => step.id_step + "" === params.id);
                            if (stepFiltred !== undefined) {
                                if (stepFiltred[0].id_step !== undefined) {
                                    flowStepFieldsInit = stepFiltred[0].form.fields;
                                    flowStepInit = stepFiltred[0];
                                    stepSelectedInit = Number(stepFiltred[0].index);
                                }
                            }
                        }

                        setFields(flowStepFieldsInit);
                        setStepSelected(stepSelectedInit);

                        //Seta os dados do novo step no form
                        if (formRefFlowOne.current !== null &&
                            formRefFlowTwo.current !== null) {

                            const selectedStepOne: StepPropsOne = {
                                step_index: flowStepInit.index,
                                step_name: flowStepInit.name
                            }

                            let newDueTimeConfig: SlaConfiguratorProps | undefined = undefined;
                            if (flowStepInit.due_time !== undefined && flowStepInit.due_time !== null) {
                                newDueTimeConfig = {
                                    due_time_type: flowStepInit.due_time_type !== undefined && flowStepInit.due_time_type !== null ? flowStepInit.due_time_type : -1,
                                    due_time: flowStepInit.due_time,
                                    due_time_bd: flowStepInit.due_time_bd
                                }
                            }

                            const selectedStepTwo: StepPropsTwo = {
                                step_color: flowStepInit.color,
                                step_icon: flowStepInit.icon,
                                step_isEndStep: flowStepInit.isEndStep,
                                step_isAddable: flowStepInit.isAddable,
                                step_isRequiredTrack: flowStepInit.isRequiredTrack,
                                step_description: flowStepInit.description,
                                step_user_owner: flowStepInit.user_id_owner,
                                step_due_time_config: newDueTimeConfig
                            }

                            formRefFlowOne.current.setData(selectedStepOne);
                            formRefFlowTwo.current.setData(selectedStepTwo);
                        }

                    }

                    setLoading(false);

                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }

        }).catch(error => {
            setLoading(false);
            console.log(error);
        });

    }, [addToast, params.hash, params.id]);

    useEffect(() => {
        //Valid if close the window
        window.onbeforeunload = confirmExit;
        function confirmExit() {
            return "show warning";
        }
    }, [])

    return (
        <LayoutContainer>
            <title>{flow !== undefined ? flow.name : "Novo Fluxo"} | Cange</title>
            <ListContainer statePage={statePage}>

                {/* Header Page */}
                <AppBarHeader
                    icon={params.origin === "new" ? RiFlowChart : RiFlowChart}
                    iconDynamic={flow !== undefined ? flow.icon as IconList : undefined}
                    iconDynamicColor={flow !== undefined ? flow.color : undefined}
                    title={params.origin === "new" ? "Novo Fluxo" : flow !== undefined ? flow.name : "Etapas do Fluxo"}
                    navBarItems={navBarItems}
                >

                    <ButtonNextStep
                        color="gray"
                        height="36px"
                        margin="0px 30px 0px 0px"
                        icon={FiArrowLeft}
                        icon_side="left"
                        onClick={handleBackPage}
                        isLoading={loadingInsert}
                    >
                        Voltar
                    </ButtonNextStep>

                    {params.origin === "new" ?
                        <ButtonNextStep
                            color="#23cd78"
                            height="36px"
                            margin="0px 30px 0px 0px"
                            icon={FiArrowRight}
                            icon_side="right"
                            onClick={handleSubmitPage}
                            isLoading={loadingInsert}
                        >
                            Avançar
                        </ButtonNextStep> :
                        <ButtonNextStep
                            type="submit"
                            color="#23cd78"
                            height="36px"
                            margin="0px 30px 0px 0px"
                            icon={BiSave}
                            onClick={handleSubmitPage}
                            isLoading={loadingInsert}
                        >
                            Salvar
                        </ButtonNextStep>
                    }

                </AppBarHeader>

                {/* Steps */}
                <StepContainer>

                    {/* New Step Item */}
                    <StepItemNew onClick={() => newStepHandle()}>
                        <StepItemNewContainer>
                            <IconPickerItem
                                icon={'FaPlus'}
                                color={"white"}
                            />
                        </StepItemNewContainer>
                        <StepNewContainerBottom>
                            <h2>
                                Nova Etapa
                            </h2>
                        </StepNewContainerBottom>
                    </StepItemNew>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {steps.map((step) => {
                            return (
                                <StepItem
                                    selectedIndex={stepSelected}
                                    index={step.index}
                                    selectStepHandle={() => selectStepHandle(step.index)}
                                    key={step.index}
                                    step={step}
                                    moveStep={moveStep}
                                />
                            );
                        })}
                    </div>

                </StepContainer>

                {/* Inputs Form */}
                <Container>
                    <Content container>

                        {/* Forms */}
                        <ContainerFields item xs={12} md={6}>

                            <ContentList>

                                <FormField
                                    id="formThirStepOne"
                                    formRef={formRefFlowOne}
                                    fields={fieldsFlowFormOne}
                                    handleSubmit={handleSubmit}
                                    hideContainer={true}
                                />

                                {/* Fields */}
                                <FieldsContainer>
                                    <Title
                                        title="Campos da Etapa"
                                        required={false}
                                        help_text="Estes campos ficarão disponíveis para preenchimento no momento em que esta etapa estará sendo executada"
                                    />
                                    <Description>Crie campos que deverão ser preenchidos quando alguém for criar esta etapa</Description>

                                    {loading ?
                                        <>
                                            {loadingItems.map((item) => {
                                                return (
                                                    <div key={item} style={{ marginBottom: '15px' }}>
                                                        <LoaderField />
                                                    </div>
                                                )
                                            })}
                                            <LoaderBtnField />
                                        </> :
                                        <Fields
                                            fields={fields}
                                            origin="flow"
                                            flow={flow}
                                            open={open}
                                            selectedValue={selectedValue}
                                            stateDialog={stateDialog}
                                            handleMoveField={handleMoveField}
                                            handleEditField={handleEditField}
                                            dialogNewFieldSubmit={dialogNewFieldSubmit}
                                            dialogNewFieldOpen={dialogNewFieldOpen}
                                            dialogNewFieldClose={dialogNewFieldClose}
                                            dialogNewFieldDelete={dialogNewFieldDelete}
                                        />
                                    }

                                </FieldsContainer>

                                <FormField
                                    id="formThirStepTwo"
                                    formRef={formRefFlowTwo}
                                    fields={fieldsFlowFormTwo}
                                    handleSubmit={handleSubmit}
                                    hideContainer={true}
                                    flow_id={flow?.id_flow}
                                />

                                <ContainerRightButton>
                                    <Button
                                        color="#e24d4b"
                                        height="36px"
                                        margin="0px 5px 0px 0px"
                                        icon={FiTrash2}
                                        icon_side="left"
                                        onClick={dialogDelStepOpen}
                                    >
                                        Excluir Etapa
                                    </Button>
                                    <DialogAction
                                        open={stateDelStep}
                                        message={"Você tem certeza que deseja excluir esta etapa?"}
                                        actions_items={[
                                            "Exclusão de todos os cartões que estão nesta etapa;",
                                            "Exclusão de todas as informações que foram inseridas na etapa;",
                                            "Exclusão de todas as métricas de tempo da etapa;",
                                            "Exclusão de todas as informações sobre a etapa"
                                        ]}
                                        disclaimer={"Lembrando que após a confirmação não será mais possível recuperar os dados referente a etapa e seus cartões!"}
                                        onSubmmit={dialogDelStepSubmmit}
                                        onClose={dialogDelStepClose}
                                        type={1}
                                    />
                                </ContainerRightButton>

                            </ContentList>

                        </ContainerFields>

                        {/* Pré-Visualização/Design/Simulação */}
                        <ContainerPreview item xs={12} md={6}>
                            <ContentPreview>
                                <FormPreview
                                    id="previewAllForm"
                                    formRef={formRefPreview}
                                    fields={fields}
                                    handleSubmit={handleSubmit}
                                    flow_id={flow?.id_flow}
                                    hideAutoComplete={false}
                                    typeUser={'A'}
                                />
                            </ContentPreview>
                        </ContainerPreview>

                    </Content>
                </Container>

            </ListContainer >

        </LayoutContainer >
    );
}

export default ThirStep;
import styled, { css } from "styled-components";

import Button from '../../components/Button';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

interface HelpContainerProps {
    color?: string;
}

export const DialogContainer = styled(Dialog)`

    .MuiDialog-paperWidthSm {
        max-width: 700px;
    }
    
`;

export const DialogFooter = styled.div`
  border-top: 1px solid #eef2f8;
  padding: 15px !important;
  width: 100%;
`;

export const DialogFooterCenter = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
`;

export const BtnInsertNewAnswer = styled(Button)`
    height: 50px;
    width: 95%;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;
`;

export const Content = styled(DialogContent)`
    background-color: #f4f5f7;   

    @media(max-width: 800px){
        background-color: white;
    }
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const DialogTop = styled.div`
    position: relative;
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 10px;

    h1 {
        margin-left: 5px;
        font-size: 18px;
        font-weight: 500;
        color: black;
    }

    @media(max-width: 800px){
        width: 80%;
    }
`;

export const DialogTopCenter = styled.div`
    position: absolute;
    right: 50px;
    top: 9px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    @media(max-width: 800px){
        display: none !important;
    }
`;

export const DialogTopRight = styled.div`
   position: absolute;
   top: 10px;
   right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 13px;
    right: 15px;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media(max-width: 800px){
        position: absolute;
        right: 10px;
        top: 30px;
    }
`;

export const IconHeader = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;
        }

    }
`;

export const BtnEditForm = styled.button`

    display: flex;
    margin-left: 0px !important;
    background-color: #f23b5c1a;
    border-radius: 10px;
    padding: 5px;
    justify-content: center;
    align-content: center;
    border: none;
    justify-items: center;
    align-items: center;

    svg {
      font-size: 11px;
      margin-right: 10px;
      margin-left: 5px;
    }

    h3 {
      font-size: 12px;
      font-weight: 500;
      margin-right: 5px;
    }

`;

export const HelpContainer = styled.div<HelpContainerProps>`
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #4680B81e;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props => props.color && css`
        background-color: ${props.color}; 
    `}
`;

export const HelpItems = styled.div`
    padding: 15px;
    padding-bottom: 5px;
    width: 100%;
    height: 100%;
`;
export const HelpItem = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: stretch;
`;
export const HelpIcon = styled.div<HelpContainerProps>`
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    svg {
        font-size: 25px;
        color: #4680B8;

        ${props => props.color && css`
            color: ${props.color}; 
        `}
    }
`;
export const HelpText = styled.div`
    width: 85%;
    font-size: 13px;
`;

import React, { useCallback, useEffect, useState } from "react";

import {
    AddNewFilter,
    BoxContainer,
    ButtonEmptyState,
    CleanFilter,
    Container,
    DeleteButton,
    DescriptionInput,
    DialogFooter,
    DialogFooterLeft,
    DialogFooterRight,
    DynamicFieldContainer,
    DynamicFieldForm,
    FilterBottom,
    FilterContainer,
    LabelInput,
    OptionItem,
    SelectComparator,
    SelectInput,
    SelectInputAction
} from "./style";
import { FieldOptionsProps, FieldProps, Fields, getComponentComparationType, getFieldObject } from "../../../components/Forms/Fields/FieldBuilder";
import FilterInput from "../../../components/FilterBar/FilterComponents/FilterInput";
import FilterDate from "../../../components/FilterBar/FilterComponents/FilterDate";
import FilterSelect from "../../../components/FilterBar/FilterComponents/FilterSelect";
import { Flow } from "../../../interfaces/Flow";
import { FaPlus, FaTrash } from "react-icons/fa";
import { HelpContainer, HelpIcon, HelpItem, HelpItems, HelpText } from "../style";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { Conditional } from "../../../interfaces/Conditional";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { Step } from "../../../interfaces/Step";

interface AddConditionalStepProps {
    flow?: Flow;
    flowStep: Step;
    flowStepParent: Step;
    selectedConditional?: Conditional;
    handleBackToConditionals: () => void;
}

export interface FilterConditionItem {
    index: number;
    fields: FieldProps[];
    comparators: string[];
    selectedField: FieldProps;
    selectedComparator: string;
    value: string;
    valueOptions?: string[];
    options?: FieldOptionsProps[];
    nameComponent?: string;
}

const AddConditionalStep: React.FC<AddConditionalStepProps> = ({ flow, flowStep, flowStepParent, handleBackToConditionals, selectedConditional }) => {

    const { addToast } = useToast();

    const [fields, setFields] = useState<FieldProps[]>([]);
    const [conditionItems, setConditionItems] = useState<FilterConditionItem[]>([]);
    const [conditional, setConditional] = useState<Conditional>({ type: 'step', key: flowStep.id_step, action: '1' });
    const [loading, setLoading] = useState<boolean>(false);

    /* Relationship */
    const ComponentField = {
        TEXT_FILTER: "TEXT_FILTER",
        DATE_FILTER: "DATE_FILTER",
        OPTION_FILTER: "OPTION_FILTER",
    };

    const getFilterComponent = (type: string) => {
        const relationFields = {
            [ComponentField.TEXT_FILTER]: FilterInput,
            [ComponentField.DATE_FILTER]: FilterDate,
            [ComponentField.OPTION_FILTER]: FilterSelect,
        };

        return relationFields[type];
    };

    const getFilterNameComponent = (type: string) => {
        const relationFields = {
            [Fields.TEXT_SHORT_FIELD]: "TEXT_FILTER",
            [Fields.TEXT_LONG_FIELD]: "TEXT_FILTER",
            [Fields.MAIL_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.RADIO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_FIELD]: "OPTION_FILTER",
            [Fields.DATE_PICKER_FIELD]: "DATE_FILTER",
            [Fields.SWITCH_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_LIST_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_ONE_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_USER_FIELD]: "OPTION_FILTER",
            [Fields.DUE_DATE_FIELD]: "DATE_FILTER",
            [Fields.CURRENCY_FIELD]: "TEXT_FILTER",
            [Fields.NUMBER_FIELD]: "TEXT_FILTER",
            [Fields.PHONE_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_REGISTER_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_FLOW_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_RICH_TEXT_FIELD]: "TEXT_FILTER",
            [Fields.ID_FIELD]: "TEXT_FILTER",
            [Fields.LINK_FIELD]: "TEXT_FILTER",
        };

        return relationFields[type];
    }

    const onSelectField = (field_id: string, index: number) => {

        const fieldSelected = fields.filter((field) => String(field.id_field) === field_id);

        if (fieldSelected !== undefined && fieldSelected.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.comparators = getComponentComparationType(fieldSelected[0].type);
                    condition.selectedComparator = getComponentComparationType(fieldSelected[0].type)[0];
                    condition.selectedField = fieldSelected[0];
                    condition.nameComponent = getFilterNameComponent(fieldSelected[0].type)
                    condition.valueOptions = [];
                    condition.value = "";
                }

                return condition;
            }))

        }

    };


    const onSelectComparator = (comparatorName: string, index: number) => {

        setConditionItems(conditionItems.map((condition, idx) => {
            if (idx === index) {
                condition.selectedComparator = comparatorName;

                //Clean the value when is blank
                if (comparatorName === "Está em branco" || comparatorName === "Não está em branco") {
                    condition.value = "";
                }
            }

            return condition;
        }))

    };

    const handleDeleteAllConditionFilter = useCallback(() => {

        let newConditions = [];

        const newConditionItem: FilterConditionItem = {
            index: 0,
            fields: fields,
            comparators: getComponentComparationType(fields[0].type),
            selectedField: fields[0],
            selectedComparator: getComponentComparationType(fields[0].type)[0],
            value: "",
            valueOptions: undefined,
            options: fields[0].options,
            nameComponent: getFilterNameComponent(fields[0].type)
        }

        newConditions.push(newConditionItem);

        setConditionItems(newConditions);

    }, [fields, setConditionItems]);

    const handleDeleteCondition = useCallback((index: number) => {

        let newConditions = conditionItems.filter((condition) => condition.index !== index);

        //Rebuild the indexes
        newConditions.map((condition, idx) => {
            condition.index = idx;
            return condition;
        })

        if (newConditions.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: fields,
                comparators: getComponentComparationType(fields[0].type),
                selectedField: fields[0],
                selectedComparator: getComponentComparationType(fields[0].type)[0],
                value: "",
                valueOptions: undefined,
                options: fields[0].options,
                nameComponent: getFilterNameComponent(fields[0].type)
            }

            newConditions.push(newConditionItem);

        }

        setConditionItems(newConditions);

    }, [conditionItems, fields, setConditionItems]);

    const onEventFieldComponent = useCallback((index: number, value?: string, conditions?: string[], version?: number) => {

        if (value !== undefined) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.value = value;
                }

                return condition;
            }))

        } else if (conditions !== undefined && conditions.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.valueOptions = conditions
                }

                return condition;
            }))

        }

    }, [conditionItems, setConditionItems]);

    const addNewCondition = useCallback(() => {

        let newArr = conditionItems;

        const newConditionItem: FilterConditionItem = {
            index: newArr.length,
            fields: fields,
            comparators: getComponentComparationType(fields[0].type),
            selectedField: fields[0],
            selectedComparator: getComponentComparationType(fields[0].type)[0],
            value: "",
            options: fields[0].options,
            nameComponent: getFilterNameComponent(fields[0].type)
        }

        newArr.push(newConditionItem);

        setConditionItems(newArr.map((condition) => {
            return condition;
        }));

    }, [fields, conditionItems, setConditionItems]);

    const addDefaultItems = useCallback(() => {

        if (fields !== undefined && fields.length > 0 && conditionItems.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: fields,
                comparators: getComponentComparationType(fields[0].type),
                selectedField: fields[0],
                selectedComparator: getComponentComparationType(fields[0].type)[0],
                value: "",
                options: fields[0].options,
                nameComponent: getFilterNameComponent(fields[0].type)
            }

            setConditionItems([newConditionItem]);

        }

    }, [fields, conditionItems.length, setConditionItems]);

    const handleChangeAction = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {

        setConditional({
            ...conditional,
            action: e.currentTarget.value
        });

    }, [conditional, setConditional]);

    const handleDeleteConditional = useCallback(async () => {

        if (conditional !== undefined && conditional.id_conditional !== undefined) {

            setLoading(true);

            await api
                .delete(`/conditional`, {
                    params: {
                        id_conditional: conditional.id_conditional,
                        flow_id: conditional.flow_id
                    }
                })
                .then(response => {

                    addToast({
                        type: 'success',
                        title: 'Wow! A regra Condicional foi removida com sucesso!',
                        description: 'Agora você pode criar uma nova regra para deixar o formulário mais dinâmico!',
                    });

                    setLoading(false);
                    handleBackToConditionals();
                }).catch(error => {
                    const errMessage: string = error.response.data.message;
                    setLoading(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao remover a regra Condicional',
                        description: errMessage,
                    });
                });

        }

    }, [addToast, conditional, handleBackToConditionals]);

    const handleAddNewConditional = useCallback(async () => {

        //Update the object
        let newConditional = conditional;

        //Update the conditions        
        newConditional.flow_id = flow?.id_flow;
        newConditional.flow_step_id = flowStep.id_step;
        newConditional.flow_step_parent_id = flowStepParent.id_step;
        newConditional.items = conditionItems;

        //Run into new conditions items and clean the fields that are not necessary
        newConditional.items = newConditional.items.map((condition) => {
            condition.fields = [];
            condition.comparators = [];

            return condition;
        });

        let message = "Quando o campo ";

        //Create the name
        if (conditionItems.length !== undefined && conditionItems.length >= 1) {

            let ret = false;
            let fieldsOn: string[] = [];

            conditionItems.map((cond) => {
                if (cond.value !== "" || (cond.valueOptions !== undefined && cond.valueOptions.length >= 1) || cond.selectedComparator === "Está em branco" || cond.selectedComparator === "Não está em branco") {
                    ret = true;
                    fieldsOn.push(cond.selectedField.title);
                }

                return cond;
            })

            if (ret) {

                fieldsOn.map((fil, index) => {

                    const containsInMessage = message.includes(fil);

                    if (index === 0) {
                        message += "[" + fil + " for " + conditionItems[0].selectedComparator.toLowerCase() + "] ";
                    } else if (index === 1 && fieldsOn.length > 2) {
                        message += " + " + (fieldsOn.length - 1) + " campos";
                    } else if (index === 1 && fieldsOn.length > 1 && !containsInMessage) {
                        message += " e  [" + fil + " for " + conditionItems[1].selectedComparator.toLowerCase() + "] ";
                    }
                    return fil;
                });

                //Limit message to 150 characters
                if (message.length > 130) {
                    message = message.substring(0, 130) + "...";
                }
            }

        }

        if (conditionItems === undefined || conditionItems.length <= 0) {
            addToast({
                type: 'error',
                title: 'Erro ao salvar a regra Condicional',
                description: 'Você precisa adicionar pelo menos uma condição para salvar a regra condicional!',
            });
        } else if (conditional.action === undefined || conditional.action === "0") {
            addToast({
                type: 'error',
                title: 'Erro ao salvar a regra Condicional',
                description: 'Você precisa adicionar uma ação para salvar a regra condicional!',
            });
        } else if (flow !== undefined && flow.id_flow !== undefined && flowStep !== undefined && flowStepParent !== undefined) {

            //Save the conditional
            setLoading(true);

            await api
                .post('/conditional', {
                    id_conditional: newConditional.id_conditional,
                    flow_id: newConditional.flow_id,
                    type: newConditional.type,
                    action: newConditional.action,
                    key: newConditional.key,
                    flow_step_id: newConditional.flow_step_id,
                    flow_step_parent_id: newConditional.flow_step_parent_id,
                    conditions_items: JSON.stringify(newConditional.items),
                    name: message
                })
                .then(response => {

                    addToast({
                        type: 'success',
                        title: 'Wow! A regra Condicional foi salva com sucesso!',
                        description: 'Agora você pode usar esta regra para deixar o formulário mais dinâmico!',
                    });

                    setLoading(false);
                    handleBackToConditionals();
                }).catch(error => {
                    const errMessage: string = error.response.data.message;
                    setLoading(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar a regra Condicional',
                        description: errMessage,
                    });
                });

        }

    }, [conditional, conditionItems, addToast, flow, handleBackToConditionals, flowStep, flowStepParent]);

    useEffect(() => {

        let newField: FieldProps[] = [];

        //Get all fields 
        if (flow !== undefined) {

            //Prepare standard fields
            //Data de Vencimento
            newField.push({
                id_field: -1,
                name: "-1",
                type: "DATE_PICKER_FIELD",
                title: "Data de Vencimento",
                description: "Data de Vencimento",
                index: -1
            });

            //Data de Criação
            newField.push({
                id_field: -2,
                name: "-2",
                type: "DATE_PICKER_FIELD",
                title: "Data de Criação",
                description: "Data de Criação",
                index: -2
            });

            //Responsável
            newField.push({
                id_field: -3,
                name: "-3",
                type: "COMBO_BOX_USER_FIELD",
                title: "Responsável",
                description: "Responsável",
                index: -3,
                form_id: flow.form_init?.id_form
            });

            //Etapa
            newField.push({
                id_field: -4,
                name: "-4",
                type: "COMBO_BOX_FIELD",
                title: "Etapa",
                description: "Etapa",
                index: -4,
                options: flow.flow_steps ? flow.flow_steps.map((step, index) => {
                    return {
                        id_field_option: step.id_step,
                        field_id: -4,
                        value: String(step.id_step),
                        label: step.name,
                        order: String(index)
                    } as FieldOptionsProps
                }) : undefined
            });

            if (flow.form_init !== undefined && flow.form_init !== null) {
                if (flow.form_init.fields !== undefined && flow.form_init.fields !== null && flow.form_init.fields.length > 0) {
                    newField.push(...flow.form_init.fields);
                }
            }

            if (flow.flow_steps !== undefined) {
                for (let index = 0; index < flow.flow_steps.length; index++) {
                    const step = flow.flow_steps[index];

                    if (step.form !== undefined) {
                        if (step.form.fields !== undefined && step.form.fields.length > 0) {
                            newField.push(...step.form.fields);
                        }
                    }

                }
            }

            setFields(newField.filter((f) => f.index < 0 || getFieldObject(f.type).onFilter));

        }

    }, [flow]);

    useEffect(() => {

        //Valid if has any init
        addDefaultItems();

    }, [addDefaultItems, fields]);

    useEffect(() => {

        if (selectedConditional !== undefined) {

            setConditional(selectedConditional);

            if (selectedConditional.conditions_items !== undefined) {
                let newConditionalItems = JSON.parse(selectedConditional.conditions_items) as FilterConditionItem[];

                //Update the fields
                newConditionalItems.map((condition) => {
                    condition.fields = fields;
                    condition.comparators = getComponentComparationType(condition.selectedField.type);
                    return condition;
                })

                setConditionItems(newConditionalItems);
            }

        }

    }, [selectedConditional, fields]);

    return (
        <>
            <Container>

                <HelpContainer>
                    <HelpItems>
                        <HelpItem>
                            <HelpIcon>
                                <RiLightbulbFlashFill />
                            </HelpIcon>
                            <HelpText>
                                <b>{"Dica: "}</b>
                                {"Defina primeiramente um cenário que quando for verdadeiro " +
                                    "irá aplicar uma ação na etapa selecionada. Esta funcionalidade é ideal para quando " +
                                    "é necessário que o fluxo seja condicional e dinâmico para se adaptar a diferentes situações."}
                            </HelpText>
                        </HelpItem>
                    </HelpItems>
                </HelpContainer>

                <DynamicFieldContainer>

                    <DynamicFieldForm>
                        <LabelInput>
                            Quando este cenário for verdadeiro
                        </LabelInput>
                        <DescriptionInput>
                            Defina abaixo um cenário para ativar esta regra condicional
                        </DescriptionInput>

                        <BoxContainer>

                            {conditionItems.map((condition, index) => {

                                const nameComponent: string = getFilterNameComponent(condition.selectedField.type);

                                let FilterComponent = getFilterComponent(nameComponent);

                                return (

                                    <FilterContainer key={condition.index}>

                                        <SelectInput value={condition.selectedField.id_field} onChange={(e) => onSelectField(e.currentTarget.value, index)}>
                                            <optgroup label="Campos Padrões">
                                                <option key={-1} value={-1}>{"Data de Vencimento"}</option>
                                                <option key={-2} value={-2}>{"Data de Criação"}</option>
                                                <option key={-3} value={-3}>{"Responsável"}</option>
                                                <option key={-4} value={-4}>{"Etapa"}</option>
                                                <option key={-5} value={-5}>{"Etiqueta"}</option>
                                            </optgroup>
                                            {fields && fields.filter((f) => f.index >= 0).length > 0 ?
                                                <optgroup label="Campos Customizáveis">
                                                    {fields.filter((f) => f.index >= 0).map((field) => {
                                                        return (
                                                            <option key={field.id_field} value={field.id_field}>{field.title}</option>
                                                        )
                                                    })}
                                                </optgroup> :
                                                <></>}
                                        </SelectInput>

                                        <SelectComparator value={condition.selectedComparator} onChange={(e) => onSelectComparator(e.currentTarget.value, index)}>
                                            {condition.comparators.map((comparator) => {
                                                return (
                                                    <option key={comparator} value={comparator}>{comparator}</option>
                                                )
                                            })}
                                        </SelectComparator>

                                        <FilterComponent
                                            comparator={condition.selectedComparator}
                                            onEvent={(e, cond) => onEventFieldComponent(index, e?.currentTarget.value, cond)}
                                            value={condition.value}
                                            valueOptions={condition.valueOptions}
                                            field={condition.selectedField}
                                            options={condition.selectedField.options}
                                        />

                                        <DeleteButton onClick={() => handleDeleteCondition(index)}>
                                            <FaTrash />
                                        </DeleteButton>

                                    </FilterContainer>

                                )

                            })}

                            {(conditionItems === undefined || conditionItems.length <= 0) ? (
                                <div style={{ padding: '10px', fontSize: '14px' }}>Você não possui campos para filtrar ainda, adicione novos campos para usar esta funcionalidade ;)</div>
                            ) : (

                                <FilterBottom>

                                    <AddNewFilter onClick={() => addNewCondition()}>
                                        <FaPlus />
                                        Adicionar condição
                                    </AddNewFilter>

                                    <CleanFilter onClick={() => handleDeleteAllConditionFilter()}>
                                        <FaTrash />
                                        Limpar Tudo
                                    </CleanFilter>

                                </FilterBottom>

                            )}

                        </BoxContainer>

                    </DynamicFieldForm>


                    <DynamicFieldForm>
                        <LabelInput>
                            Ative esta ação
                        </LabelInput>
                        <DescriptionInput>
                            Esta ação será acionada quando todas as condições forem verdadeiras
                        </DescriptionInput>

                        <SelectInputAction value={conditional.action} onChange={(e) => handleChangeAction(e)}>
                            <OptionItem disabled key={0} value={0}>Selecione uma ação...</OptionItem>
                            <OptionItem key={1} value={1}>Permitir mover</OptionItem>
                            <OptionItem key={2} value={2}>Não permitir mover</OptionItem>
                        </SelectInputAction>

                    </DynamicFieldForm>

                </DynamicFieldContainer>

            </Container>

            <DialogFooter>
                <DialogFooterLeft>
                    {conditional !== undefined && conditional.id_conditional !== undefined && (
                        <ButtonEmptyState color="#4680b8" style={{ width: '140px' }} onClick={() => handleDeleteConditional()} isLoading={loading}>Remover regra</ButtonEmptyState>
                    )}
                </DialogFooterLeft>
                <DialogFooterRight>
                    <ButtonEmptyState onClick={() => handleAddNewConditional()} isLoading={loading}>Salvar</ButtonEmptyState>
                </DialogFooterRight>
            </DialogFooter >
        </>
    );

}

export default AddConditionalStep;
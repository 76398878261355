import Switch from "@material-ui/core/Switch";
import styled, { css } from "styled-components";

interface AutoCompleteContainerProps {
  isFilled?: boolean;
}

interface FormContainerProps {
  idField?: string;
  hideField?: boolean;
}

export const FormContainer = styled.div<FormContainerProps>`
  margin-bottom: 20px;

  :hover {
    #auto-complete${props => props.idField} {
      display: flex;
    }
  }

  ${props => props.hideField === true && css`
    display: none;
  `}
`;

export const SwitchBase = styled(Switch)`
  float: right;
  margin-top: -23px;
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const AutoCompleteContainer = styled.div<AutoCompleteContainerProps>`
  display: none;
  margin-left: 5px;
  cursor: pointer;

  ${props => !!props.isFilled && css`
        display: flex;
  `}

  :hover {
    color: #f23b5d;
  }
`;

export const AutoComplete = styled.div`

`;


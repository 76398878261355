import React from "react";

import { Container } from "./styles";

interface DescriptionFieldProps {
    title: string;
}

const DescriptionField: React.FC<DescriptionFieldProps> = ({ title }) => {

    return (
        <Container>
            <h1>{title}</h1>
        </Container>
    );
    
}

export default DescriptionField;
import styled, { css } from "styled-components";

import Button from "../../../components/Button";

interface FooterButtonProps {
    color?: string;
}

interface ContainerEmailViewProps {
    hasAttachment?: boolean;
}

interface BodyContainerProps {
    onEdit: boolean;
}

export const Container = styled.div`
    //height: 100%;
    height: calc(100vh - 140px);
`;

export const HeaderFrom = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    margin-right: 10px;
    border-bottom: 1px solid #f4f5f7;
    margin-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export const HeaderTo = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    margin-right: 10px;
    border-bottom: 1px solid #f4f5f7;
    margin-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export const HeaderSubject = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    margin-right: 10px;
    border-bottom: 1px solid #f4f5f7;
    margin-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export const HeaderInfo = styled.div`
    font-size: 14px;
    color: gray;
`;

export const HeaderButtonOpt = styled.button`
    border: none;
    background-color: transparent;
    margin-left: 5px;
`;

export const HeaderInput = styled.input`
    border: none;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;

    :focus + .from-help {
      display: block; 
    }

    :focus + .from-name-help {
      display: block; 
    }
`;

export const HeaderInputSubject = styled.input`
    border: none;
    width: 100%;
    font-size: 15px;
`;

export const HeaderSender = styled.div`
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #f4f5f7;
`;

export const HeaderSenderAvatar = styled.div``;

export const HeaderSenderDate = styled.div`    
    font-size: 13px;
    color: gray;
`;

export const HeaderSenderInformations = styled.div`
    margin-left: 15px;
    overflow-wrap: break-word;
    width: calc(100% - 130px);
`;

export const HeaderSenderName = styled.div`
    font-weight: 600;

    span {
        font-size: 13px;
        color: gray;
        margin-left: 5px;
        font-weight: 400;
    }
`;

export const EmailInfoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    font-size: 15px;

    :hover {
        color: #9e37ee;
    }
`;

export const EmailInfoContainerDialog = styled.div`
    position: absolute;
    top: 20px;
    background-color: white;
    padding: 10px;
    padding-bottom: 0px;
    border-radius: 10px;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
`;

export const EmailInfoContainerDialogItem = styled.div`
    color: black;
    margin-bottom: 5px;

    span {
        color: gray;
    }
`;

export const HeaderSenderTo = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    margin-top: 5px;

    span {
        font-size: 13px;
        color: gray;
        margin-right: 5px;
    }
`;

export const EmailIcon = styled.div`
    margin-right: 3px;
    margin-left: 3px;
    padding: 3px 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;

    :hover {
        background-color: #eef2f8;
    }
`;

export const HeaderSenderDetails = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 20px;
`;

export const HeaderIconHelp = styled.div`
    display: none;
    width: 16px;
    height: 16px;
`;

export const BodyContainer = styled.div<BodyContainerProps>`
    overflow-y: auto;
    word-wrap: break-word;
    height: calc(100% - 155px);    

    ${props => props.onEdit && css`
        height: calc(100% - 185px);
    `}
    
    //height: calc(100% - 220px);
    //margin-bottom: 50px;
`;

export const ContainerEmail = styled.div`

    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    color: black;

    .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 1px solid #f4f5f7;
        margin-bottom: 10px;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 100;
    }

    .ql-container.ql-snow {
        border: none;    
    }

    .ql-editor {
        padding-top: 0px;
    }

    .ql-editor {
        min-height: 250px;
    }
`;

export const ContainerEmailView = styled.div<ContainerEmailViewProps>`
    //height: 100%;
    //height: calc(100% - 120px);
    height: 100%;
    padding: 10px; 
    font-size: 13px;   

    ${props => props.hasAttachment && css`
        height: calc(100% - 95px);
    `}
`;

export const AttachmentContainer = styled.div`
    padding-top: 10px;
    border-top: 1px dashed #f4f5f7;
`;

export const AttachmentTitle = styled.div`
    font-size: 15px;
    margin-left: 5px;
    font-weight: 500;
`;

export const HeaderInfoContainer = styled.div``;

export const FooterContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    position: absolute;
    bottom: 0px;
    height: 50px;
    border-top: 1px solid #f4f5f7;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;

export const FooterButton = styled(Button) <FooterButtonProps>`
    background-color: white;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 5px 10px;
    color: gray;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-right: 10px;

    min-width: 100px;
    max-height: 30px;

    :hover {
        background-color: #f4f5f7;
    }

    div {
        height: 50px !important;
        width: 50px !important;
    }

    svg {        
        margin-right: 10px;
    }

    ${props => props.color && css`
        background-color: ${props.color};
        color: white;
        border: none;

        :hover {
            background-color: ${props.color};
            opacity: 0.9;
        }
    `}
`;

export const ContainerEmailBody = styled.div`
    height: 100%;
`;
import { Box, ClickAwayListener } from "@material-ui/core";
import { BoxContainer, BoxItem, BoxItemsContainer, BoxMultiple, BoxSpan, Input, NoResults, SelectInput, SelectInputValue, BoxSpanDynamic } from "./style";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import React, { useCallback, useEffect, useState } from "react";

import { FilterComponentProps } from "../../FilterDialog";
import { Flow } from "../../../../interfaces/Flow";
import { Register } from "../../../../interfaces/Register";
import { User } from "../../../../interfaces/User";
import api from '../../../../services/api';
import { FlowTag } from "../../../../interfaces/FlowTag";

interface OptionFilter {
    key: string;
    label: string;
    selected?: boolean;
}

const FilterSelect: React.FC<FilterComponentProps> = ({ comparator, options, field, valueOptions, onEvent }) => {

    const [open, setOpen] = useState(false);
    const [multipleSelection, setMultipleSelection] = useState(false);
    const [loading, setLoading] = useState(true);
    const [optionsList, setOptionsList] = useState<OptionFilter[]>();
    const [optionsFiltered, setOptionsFiltered] = useState<OptionFilter[]>();
    const [conditions, setConditions] = useState<string[]>(valueOptions === undefined ? [] : valueOptions);
    const [isBlank, setIsBlank] = useState<boolean>(false);

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);

        setOptionsFiltered(optionsFiltered?.map((opt) => {

            opt.selected = conditions.filter((cond) => cond === opt.key).length >= 1;

            return opt;
        }));
    };

    const handleSelect = useCallback((option: OptionFilter) => {

        const newCondition: string[] = [];

        if (multipleSelection) {

            //Update the Options, set selected or no
            setOptionsList(optionsList?.map((opt) => {
                if (opt.key === option.key) {
                    if (opt.selected === undefined) {
                        opt.selected = true;
                        newCondition.push(opt.key);
                    } else if (opt.selected === false) {
                        opt.selected = true;
                        newCondition.push(opt.key);
                    } else {
                        opt.selected = false;
                    }
                } else if (opt.selected) {
                    newCondition.push(opt.key);
                }
                return opt;
            }));

        } else {

            //Update the Options, set selected or no
            setOptionsList(optionsList?.map((opt) => {
                if (opt.key === option.key) {
                    opt.selected = true;
                    newCondition.push(opt.key);
                } else {
                    opt.selected = false;
                }
                return opt;
            }));

            setOpen(false);

        }

        setConditions(newCondition);
        onEvent(undefined, newCondition);

    }, [multipleSelection, optionsList, onEvent]);

    const handleFilter = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        if (optionsList !== undefined) {

            const searchText = e.currentTarget.value.toLowerCase();

            let optNew = optionsList;

            const optFiltered = optNew.filter((opt) => opt.label.toLowerCase().includes(searchText));

            setOptionsFiltered(optFiltered);

        }


    }, [optionsList]);

    useEffect(() => {

        const newValueOptions = valueOptions !== undefined ? valueOptions : [];

        if (field?.type === "COMBO_BOX_REGISTER_FIELD" && field.register_id !== undefined) {

            setLoading(true);
            api.get(`/register`, {
                params: {
                    id_register: field.register_id,
                    withAnswers: true
                }
            }).then(response => {
                if (response.data !== null) {
                    const registersApi: Register = response.data;

                    const newOptionsList: OptionFilter[] = [];

                    if (registersApi.form_answers !== undefined) {

                        registersApi.form_answers.map((opt) => {

                            if (opt.id_form_answer !== undefined) {

                                const newOpt: OptionFilter = {
                                    key: opt.id_form_answer + "",
                                    label: opt.title === undefined ? "Sem registro" : opt.title,
                                    selected: newValueOptions.filter((vOpt) => opt.id_form_answer + "" === vOpt).length >= 1
                                }

                                newOptionsList.push(newOpt);

                            }

                            return opt;
                        });

                        setOptionsList(newOptionsList);
                        setOptionsFiltered(newOptionsList);

                    }

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.log(error.response.data);
            });

        } else if (field?.type === "COMBO_BOX_FLOW_FIELD" && field.flow_id !== undefined) {

            setLoading(true);
            api.get(`/flow`, {
                params: {
                    id_flow: field.flow_id,
                    withSteps: true,
                    withCards: true
                }
            }).then(response => {
                if (response.data !== null) {
                    const flowApi: Flow = response.data;
                    const newOptionsList: OptionFilter[] = [];

                    if (flowApi.flow_steps !== undefined) {
                        for (let index = 0; index < flowApi.flow_steps.length; index++) {
                            const element = flowApi.flow_steps[index];

                            if (element !== undefined && element.cards !== undefined) {
                                for (let index = 0; index < element.cards.length; index++) {
                                    const card = element.cards[index];

                                    if (card !== undefined) {

                                        const newOpt: OptionFilter = {
                                            key: card.id_card + "",
                                            label: card.title === undefined ? "Sem registro" : card.title,
                                            selected: newValueOptions.filter((vOpt) => card.id_card + "" === vOpt).length >= 1
                                        }

                                        newOptionsList.push(newOpt);

                                    }

                                }
                            }
                        }

                        setOptionsList(newOptionsList);
                        setOptionsFiltered(newOptionsList);
                    }

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.log(error.response.data);
            });

        } else if (field?.type === "COMBO_BOX_USER_FIELD" && field.form_id !== undefined) {

            setLoading(true);
            api.get(`/user/by-flow`, {
                params: {
                    form_id: field.form_id
                }
            }).then(response => {
                if (response.data !== null) {
                    const users: User[] = response.data;

                    const newOptionsList: OptionFilter[] = [];

                    users.map((opt) => {

                        if (opt.id_user !== undefined) {

                            const newOpt: OptionFilter = {
                                key: opt.id_user + "",
                                label: opt.name,
                                selected: newValueOptions.filter((vOpt) => opt.id_user + "" === vOpt).length >= 1
                            }

                            newOptionsList.push(newOpt);

                        }

                        return opt;
                    });

                    //Add Logged User
                    const newOpt: OptionFilter = {
                        key: "-1", //Logged User
                        label: "Usuário logado",
                        selected: newValueOptions.filter((vOpt) => "-1" === vOpt).length >= 1
                    }

                    newOptionsList.push(newOpt);

                    setOptionsList(newOptionsList);
                    setOptionsFiltered(newOptionsList);

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.log(error.response.data);
            });

        } else if (field?.type === "COMBO_BOX_FIELD" && field.id_field === -5) { //Etiquetas

            setLoading(true);

            api.get(`/flow-tag/by-flow`, {
                params: {
                    flow_id: field.flow_id
                }
            }).then(response => {
                if (response.data !== null) {
                    const tags: FlowTag[] = response.data;

                    const newOptionsList: OptionFilter[] = [];

                    tags.map((opt) => {

                        if (opt.id_flow_tag !== undefined) {

                            const newOpt: OptionFilter = {
                                key: opt.id_flow_tag + "",
                                label: opt.description,
                                selected: newValueOptions.filter((vOpt) => opt.id_flow_tag + "" === vOpt).length >= 1
                            }

                            newOptionsList.push(newOpt);

                        }

                        return opt;
                    });

                    setOptionsList(newOptionsList);
                    setOptionsFiltered(newOptionsList);

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.log(error.response.data);
            });

        } else if (options !== undefined) {

            const newOptionsList: OptionFilter[] = [];

            options.map((opt) => {

                if (opt.order !== undefined) {

                    const newOpt: OptionFilter = {
                        key: opt.value + "",
                        label: opt.label,
                        selected: newValueOptions.filter((vOpt) => opt.value + "" === vOpt).length >= 1
                    }

                    newOptionsList.push(newOpt);

                }

                return opt;
            });

            setLoading(false);
            setOptionsList(newOptionsList);
            setOptionsFiltered(newOptionsList);

        }

        if (comparator === "Está em branco" || comparator === "Não está em branco") {
            setIsBlank(true);
        } else {
            setIsBlank(false);
        }

        if (comparator === "É uma das" || comparator === "Não é uma das") {
            setMultipleSelection(true);
        } else {
            setMultipleSelection(false);
        }

    }, [comparator, options, field, valueOptions]);

    return (

        <ClickAwayListener onClickAway={handleClickAway} >
            <Box style={{ position: "relative" }}>

                {isBlank === true ?
                    <Input disabled={true} /> :
                    open || optionsList === undefined || optionsList?.filter((opt) => opt.selected === true).length <= 0 ?
                        <Input
                            placeholder="Clique aqui..."
                            onClick={handleClick}
                            onChange={handleFilter}
                        /> :
                        <SelectInput onClick={handleClick}>
                            <SelectInputValue>
                                {optionsList?.filter((opt) => opt.selected === true).map((opt, idx) => {

                                    const lenPlusSelect = optionsList?.filter((opt) => opt.selected === true).length - 1;

                                    if (idx === 0) {
                                        return (
                                            <BoxSpan key={opt.key}>{opt.label}</BoxSpan>
                                        )
                                    } else if (idx === 1) {
                                        if (lenPlusSelect === 1) {
                                            return (
                                                <BoxSpan key={opt.key}>+ {lenPlusSelect} Item</BoxSpan>
                                            )
                                        } else {
                                            return (
                                                <BoxSpan key={opt.key}>+ {lenPlusSelect} Itens</BoxSpan>
                                            )
                                        }
                                    }

                                    return <></>;
                                })}
                            </SelectInputValue>
                        </SelectInput>
                }

                {open ? (
                    <BoxContainer>
                        <BoxItemsContainer>
                            {!loading && optionsFiltered !== undefined && optionsFiltered?.length > 0 ?
                                optionsFiltered?.map((option) => {
                                    return (
                                        <BoxItem key={option.key} onClick={() => handleSelect(option)}>
                                            {multipleSelection ?
                                                <BoxMultiple>
                                                    {option.selected !== undefined && option.selected === true ?
                                                        <BsToggleOn color="green" /> :
                                                        <BsToggleOff />
                                                    }
                                                </BoxMultiple> : <></>
                                            }
                                            <BoxSpan color={option.key === "-1" ? "grey" : undefined}>{option.label}</BoxSpan>
                                            {option.key === "-1" && (
                                                <BoxSpanDynamic title="Este valor vai ser dinâmico de acordo com o usuário que está acessando o sistema">Valor dinâmico</BoxSpanDynamic>
                                            )}
                                        </BoxItem>
                                    )
                                }) : loading ?
                                    <NoResults>Carregando...</NoResults> :
                                    <NoResults>Sem registro</NoResults>
                            }
                        </BoxItemsContainer>
                    </BoxContainer>
                ) : null}
            </Box>
        </ClickAwayListener>

    );

}

export default FilterSelect;
import styled, { css } from "styled-components";

import Grid from '@material-ui/core/Grid';

interface GroupItemsProps {
    color: string;
}

interface ItemAgendaContainerProps {
    color: string;
}

interface ItemAgendaHeaderLeftProps {
    color: string;
}

interface ButtonGroupProps {
    active: boolean;
}

interface ButtonFilterProps {
    active: boolean;
}

export const Container = styled.div`

`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: left;
    
    width: 100%;
    height: 100%;

    @media(max-width: 800px){
        .schedule-tasks {
            display: none !important;
        }
    }
`;

export const ContainerLeft = styled(Grid)`
`;

export const ContainerRight = styled(Grid)`
`;

export const WidgetContainer = styled.div`
    
`;

export const ContentContainer = styled.div`
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 20px;
`;

export const MyWorkContainer = styled.div`
    padding: 0px;
    background-color: transparent;
    border-radius: 10px;
    margin-bottom: 20px;
`;

export const SubHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid white;
    border-radius: 10px;
    padding-top: 10px;
    background-color: white;
`;

export const SubHeaderTitle = styled.div`
    font-size: 20px;
    color: black;
    font-weight: 500;
    margin-right: 5px;
`;

export const SubHeaderSpan = styled.div`
    font-size: 12px;
    letter-spacing: 0.8px;
`;

export const SubHeaderRight = styled.div`
    position: absolute;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width: 800px){
        display: none;
    }
`;


export const ContainerGroup = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;
    padding-bottom: 0px;
    background-color: white;
`;

export const GroupHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
`;

export const GroupName = styled.h3`
    font-weight: 400;
    color: black;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;

    svg {
        margin-right: 10px;
    }
`;

export const GroupBody = styled.div`
    margin-top: 10px;
`;

export const GroupItems = styled.span<GroupItemsProps>`
    margin-left: 10px;
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
    color: white;

    ${(props) =>
        props.color &&
        css`
      background-color: ${props.color};
    `}
    
`;

export const DividerGroup = styled.div`
    border: 1px solid #eef2f8;
    width: 100%;
`;

export const TagOrigin = styled.span`
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 400;
    text-transform: uppercase;
    color: black;
    margin-left: 5px;
`;

export const ItemRegisterContainer = styled.div`
    position: relative;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;

    :hover {
        .focus-task {        
            display: flex;
        }
    }
`;

export const ItemRegisterActions = styled.div`
    position: absolute;
    right: 0px;
    top: -20px;
    display: none;
    align-items: center;
    justify-content: right;
    margin-bottom: -15px;
    margin-right: 50px;  
`;

export const ItemRegisterActionFocus = styled.button`    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f23b5c;
    font-weight: 500;
    color: white;
    padding: 3px 8px;
    font-size: 12px;
    border-radius: 5px 5px 0px 0px;
    border: none;        

    svg {
        margin-right: 5px;
    }
`;

export const FocusContainerTasks = styled.div`
    display: block;
    overflow: auto;
`;

export const AddFocusContainer = styled.div`
    border: 1px dashed #80808059;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    font-size: 14px;
    font-weight: 400;

    svg {
        margin-right: 5px;
        font-size: 15px;
    }

    @media(max-width: 800px){
        padding: 25px;   

        svg {
            margin-right: 10px;
        }
    }
`;

export const AgendaContainer = styled.div`
    height: 100%;
`;

export const AgendaHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #eef2f8;
    min-height: 50px;
    padding-top: 0px;
`;

export const AgendaDate = styled.div`
    color: black;
    font-size: 20px;
    margin-right: 15px;
    font-size: 17px;
    margin-top: 5px;
`;

export const AgendaButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    margin-top: 5px;
`;

export const AgendaBtn = styled.button`
    margin-right: 10px;
    font-size: 15px;
    color: grey;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AgendaToday = styled.button`
    margin-right: 10px;
    font-size: 14px;
    color: grey;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AgendaBody = styled.div`
    height: calc(100% - 60px);
    margin-top: 15px;
    overflow-y: auto;
`;

export const AgendaCleanContainer = styled.div`
    border: 1px dashed #80808059;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 13px;
    font-weight: 400;

    svg {
        margin-right: 5px;
    }  
`;

export const AgendaTotalItems = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    font-size: 14px;
    color: grey;

    svg {
        margin-right: 5px;
    }  
`;

export const ButtonGroupLeft = styled.button<ButtonGroupProps>`
    border: none;
    font-size: 12px;
    background-color: #f4f4f4;
    padding: 5px 10px;
    border-radius: 10px 0px 0px 10px;
    border-right: 1px solid #858585;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;

    ${(props) =>
        props.active &&
        css`
        background-color: gray;
        color: white;
        font-weight: 500;
    `}
`;

export const ButtonGroupRight = styled.button<ButtonGroupProps>`
    border: none;
    font-size: 12px;
    background-color: #f4f4f4;
    padding: 5px 10px;
    border-radius: 0px 10px 10px 0px;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;

    ${(props) =>
        props.active &&
        css`
        background-color: gray;
        color: white;
        font-weight: 500;
    `}
`;

export const AgendaTrackingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
`;

export const AgendaTrackingTotal = styled.div`
    height: 45px;
    width: 45px;
    background-color: gray;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.05);
    }
`;

export const ItemAgendaContainer = styled.div<ItemAgendaContainerProps>`
    background-color: #ffe9ec;
    border-radius: 10px;

    ${(props) =>
        props.color &&
        css`
      background-color: ${props.color + "1e"};
    `}

    :hover {
        box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    }
`;

export const ItemAgendaHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px;
    padding-bottom: 5px;
`;

export const ItemAgendaHeaderOrigin = styled.div`
    font-size: 10px;
    font-weight: 500;
`;

export const ItemAgendaHeaderTitle = styled.div`
    color: black;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #292d34;
    width: 100%;
`;

export const ItemAgendaBottom = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3px;
    padding-left: 15px;
    padding-right: 15px;
    border-top: 1px solid white;
    position: relative;
`;

export const ItemAgendaBottomLeft = styled.div`
    font-size: 12px;
`;

export const ItemAgendaBottomRight = styled.div`
    position: absolute;
    right: 15px;
    font-size: 12px;
    font-weight: 500;
`;

export const ItemAgendaHeaderLeft = styled.div<ItemAgendaHeaderLeftProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    min-width: 30px;
    background-color: red;
    border-radius: 10px;
    margin-right: 10px;

    ${(props) =>
        props.color &&
        css`
        background-color: ${props.color};
    `}

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        font-size: 15px !important;
    }
`;

export const ItemAgendaHeaderRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
    width: calc(100% - 40px);
`;

export const ButtonFilter = styled.button<ButtonFilterProps>`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 15px;
    padding: 3px 10px;
    font-size: 12px;
    color: gray;
    border: 1px solid;
    margin-left: 10px;
    transition: transform 0.3s ease;
    min-width: 80px;
    min-height: 24px;

    :hover {
        background-color: #f23c5d1e;
        color: #f23c5d;
        transform: scale(1.05);
    }

    svg {
        margin-right: 5px;
    }

    ${(props) =>
        props.active &&
        css`
        background-color: #f23c5d1e;
        color: #f23c5d;
    `}

    @media (max-width: 960px) {

      min-width: auto;
      margin-left: 5px;
    
      svg {
        margin-right: 0px;
      }

      label {
        display: none;
      }
    }
`;

export const DividerAgenda = styled.div`
    border-bottom: 1px solid #eef2f8;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #000000b5;
`;
import React, { useCallback, useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import {
    Body,
    BoxContainer,
    Header,
    ButtonAddNew,
    ContainerWorkspaceItems,
    WorkspaceTitle,
    WorkspaceItemIcon,
    WorkspaceItem,
    WorkspaceItemRight,
    CleanBox,
    CleanBoxTitle,
    CleanBoxText
} from "./styles";
import { FaPlus } from "react-icons/fa";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import AddNewWorkspace from "../../../dialogs/AddNewWorkspace";
import { Workspace } from "../../../interfaces/Workspace";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { BsFillPinAngleFill } from "react-icons/bs";
import { BiDotsHorizontal } from "react-icons/bi";
import { WorkspaceUser } from "../../../interfaces/WorkspaceUser";
import getAccessControl from "../../../middlewares/AccessControl";
import { useAuth } from "../../../hooks/auth";

interface UserMenuProps {
    open: boolean;
    isColapsed?: boolean;
    setWorkspacesFixed: (workspaces: Workspace[]) => void;
    closeMenu: () => void;
}

const WorkspaceMenu: React.FC<UserMenuProps> = ({ open, isColapsed, closeMenu, setWorkspacesFixed }) => {

    const history = useHistory();
    const { addToast } = useToast();
    const { user } = useAuth();

    const [editWorkspace, setEditWorkspace] = useState<Workspace>();
    const [workspaces, setWorkspaces] = useState<Workspace[]>();

    const [openNewWorkspace, setOpenNewWorkspace] = useState<boolean>(false);

    const getWorkspacesApi = useCallback(async () => {

        api.get(`/workspace/by-user`, {}
        ).then(response => {
            if (response.data !== null) {

                const respWorkspace: Workspace[] = response.data;

                if (respWorkspace !== undefined) {
                    setWorkspaces(respWorkspace);

                    const workspacesFixed = respWorkspace.filter((wp) => wp.workspace_users !== undefined && wp.workspace_users.length > 0 && wp.workspace_users[0].fixed === "S");
                    setWorkspacesFixed(workspacesFixed);

                }

            }
        }).catch(error => {
            console.log(error);
            addToast({
                type: 'error',
                title: 'Erro ao carregar os seus workspaces',
                description: 'Ocorreu ao tentar carregar os seus workspaces!',
            });
        });

    }, [addToast, setWorkspacesFixed]);

    const postWorkspaceFixedApi = useCallback(async (workspace_user: WorkspaceUser) => {

        api.put(`/workspace/user`, {
            id_workspace_user: workspace_user.id_workspace_user,
            workspace_id: workspace_user.workspace_id,
            type: workspace_user.type,
            fixed: workspace_user.fixed !== undefined && workspace_user.fixed !== null ? workspace_user.fixed === "S" ? "N" : "S" : "S",
        }
        ).then(response => {
            if (response.data !== null) {

                getWorkspacesApi();

            }
        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao carregar os seus workspaces',
                description: 'Ocorreu ao tentar carregar os seus workspaces!',
            });
        });

    }, [addToast, getWorkspacesApi]);

    const handleClickAway = () => {
        if (openNewWorkspace === false) {
            closeMenu();
        }
    };

    const goToWorkspace = useCallback((hash: string) => {
        history.push('/workspace/' + hash);
    }, [history]);

    const handleNewWorkspaceClose = useCallback(async (newWorkspace?: Workspace) => {

        setOpenNewWorkspace(false)

    }, []);

    const dialogDeleteSubmmit = useCallback(async () => {

        if (editWorkspace !== undefined) {

            await api.delete('/workspace', {
                params: {
                    id_workspace: editWorkspace.id_workspace
                }
            }).then(response => {

                setEditWorkspace(undefined);
                handleNewWorkspaceClose();

                return true;

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar o workspace',
                    description: 'Ocorreu um erro ao tentar deletar o workspace!',
                });
            });


        }

    }, [editWorkspace, addToast, handleNewWorkspaceClose]);

    useEffect(() => {

        //Get all workspaces
        if (open) {
            getWorkspacesApi();
        }

    }, [open, getWorkspacesApi]);

    useEffect(() => {

        //Get all workspaces
        if (!openNewWorkspace) {
            getWorkspacesApi();
        }

    }, [openNewWorkspace, getWorkspacesApi]);

    return (
        open ?
            <ClickAwayListener onClickAway={handleClickAway}>
                <BoxContainer iscolapsed={isColapsed ? 'true' : 'false'}>
                    <Header>
                        Espaços de trabalho
                        <span>workspaces</span>
                    </Header>
                    <Body>

                        {getAccessControl(83, user.type) && (
                            <ButtonAddNew onClick={() => {
                                setOpenNewWorkspace(true);
                                setEditWorkspace(undefined);
                            }}>
                                <FaPlus />
                                Criar novo workspace
                            </ButtonAddNew>
                        )}

                        <AddNewWorkspace
                            open={openNewWorkspace}
                            selectedWorkspace={editWorkspace}
                            onClose={handleNewWorkspaceClose}
                            dialogDeleteSubmmit={dialogDeleteSubmmit}
                        />

                        <ContainerWorkspaceItems>

                            {getAccessControl(83, user.type) && (workspaces === undefined || workspaces.length === 0) ?
                                <CleanBox onClick={() => {
                                    setOpenNewWorkspace(true);
                                    setEditWorkspace(undefined);
                                }}>
                                    <FaPlus />
                                    <CleanBoxTitle>
                                        Crie seu primeiro Workspace
                                    </CleanBoxTitle>
                                    <CleanBoxText>
                                        Workspaces são espaços de trabalho para você organizar fluxos e os membros de cada área da sua empresa em um só lugar.
                                    </CleanBoxText>
                                </CleanBox> :
                                <></>
                            }

                            <div>
                                {workspaces !== undefined && workspaces.filter((wp) => wp.workspace_users !== undefined && wp.workspace_users.length > 0 && wp.workspace_users[0].fixed === "S").length > 0 ?
                                    <>
                                        <WorkspaceTitle>
                                            Fixados
                                        </WorkspaceTitle>

                                        {workspaces !== undefined ?
                                            workspaces.filter((wp) => wp.workspace_users !== undefined && wp.workspace_users.length > 0 && wp.workspace_users[0].fixed === "S").map((wp) => {

                                                return (
                                                    <WorkspaceItem
                                                        key={wp.id_workspace + "fixed"}
                                                        color={wp.color}
                                                        selectedStyle={false}
                                                        onClick={() => {
                                                            goToWorkspace(wp.hash);
                                                            closeMenu();
                                                        }}
                                                    >
                                                        <WorkspaceItemIcon color={wp.color}>
                                                            <IconPickerItem
                                                                icon={wp.icon as IconList}
                                                                color={wp.color}
                                                            />
                                                        </WorkspaceItemIcon>
                                                        {wp.name}


                                                        <WorkspaceItemRight className="workspace-item-right" color={wp.color}>

                                                            {wp.workspace_users !== undefined && wp.workspace_users.length > 0 ?
                                                                <BsFillPinAngleFill onClick={() => wp.workspace_users !== undefined && wp.workspace_users.length > 0 ? postWorkspaceFixedApi(
                                                                    wp.workspace_users[0]
                                                                ) : {}} /> :
                                                                <></>
                                                            }

                                                            {getAccessControl(65, wp?.typeUserAccess !== undefined ? wp?.typeUserAccess : "") ?
                                                                <BiDotsHorizontal onClick={() => {
                                                                    setEditWorkspace(wp);
                                                                    setOpenNewWorkspace(true);
                                                                }} /> :
                                                                <></>
                                                            }

                                                        </WorkspaceItemRight>
                                                    </WorkspaceItem>
                                                )

                                            }) :
                                            <></>
                                        }
                                    </> :
                                    <></>
                                }

                            </div>

                            <div>
                                {workspaces !== undefined && workspaces.filter((wp) => wp.workspace_users === undefined || wp.workspace_users.length === 0 || wp.workspace_users[0].fixed !== "S").length > 0 ?
                                    <>
                                        <WorkspaceTitle>
                                            Todos
                                        </WorkspaceTitle>

                                        {workspaces !== undefined ?
                                            workspaces.filter((wp) => wp.workspace_users === undefined || wp.workspace_users.length === 0 || wp.workspace_users[0].fixed !== "S").map((wp) => {

                                                return (
                                                    <WorkspaceItem
                                                        key={wp.id_workspace}
                                                        color={wp.color}
                                                        selectedStyle={false}
                                                        onClick={() => {
                                                            goToWorkspace(wp.hash)
                                                            closeMenu();
                                                        }}
                                                    >
                                                        <WorkspaceItemIcon color={wp.color}>
                                                            <IconPickerItem
                                                                icon={wp.icon as IconList}
                                                                color={wp.color}
                                                            />
                                                        </WorkspaceItemIcon>
                                                        {wp.name}

                                                        <WorkspaceItemRight className="workspace-item-right" color={wp.color}>

                                                            {wp.workspace_users !== undefined && wp.workspace_users.length > 0 ?
                                                                <BsFillPinAngleFill onClick={() => wp.workspace_users !== undefined && wp.workspace_users.length > 0 ? postWorkspaceFixedApi(
                                                                    wp.workspace_users[0]
                                                                ) : {}} /> :
                                                                <></>
                                                            }

                                                            {getAccessControl(65, wp?.typeUserAccess !== undefined ? wp?.typeUserAccess : "") ?
                                                                <BiDotsHorizontal onClick={() => {
                                                                    setEditWorkspace(wp);
                                                                    setOpenNewWorkspace(true);
                                                                }} /> :
                                                                <></>
                                                            }

                                                        </WorkspaceItemRight>
                                                    </WorkspaceItem>
                                                )

                                            }) :
                                            <></>
                                        }
                                    </> :
                                    <></>
                                }

                            </div>

                        </ContainerWorkspaceItems>

                    </Body>
                </BoxContainer>
            </ClickAwayListener> :
            <></>
    );

}

export default WorkspaceMenu;
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled from "styled-components";
import Button from '../../components/Button';
import Input from '../../components/Forms/Fields/Components/Input';

export const Content = styled(DialogContent)`
    background-color: white;   
    padding: 30px 40px !important;    
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: flex-start;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const InviteTitle = styled.h1`
    font-weight: 500;
    color: black;
    font-size: 25px;
    margin-bottom: 25px;
`;

export const InviteDescription = styled.div`
    font-size: 16px;
    line-height: 1.75;    
    letter-spacing: 0.05px;
    color: #515358;
`;

export const InviteContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    form {
        width: 100%;

        .container-input {
           max-width: 450px !important;           
           margin-top: 0px;
           border: none !important;           

           input {
            font-size: 14px;
           }

           .icon-input {
            width: 18px;
           }
        }
    }
`;

export const InviteContainerTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #000000ba;
    margin-left: 2px;
`;

export const InviteInput = styled(Input)`

    
`;

export const InviteContainerButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 80px !important;
`;

export const InviteButtonSkip = styled(Button)`
    background-color: transparent !important;
    color: gray;
    justify-content: flex-start;
    padding-left: 0px;
    font-size: 15px;
    font-weight: 400;

    :hover {
        color: #f23b5c;
    }
`;

export const InviteButtonPlusEmail = styled(Button)`
    background-color: transparent !important;
    color: #f23b5c;
    justify-content: flex-start;
    padding-left: 0px;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;

    :hover {
        
    }
`;

export const InviteButton = styled(Button)`
    height: 36px;
    width: 250px;
    background: linear-gradient(90deg,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);

    :hover {
        background: linear-gradient(90deg,rgba(255,140,47,0.7) 25%,rgba(251,50,92,0.7) 50%,rgba(147,55,237,0.7) 75%);
    }
`;

export const CloseButton = styled(Button)`
    height: 36px;
    width: 200px;
    background: linear-gradient(90deg,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);
    margin-top: 40px;

    :hover {
        background: linear-gradient(90deg,rgba(255,140,47,0.7) 25%,rgba(251,50,92,0.7) 50%,rgba(147,55,237,0.7) 75%);
    }
`;

export const Animation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
    position: absolute;
    height: 100%;
    width: 100%;
`;

export const ContainerAnimation = styled.div`
    height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
        margin-bottom: 40px !important;
        height: 140px;
        width: auto;
        margin: 0px;
    }

    h1 {      
        color: black;
        font-weight: 500;
        font-family: Roboto, helvetica;    
        text-align: center;
        font-size: 25px;
        margin-bottom: 30px !important;
    }   

    h3 {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px !important;
    }
`;

export const ConfettiArea = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const BoxUserType = styled.select`
    border: 1px dashed #dfe7f0;
    padding: 2px 5px 2px 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContainerAcessType = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dee7f0;
    border-radius: 10px;
    padding: 0px 15px 0px 0px;
    margin-bottom: 10px;
`;
import styled, { css } from "styled-components";

interface ContainerProps {
    isViewMode: boolean;
}

export const Container = styled.div<ContainerProps>`
    color: black;

    ${(props) => props.isViewMode && css`
        
        .field-dynamic-tag {
            position: relative;
            background-color: #f23b5d;
            border-radius: 5px;
            color: white;
            text-decoration: auto;
            font-size: 12px;
            font-weight: 50;
            padding: 3px 5px;
            user-select: all;
        }

    `}
`;

export const DynamicTextContainer = styled.div`

`;

import * as Yup from 'yup';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaLock, FaUser } from 'react-icons/fa';
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { Link, useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import Button from '../../components/Button';
import Input from '../../components/Forms/Fields/Components/Input';
import LoaderCange from '../Loader/LoaderCange';
import api from '../../services/api';
import checked from '../../assets/checked.png';
import getValidationErrors from '../../utils/getValidationErrors';
import logoWendata from '../../assets/wendata-logo-white.png';
import { AnimationContainer, Container, ContainerSucess, Content, NoAccount } from './styles';
import { InvitedUser } from '../../interfaces/InvitedUser';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

interface RegisterInvitedDataForm {
    name: string;
    password: string;
}

interface RouteParams {
    hash: string;
}

const RegisterInvitedUser: React.FC = () => {

    const formRef = useRef<FormHandles>(null);
    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();
    const history = useHistory();
    const { signIn } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [sucess, setSucess] = useState<boolean>(false);
    const [invitedUser, setInvitedUser] = useState<InvitedUser>();

    const handleSubmit = useCallback(async (data: RegisterInvitedDataForm) => {

        if (invitedUser !== undefined) {

            try {

                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    name: Yup.string()
                        .required('O campo nome é obrigatório!')
                        .min(2, 'O campo nome deve conter mais de 2 caracteres!'),
                    password: Yup.string()
                        .required('O campo senha é obrigatório!')
                        .min(8, 'O campo senha deve conter mais de 8 caracteres!')
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                setLoading(true);

                //Recovery api
                await api
                    .post('/session/register-invited', {
                        hash: params.hash,
                        name: data.name,
                        password: data.password
                    })
                    .then(response => {

                        setSucess(true);

                        signIn({
                            email: invitedUser.email,
                            password: data.password,
                            isHashPassword: false
                        });

                    }).catch(error => {
                        console.log(error)
                        addToast({
                            type: 'error',
                            title: 'Erro ao cadastrar o usuário!',
                            description: 'Verifique os dados digitados, caso não tenha sucesso, entre em contato com o suporte!',
                        });
                    });

                setLoading(false);
            } catch (err) {
                setLoading(false);
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);
                    formRef.current?.setErrors(errors);
                }

                console.log(err);

                addToast({
                    type: 'error',
                    title: 'Erro ao cadastrar o usuário!',
                    description: 'Verifique os dados digitados, caso não tenha sucesso, entre em contato com o suporte!',
                });
            }
        } else {
            addToast({
                type: 'error',
                title: 'Erro ao buscar o seu convite',
                description: 'Ocorreu um erro ao buscar o seu convite! Olhe sua caixa de entrada novamente',
            });
        }

    }, [addToast, params.hash, invitedUser, signIn]);

    useEffect(() => {

        api.get('/session/invited-user?hash=' + params.hash).then(response => {

            const apiInvitedUser: InvitedUser = response.data;

            setInvitedUser(apiInvitedUser);

            setLoading(false);

        }).catch(error => {
            history.push('/');
            addToast({
                type: 'error',
                title: 'Erro ao encontrar seus dados!',
                description: 'Ocorreu um erro ao tentar buscar as informações do seu convite!',
            });
        });

    }, [params.hash, addToast, history]);

    return (
        <>
            <title>Cadastro Usuário | Cange</title>
            {loading ? <LoaderCange /> :
                <Container>

                    <img src={logoWendata} alt="cange.me" />
                    <Content>

                        <AnimationContainer>
                            {!sucess ?
                                <Form ref={formRef} onSubmit={handleSubmit}>

                                    <h1>Bem vindo! &#x1F600;</h1>
                                    <h3>Fique tranquilo, será rápido! Preencha os campos abaixo e confirme. Lembrando, a senha deve <b>conter mais de 8 caracteres</b>.</h3>

                                    <Input tabIndex={0} name="name" icon={FaUser} placeholder="Digite aqui o seu nome" />
                                    <Input tabIndex={0} name="password" type="password" icon={FaLock} placeholder="Digite aqui a sua senha" />

                                    <Button tabIndex={0} type="submit" height="56px">Criar minha conta</Button>

                                    <NoAccount tabIndex={-1}>Quer voltar para o Login?
                                        <Link to="/" style={{ color: '#14aaf5', paddingLeft: '5px' }}>
                                            Clique aqui!
                                        </Link>
                                    </NoAccount>

                                </Form> :
                                <ContainerSucess>

                                    <h1>Conta criada! &#x270C;</h1>

                                    <img src={checked} alt="wendata" />

                                    <h3>Feito! Sua conta está pronta, vá para o login e aproveite! &#x1F609;</h3>

                                    <NoAccount tabIndex={-1}>Quer voltar para o Login?
                                        <Link to="/" style={{ color: '#14aaf5', paddingLeft: '5px' }}>
                                            Clique aqui!
                                        </Link>
                                    </NoAccount>

                                </ContainerSucess>
                            }
                        </AnimationContainer>
                    </Content>
                </Container>
            }
        </>
    );
}

export default RegisterInvitedUser;
import styled, { css } from "styled-components";

interface ColumnHeaderProps {
  color: string;
}

interface ColumnsHeaderSpanProps {
  color: string;
}

interface ColumnsAggregationProps {
  color: string;
}

interface ContainerBtnConfigureProps {
  color: string;
}

interface ColumnItemsProps {
  isDraggingOver?: boolean;
  isDropDisabled?: boolean;
  color?: string;
}

export const Container = styled.div`
  height: 100%;
  margin-right: 10px;
  min-width: 300px;
  max-width: 300px;
  max-height: 100vh; //Paliativo
  //overflow: hidden;
  overflow-y: clip;
  z-index: 1;
`;

export const ColumnsHeader = styled.div<ColumnHeaderProps>`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: start;
  align-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: white;
  //margin-bottom: 10px;
  height: 48px;
  max-height: 60px;
  padding-right: 10px;
  padding-left: 10px;

  .col-hed-conf {
    margin-right: 5px !important;
  }

  .col-cont-conf {
    right: -16px;
  }

  ${(props) =>
    props.color &&
    css`
      border: 2px solid ${props.color};
      background-color: ${props.color};
    `}

  :hover {
    .col-hed-conf {
      display: flex;
    }    

    .column-count-container {
      display: none;
    }
  }
`;

export const ColumnsHeaderIcon = styled.div`
  //width: 20%;
  width: 24px;
  margin-right: 3px;
  display: flex;
  justify-items: center;
  align-items: center;

  svg {
    display: flex;
    color: #f23b5c;
    font-size: 18px;
    color: white;
  }

  transition: transform 0.3s ease;
  cursor: pointer;

  :hover {      
      transform: scale(1.1);
  }
`;

export const ColumnsHeaderTitle = styled.div`
  //width: 55%;
  width: calc(100% - 60px);
  display: flex;
  justify-items: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: white;
`;

export const InputTitle = styled.input`
    border: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: 500;
    color: white;
    outline: none;
    padding: 2px 5px;
    border: 1px solid transparent;
    width: 100%;

    :hover {
        border: 1px solid #e0dfdf;
        border-radius: 10px;
    }

    :focus {
        border: 1px solid #e0dfdf;
        border-radius: 10px;
    }
`;

export const ColumnsHeaderSpan = styled.div<ColumnsHeaderSpanProps>` 
  display: flex;
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: white;
  font-size: 13px;    
  margin-right: 5px;

  span {
    background-color: #8917e3;
    padding: 7px;
    padding-right: 7px;
    padding-left: 7px;
    border-radius: 15px;
    min-width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
      filter: brightness(90%);
    `}
  }
`;

export const ColumnsItems = styled.div<ColumnItemsProps>`
  overflow-y: auto;
  background-color: white;
  height: calc(100% - 65px);
  padding: 15px;
  border-radius: 0px 0px 10px 10px;

  position: relative; /* Ou use z-index corretamente */
  z-index: 1; /* Ajuste conforme necessário */

  ${(props) => props.isDraggingOver === true && css`
      border: 2px solid ${props.color};     
      border-top: none;
  `}

  ${(props) => props.isDropDisabled === true && css`
      display: none;
  `}
`;

export const ColumnsItemsDisabled = styled.div<ColumnItemsProps>`
  overflow-y: auto;
  background-color: #f4f4f4;
  height: calc(100% - 65px);
  padding: 15px;
  border-radius: 0px 0px 10px 10px;

  ${(props) => props.isDraggingOver === true && css`
      border: 2px solid ${props.color};     
      border-top: none;
  `}
`;

export const ColumnDescription = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;

  p {
    font-size: 14px;
    color: gray;
    margin-bottom: 5px;
    margin-left: 5px;
    font-weight: 400;
  }

`;

export const ColumnsHeaderRight = styled.div`
  position: absolute;
  right: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ColumnsAggregation = styled.div<ColumnsAggregationProps>`
  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color + "1e"};
    `}
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const ColumnAggregationItem = styled.div`
  display: flex;
  align-content: center;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: gray;
  padding: 5px 0px;
`;

export const AggregationItemName = styled.div`
  width: 50%;
  text-align: center;
  border-right: 1px solid gray;
  overflow: hidden;
  max-height: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: none;
  }

  :hover {
      svg {
        font-size: 11px;
        color: gray;
        display: flex;
        margin-left: 10px;
      }
    }
`;

export const AggregationItemValue = styled.div`
  width: 50%;
  text-align: center;
  overflow: hidden;
  max-height: 15px;
`;

export const ContainerBtnConfigure = styled.div<ContainerBtnConfigureProps>`
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {

    cursor: pointer;
    height: 20px;
    ${(props) => props.color && css`
        background-color: ${props.color + "1e"};
    `}

    .btnConfigAgg {
      display: flex;
    }
  }
`;

export const BtnConfigureAgg = styled.button`
  display: none;
  border: none;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 20px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: gray;

  svg {
    font-size: 11px;
    color: gray;
    margin-right: 10px;
  }
`;

export const ContainerLoader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
    width: 30px;
`;


export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
    }
    
`;
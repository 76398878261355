import { Card } from "../../interfaces/Card";
import { TimeTracking } from "../../interfaces/TimeTracking";

export interface TimeSheetInsightsProps {
    totDuration: number;
    totUsers: number;
}

export default function getInsightsFromCards(cards: Card[]): TimeSheetInsightsProps {

    let insights: TimeSheetInsightsProps;

    let totDuration = 0;
    let uniqueUserIds: number[] = [];
    let totUsers = 0;

    if (cards !== undefined && cards.length > 0) { //If there are cards        

        for (let index = 0; index < cards.length; index++) { //Run through the cards
            const card = cards[index];

            //Get the values
            if (card.time_trackings !== undefined && card.time_trackings.length > 0) {

                //Sum the duration
                totDuration += card.time_trackings.reduce((sum, item) => sum + (item.duration || 0), 0);

                //Valid if the users already are in the list of unique users
                card.time_trackings.forEach((item: TimeTracking) => {
                    if (uniqueUserIds.indexOf(item.user_id) === -1) {
                        uniqueUserIds.push(item.user_id);
                    }
                });

            }


        }

    }

    totUsers = uniqueUserIds.length;

    insights = {
        totDuration: totDuration,
        totUsers: totUsers
    };

    return insights;

}
import React from "react";

import emptyTask from '../../../assets/empty-state/empty-task.png';
import {
    Container,
    ContainerImage,
    ContainerSubTitle,
    ContainerTitle
} from "./style";

interface emptyStateProps {

}

const EmptyTask: React.FC<emptyStateProps> = () => {

    return (
        <Container>
            <ContainerImage>
                <img src={emptyTask} alt="Sem Tarefas" />
            </ContainerImage>
            <ContainerTitle>
                <h1>Você não possui tarefas</h1>
            </ContainerTitle>
            <ContainerSubTitle>
                <span>Fique tranquilo, por aqui você ainda está sem tarefas</span>
            </ContainerSubTitle>
        </Container>
    );

}

export default EmptyTask;
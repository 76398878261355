import React, { useCallback } from "react";
import { IconBaseProps } from "react-icons";
import { RiArrowDownSLine } from "react-icons/ri";
import { RiArrowUpSLine } from "react-icons/ri";

import {
    BodyContainer,
    ButtonDown,
    ButtonUp,
    Container,
    ContainerBox,
    ContainerOrder,
    LeftContainer,
    RightContainer
} from "./styles";

interface FieldBoxProps {
    title: string;
    field_type?: string;
    index?: number;
    icon: React.ComponentType<IconBaseProps>;
    hide_order?: boolean;
    color?: string;
    color_background?: string;
    color_icon?: string;
    isBeta?: boolean;
    moveField?: (type: string, index: number) => void; //type: up - pra cima, down - pra baixo
    editField?: (index: number) => void;
}

const FieldBox: React.FC<FieldBoxProps> = ({ title, field_type, index, icon: Icon, hide_order, moveField, editField, color, color_background, color_icon, isBeta }) => {

    const alignCenter: boolean = hide_order ? true : false;

    const handleMoveField = useCallback(async (type: string, index: number | undefined) => {

        if (index === undefined) {
            index = 0;
        }

        if (moveField !== undefined) {
            moveField(type, index);
        }

    }, [moveField]);

    const handleEditField = useCallback(async (index: number | undefined) => {

        if (index === undefined) {
            index = 0;
        }

        if (editField !== undefined) {
            editField(index);
        }

    }, [editField]);

    return (
        <Container>

            <ContainerBox onClick={() => handleEditField(index)} color={color} colorBackground={color_background}>
                <LeftContainer color={color} colorBackground={color_background} colorIcon={color_icon}>
                    <Icon size="20" />
                </LeftContainer>

                <BodyContainer alignCenter={alignCenter}>
                    <h1>
                        {title.length <= 30 ? title : title.substring(0, 30) + "..."}
                        {isBeta ?
                            <span>Beta</span> :
                            <></>
                        }
                    </h1>
                    {!!field_type ?
                        <h2>
                            {field_type}
                        </h2> :
                        <> </>
                    }
                </BodyContainer>

                {index !== undefined ?
                    <RightContainer>
                        <p># {index + 1}</p>
                    </RightContainer> :
                    <> </>
                }
            </ContainerBox>

            {!hide_order ?
                <ContainerOrder>
                    <ButtonUp type="button" onClick={() => handleMoveField("up", index)}><RiArrowUpSLine /></ButtonUp>
                    <ButtonDown type="button" onClick={() => handleMoveField("down", index)}><RiArrowDownSLine /></ButtonDown>
                </ContainerOrder> : <></>
            }
        </Container>
    )
};

export default FieldBox;
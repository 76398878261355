import React, { useEffect, useState, useCallback } from "react";

import {
    ContentBody,
    ContainerLeft,
    ContainerRight,
    SubHeaderContainer,
    SubHeaderTitle,
    SubHeaderSpan,
    SubHeaderRight,
    WidgetContainer,
    ContentContainer,
    ContainerGroup,
    GroupHeader,
    GroupName,
    GroupItems,
    DividerGroup,
    GroupBody,
    MyWorkContainer,
    ItemRegisterContainer,
    ItemRegisterActions,
    ItemRegisterActionFocus,
    FocusContainerTasks,
    AddFocusContainer,
    AgendaContainer,
    AgendaHeader,
    AgendaDate,
    AgendaButtons,
    AgendaBtn,
    AgendaBody,
    AgendaToday,
    AgendaCleanContainer,
    ButtonGroupLeft,
    ButtonGroupRight,
    AgendaTrackingContainer,
    AgendaTrackingTotal,
    ItemAgendaContainer,
    ItemAgendaHeader,
    ItemAgendaHeaderOrigin,
    ItemAgendaHeaderTitle,
    ItemAgendaBottom,
    ItemAgendaBottomLeft,
    ItemAgendaBottomRight,
    ItemAgendaHeaderLeft,
    ItemAgendaHeaderRight,
} from "./style";
import { useToast } from '../../../hooks/toast';
import CardDialog from "../../../dialogs/Card";
import { Card } from "../../../interfaces/Card";
import pt from 'date-fns/locale/pt-BR';
import ItemRegister, { ComboItem } from "../../../components/ItemRegister";
import formAnswerToComboItemFromFlow from "../../../utils/formAnswerToComboItemFromFlow";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { KanbanConfigProps } from "../../../components/Kanban";
import { Flow } from "../../../interfaces/Flow";
import api from "../../../services/api";
import getKanbanConfig from "../../../utils/KanbanConfig/getKanbanConfig";
import { FiTarget } from "react-icons/fi";
import { IoRemoveCircleOutline } from "react-icons/io5";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { addDays, format } from "date-fns";
import EmptyTask from "../../../components/EmptyState/EmptyTask";
import getFields from "../../../utils/getFields";
import { FieldProps } from "../../../components/Forms/Fields/FieldBuilder";
import updateFormulaFromCard from "../../../utils/Formula/updateFormulaFromCard";
import { useMediaQuery } from "react-responsive";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { TimeTracking } from "../../../interfaces/TimeTracking";

interface FocusTabProps {
    cards?: Card[];
    conditions?: string[];
    expandedAll?: boolean;
    forceUpdate: () => void;
    handleCardFocus: (focus: boolean, id_card?: number,) => void;
}

const FocusTab: React.FC<FocusTabProps> = ({ cards, conditions, expandedAll, forceUpdate, handleCardFocus }) => {

    const { addToast } = useToast();

    const [itemsFlow, setItemsFlow] = useState<ComboItem[]>([]);
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

    const [cardSelected, setCardSelected] = useState<Card>();
    const [flowSelected, setFlowSelected] = useState<Flow>();
    const [kanbanConfig, setKanbanConfig] = useState<KanbanConfigProps>();
    const [openCard, setOpenCard] = useState<boolean>(false);
    const [groupByType, setGroupByType] = useState<number>(0); // 0 - By Due Date / 1 - By Flow
    const [agendaView, setAgendaView] = useState<number>(0); // 0 - Tasks / 1 - Tracks
    const [dateAgenda, setDateAgenda] = useState<Date>(new Date());
    const [dateAgendaFormated, setDateAgendaFormated] = useState<string>();
    const [isHovered, setIsHovered] = useState(false);
    const [totalTrackTime, setTotalTrackTime] = useState(0);
    const [tracks, setTracks] = useState<TimeTracking[]>([]);
    const [tracksFiltered, setTracksFiltered] = useState<TimeTracking[]>([]);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const handleMouseEnter = () => {
        setTimeout(() => {
            setIsHovered(true);
        }, 300);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const toggleGroup = (flowName: string) => {
        if (expandedGroups.includes(flowName)) {
            setExpandedGroups(expandedGroups.filter((group) => group !== flowName));
        } else {
            setExpandedGroups([...expandedGroups, flowName]);
        }
    };

    const formatDuration = (timeInSeconds: number, pocket?: boolean): string => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        if (pocket) {
            return `${formattedHours}:${formattedMinutes}`;
        } else {
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        }
    };

    function compareItems(a: ComboItem, b: ComboItem): number {
        // Critério 1: Items com o campo completed igual a "S"
        if (a.complete === 'S' && b.complete !== 'S') {
            return 1; // a vem antes de b
        }
        if (a.complete !== 'S' && b.complete === 'S') {
            return -1; // a vem depois de b
        }

        // Critério 3: Items com o campo due_date_completed mais antigos
        if (a.due_date_completed && b.due_date_completed) {
            const dateA = new Date(a.due_date_completed).getTime();
            const dateB = new Date(b.due_date_completed).getTime();
            return dateA - dateB; // Ordenação ascendente por data
        }

        // Critério 2: Items com o campo due_date_completed igual a null ou undefined
        if (!a.due_date_completed && b.due_date_completed) {
            return 1; // a vem antes de b
        }
        if (a.due_date_completed && !b.due_date_completed) {
            return -1; // a vem depois de b
        }

        if (a.id_register !== undefined && b.id_register !== undefined) {
            return a.id_register - b.id_register;
        }

        // Se todos os critérios forem iguais, mantém a ordem original
        return 0;

    }

    function compareTracks(a: TimeTracking, b: TimeTracking): number {

        // Critério 3: Items com o campo due_date_completed mais antigos
        if (a.dt_start && b.dt_start) {
            const dateA = new Date(a.dt_start).getTime();
            const dateB = new Date(b.dt_start).getTime();
            return dateA - dateB; // Ordenação ascendente por data
        }

        // Se todos os critérios forem iguais, mantém a ordem original
        return 0;

    }

    const groupedCards: { [flowName: string]: ComboItem[] } = itemsFlow.reduce(
        (acc, item) => {

            if (groupByType === 0) { //Due Date
                if (item.due_date_status_string !== undefined) {
                    if (acc[item.due_date_status_string]) {
                        acc[item.due_date_status_string].push(item);
                    } else {
                        acc[item.due_date_status_string] = [item];
                    }
                }
            } else if (groupByType === 1) { //Flow
                if (item.originGroup !== undefined) {
                    if (acc[item.originGroup]) {
                        acc[item.originGroup].push(item);
                    } else {
                        acc[item.originGroup] = [item];
                    }
                }
            }

            return acc;
        },
        {} as { [flowName: string]: ComboItem[] }
    );

    const getApiFlow = useCallback(async (id_flow: number) => {

        await api.get(`/flow`, {
            params: {
                id_flow: id_flow,
                withSteps: true,
                withCards: false
            }
        }).then(response => {
            if (response.data !== null) {

                const respFlow: Flow = response.data;

                if (respFlow !== undefined) {

                    setFlowSelected(respFlow);
                    setKanbanConfig(getKanbanConfig(respFlow));

                }
            }

        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao carregar o Fluxo',
                description: 'Ocorreu ao tentar carregar o Fluxo!',
            });
        });

    }, [addToast]);

    const cardOpen = useCallback(async (id_card: number, cardToOpen?: Card) => {

        if (id_card !== undefined) {
            const card = cards?.filter((c) => id_card === c.id_card)[0];

            if (card !== undefined) { //If is from the cards list
                setCardSelected(card);
                getApiFlow(card.flow_id);
                setOpenCard(true);
            } else if (cardToOpen !== undefined && cardToOpen.id_card === id_card) { //If is from the tracks
                setCardSelected(cardToOpen);
                getApiFlow(cardToOpen.flow_id);
                setOpenCard(true);
            }
        }

    }, [getApiFlow, cards]);

    const getCardApi = useCallback(async (card_id: number, flow_id: number, openCard?: boolean) => {

        await api.get(`/card`, {
            params: {
                id_card: card_id,
                flow_id: flow_id,
            }
        }).then(response => {
            if (response.data !== null) {

                const respCard: Card = response.data;

                if (respCard !== undefined) {
                    setCardSelected(respCard);

                    if (openCard !== undefined && openCard) {
                        cardOpen(respCard.id_card, respCard);
                    }
                }

            }
        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao atualizar o objeto',
                description: 'Ocorreu um erro ao tentar atualizar o objeto!',
            });
        });

    }, [addToast, cardOpen]);

    const getTimeTrackingApi = useCallback(async () => {

        await api.get(`/time-tracking/by-user`, {
            params: {
                date: format(dateAgenda, "yyyy-MM-dd", { locale: pt }),
            }
        }).then(response => {
            if (response.data !== null) {

                let respTracks: TimeTracking[] = response.data;

                let totalDuration = 0;
                if (respTracks !== undefined && respTracks.length > 0) {
                    respTracks.forEach(track => {
                        if (track.duration !== undefined) {
                            totalDuration += track.duration;
                        }
                    });
                }

                respTracks = respTracks.sort(compareTracks);

                setTotalTrackTime(totalDuration);
                setTracks(respTracks);
                setTracksFiltered(respTracks);
            }

        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao carregar o Fluxo',
                description: 'Ocorreu ao tentar carregar o Fluxo!',
            });
        });

    }, [addToast, dateAgenda]);

    const cardClose = useCallback(async () => {
        setOpenCard(false);
        setCardSelected(undefined);
        setFlowSelected(undefined);
        setKanbanConfig(undefined);
        forceUpdate();
    }, [forceUpdate]);

    useEffect(() => {

        if (cards !== undefined) {
            let apiRetCard: Card[] = cards;
            let newComboItemsFlow: ComboItem[] = [];

            if (apiRetCard !== undefined && apiRetCard.length > 0) {
                for (let index = 0; index < apiRetCard.length; index++) {
                    let card = apiRetCard[index];

                    if (card.flow !== undefined) {

                        const flow = card.flow;

                        let fields: FieldProps[] = [];
                        if (card.flow.form_init !== undefined && card.flow.flow_steps !== undefined) {
                            fields = getFields(card.flow.form_init, card.flow.flow_steps);

                            card = updateFormulaFromCard(card, fields);
                        }

                        const newComboItem: ComboItem = formAnswerToComboItemFromFlow(card, flow);

                        newComboItemsFlow.push(newComboItem);
                    }
                }
            }

            //Sort the items
            newComboItemsFlow = newComboItemsFlow.sort(compareItems);

            setItemsFlow(newComboItemsFlow);

        }

    }, [cards]);

    useEffect(() => {

        const dtFormated = format(
            dateAgenda,
            "dd 'de' MMMM',' EE",
            { locale: pt }
        );

        setDateAgendaFormated(dtFormated);

        getTimeTrackingApi();

    }, [dateAgenda, getTimeTrackingApi, openCard])

    useEffect(() => {

        if (conditions !== undefined && conditions.length > 0 && tracks !== undefined && tracks.length > 0) {
            const newTracksFiltered = tracks.filter((c) => {
                const isFiltered = conditions.filter((co) => c.flow_id !== undefined && String(c.flow_id) === co);
                if (isFiltered.length > 0) {
                    return true;
                } else {
                    return false;
                }
            })

            setTracksFiltered(newTracksFiltered);
        } else {
            setTracksFiltered(tracks);
        }

    }, [tracks, conditions])

    return (
        <ContentBody>

            <ContainerLeft
                item
                xs={12}
                sm={12}
                md={7}
                lg={8}
            >

                <div className="container">
                    <WidgetContainer>
                        <SubHeaderContainer>
                            <SubHeaderTitle>
                                Meu Foco
                            </SubHeaderTitle>
                            <SubHeaderSpan>
                                ({itemsFlow.filter((f) => f.focus) !== undefined ? itemsFlow.filter((f) => f.focus).length : 0})
                            </SubHeaderSpan>

                            <SubHeaderRight>
                            </SubHeaderRight>
                        </SubHeaderContainer>

                        <ContentContainer style={{ minHeight: '100px' }}>
                            <FocusContainerTasks>

                                {itemsFlow.filter((f) => f.focus).length <= 0 && (
                                    <AddFocusContainer>
                                        <FiTarget />
                                        Passe o mouse sobre alguma tarefa e adicione no seu foco
                                    </AddFocusContainer>
                                )}

                                {itemsFlow.filter((f) => f.focus).map((item) => (
                                    <ItemRegisterContainer key={item.id_register}>
                                        <ItemRegisterActions className="focus-task">
                                            <ItemRegisterActionFocus onClick={() => handleCardFocus(false, item.id_register)}>
                                                <IoRemoveCircleOutline />
                                                Remover
                                            </ItemRegisterActionFocus>
                                        </ItemRegisterActions>
                                        <ItemRegister
                                            key={item.id_register}
                                            type={'Flow'}
                                            item={item}
                                            hideDetails={true}
                                            handleClick={cardOpen}
                                        />
                                    </ItemRegisterContainer>
                                ))}
                            </FocusContainerTasks>
                        </ContentContainer>

                    </WidgetContainer>

                    <WidgetContainer>
                        <SubHeaderContainer>
                            <SubHeaderTitle>
                                Meu trabalho
                            </SubHeaderTitle>
                            <SubHeaderSpan>
                                ({cards !== undefined ? cards.length : 0})
                            </SubHeaderSpan>

                            <SubHeaderRight>
                                <ButtonGroupLeft active={groupByType === 0} onClick={() => setGroupByType(0)}>
                                    Por Vencimento
                                </ButtonGroupLeft>
                                <ButtonGroupRight active={groupByType === 1} onClick={() => setGroupByType(1)}>
                                    Por Fluxo
                                </ButtonGroupRight>
                            </SubHeaderRight>
                        </SubHeaderContainer>

                        <MyWorkContainer style={{ paddingBottom: '25px' }}>
                            {Object.entries(groupedCards).map(([flowName, itemsFlow]) => {

                                let isExpanded = expandedGroups.includes(flowName);

                                if (expandedAll) { //Bypass the control
                                    isExpanded = true;
                                }

                                return (
                                    <ContainerGroup key={flowName}>
                                        <GroupHeader onClick={() => toggleGroup(flowName)}>
                                            <GroupName>
                                                {isExpanded ?
                                                    <FaCaretDown /> :
                                                    <FaCaretRight />
                                                }
                                                {flowName}
                                            </GroupName>
                                            <GroupItems
                                                color={
                                                    flowName === "Em Atraso" ? "#f23c5d" :
                                                        flowName === "Hoje" ? "#ffd400" :
                                                            flowName === "Amanhã" ? "#fe8c2f" :
                                                                flowName === "Próximo" ? "#4681b8" : "gray"
                                                }
                                            >{itemsFlow.length}</GroupItems>
                                        </GroupHeader>
                                        {isExpanded && (
                                            <>
                                                <DividerGroup />
                                                <GroupBody>
                                                    {itemsFlow.map((item, index) => (
                                                        <ItemRegisterContainer key={item.id_register} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            {isHovered ?
                                                                <ItemRegisterActions className="focus-task">
                                                                    <ItemRegisterActionFocus className="focus-task-item" onClick={() => handleCardFocus(true, item.id_register)}>
                                                                        <FiTarget />
                                                                        Incluir no meu foco
                                                                    </ItemRegisterActionFocus>
                                                                </ItemRegisterActions> : <></>
                                                            }
                                                            <ItemRegister
                                                                key={item.id_register}
                                                                type={'Flow'}
                                                                item={item}
                                                                hideDetails={true}
                                                                handleClick={cardOpen}
                                                            />
                                                        </ItemRegisterContainer>
                                                    ))}
                                                </GroupBody>
                                            </>
                                        )}
                                    </ContainerGroup>
                                )
                            })}

                            {openCard && cardSelected !== undefined && flowSelected !== undefined && (
                                <CardDialog
                                    open={openCard}
                                    isMobile={isMobile}
                                    flow={flowSelected}
                                    kanban_config={kanbanConfig}
                                    selectedValue={cardSelected}
                                    onClose={cardClose}
                                    typeUser={flowSelected.typeUserAccess !== undefined ? flowSelected.typeUserAccess : ""}
                                    showFlowDetail={true}
                                />
                            )}
                        </MyWorkContainer>
                    </WidgetContainer>
                </div>

            </ContainerLeft>

            <ContainerRight
                item
                md={5}
                lg={4}
                style={{ marginLeft: '15px' }}
                className="schedule-tasks"
            >
                <WidgetContainer style={{ height: '100%' }}>
                    <SubHeaderContainer>
                        <SubHeaderTitle>
                            Minha Agenda
                        </SubHeaderTitle>
                        <SubHeaderSpan>
                        </SubHeaderSpan>

                        <SubHeaderRight>
                            <ButtonGroupLeft style={{ minWidth: '80px' }} active={agendaView === 0} onClick={() => setAgendaView(0)}>
                                Tarefas
                            </ButtonGroupLeft>
                            <ButtonGroupRight style={{ minWidth: '80px' }} active={agendaView === 1} onClick={() => setAgendaView(1)}>
                                Tracks
                            </ButtonGroupRight>
                        </SubHeaderRight>
                    </SubHeaderContainer>

                    <ContentContainer style={{ height: 'calc(100% - 60px)' }}>
                        <AgendaContainer>
                            <AgendaHeader>
                                <AgendaDate>
                                    {dateAgendaFormated}
                                </AgendaDate>
                                <AgendaButtons>
                                    <AgendaBtn onClick={() => setDateAgenda(addDays(dateAgenda, -1))}>
                                        <MdArrowBackIos />
                                    </AgendaBtn>
                                    <AgendaBtn onClick={() => setDateAgenda(addDays(dateAgenda, 1))}>
                                        <MdArrowForwardIos />
                                    </AgendaBtn>
                                    <AgendaToday onClick={() => setDateAgenda(new Date())}>
                                        Hoje
                                    </AgendaToday>
                                </AgendaButtons>

                                {totalTrackTime > 0 && (
                                    <AgendaTrackingContainer>
                                        <AgendaTrackingTotal>
                                            {formatDuration(totalTrackTime, true)}
                                        </AgendaTrackingTotal>
                                    </AgendaTrackingContainer>
                                )}

                                {/*
                                <AgendaTotalItems>
                                    <FaCalendar />
                                    {itemsFlow.filter((f) => {

                                        if (f.due_date_completed !== undefined) {
                                            const dItem = format(new Date(f.due_date_completed), "dd/MM/yyyy", { locale: pt });
                                            const dAgenda = format(dateAgenda, "dd/MM/yyyy", { locale: pt });

                                            if (dItem === dAgenda) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        } else {
                                            return false;
                                        }
                                    }).length}
                                </AgendaTotalItems>
                                */}
                            </AgendaHeader>
                            <AgendaBody>

                                {itemsFlow.filter((f) => {

                                    if (f.due_date_completed !== undefined) {
                                        const dItem = format(new Date(f.due_date_completed), "dd/MM/yyyy", { locale: pt });
                                        const dAgenda = format(dateAgenda, "dd/MM/yyyy", { locale: pt });

                                        if (dItem === dAgenda) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        return false;
                                    }
                                }).length <= 0 && tracksFiltered.length <= 0 && (
                                        <AgendaCleanContainer>
                                            <EmptyTask />
                                        </AgendaCleanContainer>
                                    )}

                                {agendaView === 0 && itemsFlow.filter((f) => {

                                    if (f.due_date_completed !== undefined) {
                                        const dItem = format(new Date(f.due_date_completed), "dd/MM/yyyy", { locale: pt });
                                        const dAgenda = format(dateAgenda, "dd/MM/yyyy", { locale: pt });

                                        if (dItem === dAgenda) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        return false;
                                    }
                                }).map((item, index) => {

                                    let timeDue: string = item.due_date_completed !== undefined ? format(
                                        new Date(item.due_date_completed),
                                        "HH:mm",
                                        { locale: pt }
                                    ) : "";

                                    if (timeDue === "00:00") {
                                        timeDue = "O dia todo";
                                    }

                                    const colorItem = item.step !== undefined && item.step.color !== undefined ? item.step.color : item.color !== undefined ? item.color : "#4681b8";
                                    const iconItem = item.step !== undefined && item.step.icon !== undefined ? item.step.icon : item.icon !== undefined ? item.icon : "FaCalendar";

                                    return (
                                        <ItemRegisterContainer key={item.id_register} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            {isHovered ?
                                                <ItemRegisterActions className="focus-task">
                                                    <ItemRegisterActionFocus onClick={() => handleCardFocus(true, item.id_register)}>
                                                        <FiTarget />
                                                        Incluir no meu foco
                                                    </ItemRegisterActionFocus>
                                                </ItemRegisterActions> : <></>
                                            }
                                            <ItemAgendaContainer
                                                onClick={() => item.id_register !== undefined ? cardOpen(item.id_register) : {}}
                                                key={item.id_register}
                                                color={colorItem}
                                            >
                                                <ItemAgendaHeader>
                                                    <ItemAgendaHeaderLeft color={colorItem}>
                                                        <IconPickerItem
                                                            icon={iconItem as IconList}
                                                            color={"white"}
                                                        />
                                                    </ItemAgendaHeaderLeft>
                                                    <ItemAgendaHeaderRight>
                                                        <ItemAgendaHeaderOrigin>
                                                            {item.origin}
                                                        </ItemAgendaHeaderOrigin>
                                                        <ItemAgendaHeaderTitle>
                                                            {item.title}
                                                        </ItemAgendaHeaderTitle>
                                                    </ItemAgendaHeaderRight>
                                                </ItemAgendaHeader>
                                                <ItemAgendaBottom>
                                                    <ItemAgendaBottomLeft>
                                                        {timeDue}
                                                    </ItemAgendaBottomLeft>
                                                </ItemAgendaBottom>
                                            </ItemAgendaContainer>
                                        </ItemRegisterContainer>
                                    )
                                })}

                                {tracksFiltered !== undefined && tracksFiltered.length > 0 && agendaView === 1 && tracksFiltered.filter((track) => track.isRunning !== "S").map((track) => {

                                    let timeStart: string = track.dt_start !== undefined ? format(
                                        new Date(track.dt_start),
                                        "HH:mm",
                                        { locale: pt }
                                    ) : "";

                                    let timeEnd: string = track.dt_end !== undefined ? format(
                                        new Date(track.dt_end),
                                        "HH:mm",
                                        { locale: pt }
                                    ) : "";

                                    const colorItem = track.user !== undefined && track.user.color !== undefined ? track.user.color : "#4681b8";

                                    return (
                                        <ItemRegisterContainer key={track.id_time_tracking} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <ItemAgendaContainer
                                                onClick={() => {
                                                    if (track.card_id !== undefined && track.card_id !== null && track.flow_id !== undefined && track.flow_id !== null) {
                                                        getCardApi(track.card_id, track.flow_id, true);
                                                    }
                                                }}
                                                key={track.id_time_tracking}
                                                color={colorItem}
                                            >
                                                <ItemAgendaHeader>
                                                    <ItemAgendaHeaderLeft color={colorItem}>
                                                        <IconPickerItem
                                                            icon={"FaPlayCircle" as IconList}
                                                            color={"white"}
                                                        />
                                                    </ItemAgendaHeaderLeft>
                                                    <ItemAgendaHeaderRight>
                                                        <ItemAgendaHeaderOrigin>
                                                            {track.flow?.name + " [" + track.flow_step?.name + "]"}
                                                        </ItemAgendaHeaderOrigin>
                                                        <ItemAgendaHeaderTitle>
                                                            {track.title}
                                                        </ItemAgendaHeaderTitle>
                                                    </ItemAgendaHeaderRight>
                                                </ItemAgendaHeader>
                                                <ItemAgendaBottom>
                                                    <ItemAgendaBottomLeft>
                                                        {timeStart !== "00:00" && timeEnd !== "00:00" ? timeStart + " - " + timeEnd : "Manual"}
                                                    </ItemAgendaBottomLeft>
                                                    {track.duration !== undefined && (
                                                        <ItemAgendaBottomRight style={{ color: colorItem }}>
                                                            {formatDuration(track.duration !== undefined ? track.duration : 0)}
                                                        </ItemAgendaBottomRight>
                                                    )}
                                                </ItemAgendaBottom>
                                            </ItemAgendaContainer>
                                        </ItemRegisterContainer>
                                    )
                                })}

                            </AgendaBody>
                        </AgendaContainer>
                    </ContentContainer>
                </WidgetContainer>
            </ContainerRight>
        </ContentBody >
    );

}

export default FocusTab;
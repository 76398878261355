import styled, { css } from "styled-components";

interface AutomationConfigItemIconProps {
    color: string;
}

interface AutomationConfigItemProps {
    color: string;
}

interface FilterComboContainerButtonProps {
    active?: boolean;
}

export const Container = styled.div`
    width: 100%;
`;

export const ContainerConfigRightHeader = styled.div`
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid #f4f4f4;    
    position: relative;
`;

export const ContainerConfigRightHeaderTitle = styled.div`

    span {
        color: gray;
        font-size: 12px;
    }
`;

export const ContainerConfigRightHeaderSearch = styled.div`
`;

export const ContainerCloseButton = styled.div`
    position: absolute;
    right: 10px;
    top: 20px;
`;

export const ButtonClose = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    width: 45px !important;
    height: 35px !important;
    margin-right: 15px !important;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
`;

export const AutomationConfigGroup = styled.div`
    padding: 15px;
    padding-bottom: 0px;
    width: 100%;
`;

export const AutomationConfigTitle = styled.div`
    font-size: 13px;
    color: #4d4d4d;
    margin-bottom: 2px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #f4f5f7;
    margin-bottom: 15px;
`;

export const AutomationConfigItems = styled.div`
    
`;

export const AutomationConfigItem = styled.div<AutomationConfigItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    :hover {
        background-color: #f23b5d1e;

        ${props => props.color && css`
            background-color: ${props.color}1e;
        `}
    }
`;

export const AutomationConfigItemIcon = styled.div<AutomationConfigItemIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 45px;
    min-height: 45px;
    background-color: #f23b5d;
    color: white;
    border-radius: 5px;
    font-size: 13px;

    div {
        display: flex;
        font-size: 16px !important;
    }

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const AutomationConfigItemDescription = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h3 {
        font-size: 14px;
        color: black;
        margin-bottom: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            background-color: #00bcd4;
            color: white;
            border-radius: 10px;
            margin-left: 5px;
            padding: 3px 7px;
            font-size: 10px;
        }
    }

    span {
        font-size: 12px;
        color: grey;
    }
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
        max-width: 75px;
    }
    
`;

export const DividerMenu = styled.div`
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
    margin: 20px 0;
`;

export const ContainerForm = styled.div`
    width: 100%;
    padding: 15px;
    padding-top: 0px;

    form {
        padding-top: 0px;
        padding-left: 5px;
        padding-right: 5px;

        .cange-form-title {
            font-size: 14px;
        }

        .cange-form-title-required {
            font-size: 14px;
        }

        .cange-form-description {
            font-size: 12px;
            color: gray;
            font-weight: 400;
        }

        .cange-form-error {
            font-size: 12px;
        }

        .cange-form-error-icon {
            font-size: 12px;
            height: 12px;
            width: 12px;
        }

        .cange-form-help-text {
            margin-top: 0px;
            font-size: 12px;
        }
    }
`;

export const ContainerFormLabel = styled.div`
    width: 100%;
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 0px;

    form {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;

        .cange-form-title {
            font-size: 14px;
        }

        .cange-form-title-required {
            font-size: 14px;
        }

        .cange-form-description {
            font-size: 12px;
            color: gray;
            font-weight: 400;
        }

        .cange-form-error {
            font-size: 12px;
        }

        .cange-form-error-icon {
            font-size: 12px;
            height: 12px;
            width: 12px;
        }

        .cange-form-help-text {
            margin-top: 0px;
            font-size: 12px;
        }
    }
`;

export const FilterComboContainer = styled.div`    
    padding: 10px;
    padding-top: 0px;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
`;

export const FilterComboContainerTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-bottom: 5px;
    margin-left: 5px;
`;

export const FilterComboContainerDescription = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: gray;
    margin-bottom: 5px;
    margin-left: 5px;
`;

export const FilterComboContainerButton = styled.button<FilterComboContainerButtonProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    margin-right: 10px;
    color: #f23b5c;
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
    margin-top: 10px;
    margin-left: 0px;

    svg {
        font-size: 15px; 
        margin-top: 1px;
        margin-right: 10px;
    }

    :hover {
      opacity: 0.8;
    }    
    

    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;
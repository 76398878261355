import {
    ButtonNewFormField,
    Container,
    ContainerButtonNewFormField,
    ContainerFieldBox,
    FieldBox
} from "./style";
import {
    FieldProps,
    getItemComponentColor,
    getItemComponentColorBackground,
    getItemComponentDescription,
    getItemComponentIcon
} from "../Forms/Fields/FieldBuilder";
import React, { useCallback } from "react";

import NewField from "../../dialogs/NewField";
import { Flow } from "../../interfaces/Flow";
import { Register } from "../../interfaces/Register";

interface defaultProps {
    fields: FieldProps[];
    open: boolean;
    selectedValue: FieldProps;
    stateDialog: string;
    origin: 'flow' | 'register';
    flow?: Flow;
    register?: Register;
    isFormInit?: boolean;
    handleMoveField: (type: string, index: number) => Promise<void>;
    handleEditField: (index: number) => Promise<void>;
    dialogNewFieldSubmit: (data: FieldProps) => Promise<void>;
    dialogNewFieldOpen: () => void;
    dialogNewFieldClose: (value: FieldProps) => void;
    dialogNewFieldDelete?: (name: string, id_field?: number) => Promise<void>;
}
//
const Fields: React.FC<defaultProps> = ({ fields, open, selectedValue, stateDialog, handleMoveField, handleEditField, dialogNewFieldSubmit, dialogNewFieldOpen, dialogNewFieldClose, dialogNewFieldDelete, origin, flow, register, isFormInit }) => {

    const sortfields = useCallback(async () => {
        function compare(a: FieldProps, b: FieldProps) {
            if (a.index !== undefined && b.index !== undefined) {
                if (a.index < b.index) {
                    return -1;
                }
                if (a.index > b.index) {
                    return 1;
                }
            }
            return 0;

        }

        fields.sort(compare);

    }, [fields]);

    const renderFields = useCallback(() => {

        sortfields();

        return fields.map((field) => {
            return (
                <ContainerFieldBox
                    key={field.index}
                >
                    <div></div>
                    <FieldBox
                        title={field.title}
                        field_type={getItemComponentDescription(field.type)}
                        index={field.index}
                        icon={getItemComponentIcon(field.type)}
                        color={getItemComponentColor(field.type)}
                        color_background={getItemComponentColorBackground(field.type)}
                        moveField={handleMoveField}
                        editField={handleEditField}
                    />
                </ContainerFieldBox>
            );
        });
    }, [fields, handleMoveField, sortfields, handleEditField]);

    return (
        <Container>
            {renderFields()}

            <ContainerButtonNewFormField>
                <ButtonNewFormField color="#2390eb" height="36px" margin="0px 30px 0px 0px" onClick={dialogNewFieldOpen}>
                    Criar novo campo
                </ButtonNewFormField>
                {open && (
                    <NewField
                        index={fields.length}
                        origin={origin}
                        selectedValue={selectedValue}
                        open={open}
                        onSubmmit={dialogNewFieldSubmit}
                        onClose={dialogNewFieldClose}
                        onDelete={dialogNewFieldDelete}
                        type={stateDialog}
                        formFields={fields}
                        flow={flow}
                        register={register}
                        isFormInit={isFormInit}
                    />
                )}
            </ContainerButtonNewFormField>
        </Container>
    );

};

export default Fields;
import React, { useEffect, useRef, useState } from "react";
import AvatarCange from "../../../components/AvatarCange";
import { Animation, ContainerLoader, ContainerRank, RankAvatar, RankItem, RankName, RankPoints, RankPosition, RankingChildren } from "./style";
import { User } from "../../../interfaces/User";
import lottie from "lottie-web";
import { TimeSheetDataTableProps } from "../getAggregateObject";

interface TimeSheetRankingChartProps {
    rankingData?: TimeSheetDataTableProps[];
    isLoading?: boolean;
}

const TimeSheetRankingChart: React.FC<TimeSheetRankingChartProps> = ({ rankingData, isLoading }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [usersToShow] = useState<number>(3);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const lottieContainer = useRef<HTMLDivElement>(null);

    const formatDuration = (timeInSeconds: number): string => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    useEffect(() => {

        if (isLoading !== undefined) {
            setLoading(isLoading);
        }

    }, [isLoading]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, [loading]);

    return (
        <>
            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <ContainerRank>
                    {rankingData?.slice(0, usersToShow).map((d: TimeSheetDataTableProps, index) => {

                        const isFirst = index === 0;

                        return (
                            <RankItem key={d.user?.id_user} isFirst={isFirst}>
                                <RankPosition>#{index + 1}</RankPosition>
                                <RankAvatar>
                                    <AvatarCange
                                        user={{
                                            id_user: d.user?.id_user,
                                            name: d.user?.name,
                                            color: d.user?.color,
                                            company_id: d.user?.company_id,
                                            email: d.user?.email,
                                            avatar_id: d.user?.avatar_id,
                                            avatar_url: d.user?.avatar_url

                                        } as User}
                                        size={"35"}
                                    />
                                </RankAvatar>
                                <RankName>{d.user?.name}</RankName>
                                <RankPoints>{d.duration !== undefined ? formatDuration(d.duration) : '-'}</RankPoints>
                            </RankItem>
                        )
                    })}
                    {rankingData !== undefined && rankingData.length > usersToShow && !collapsed ?
                        <RankingChildren onClick={() => setCollapsed(true)}>
                            Mostrar mais {rankingData?.length - usersToShow} {rankingData.length - usersToShow > 1 ? "usuários" : "usuário"}
                        </RankingChildren> : rankingData !== undefined && rankingData.length > usersToShow && collapsed ?
                            rankingData?.slice(usersToShow, rankingData.length).map((d, index) => {
                                return (
                                    <RankItem key={d.user?.id_user} isFirst={false}>
                                        <RankPosition>#{index + usersToShow + 1}</RankPosition>
                                        <RankAvatar>
                                            <AvatarCange
                                                user={{
                                                    id_user: d.user?.id_user,
                                                    name: d.user?.name,
                                                    color: d.user?.color,
                                                    company_id: d.user?.company_id,
                                                    email: d.user?.email,
                                                    avatar_id: d.user?.avatar_id,
                                                    avatar_url: d.user?.avatar_url

                                                } as User}
                                                size={"35"}
                                            />
                                        </RankAvatar>
                                        <RankName>{d.user?.name}</RankName>
                                        <RankPoints>{d.duration !== undefined ? formatDuration(d.duration) : '-'}</RankPoints>
                                    </RankItem>
                                )
                            }) :
                            <></>
                    }
                </ContainerRank>
            }
        </>
    );

}

export default TimeSheetRankingChart;
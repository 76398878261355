import styled, { css } from "styled-components";

interface BodyContainerProps {
    alignCenter?: boolean;
}

interface ColorProps {
    color?: string;
    colorBackground?: string;
    colorIcon?: string;
}

export const Container = styled.div`
    display: flex;
    width: 100%;    
`;

export const ContainerBox = styled.div<ColorProps>`
    display: flex;
    width: 100%;
    min-width: 220px;
    min-height: 50px;   
    border: 1px solid #f23b5ca8;
    border-radius: 5px;

    ${props => props.colorBackground && props.color && css`
        border: 1px solid ${props.color};

        :hover {
            background-color: ${props.color}1a;
        }
    `}


`;

export const LeftContainer = styled.div<ColorProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 70px;
    max-width: 120px !important;
    background-color: #f23b5c1a;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;  
    border-right: 1px solid #f23b5ca8;  

    svg {
        color: #f23b5c;
        width: 23px;
        height: 23px;
    }

    ${props => props.colorBackground && props.color && css`
        background-color: ${props.colorBackground};
        border-right: 1px solid ${props.color}; 

        svg {
            color: ${props.color};
        }
    `}

    ${props => props.colorIcon && css`

        svg {
            color: ${props.colorIcon};
        }
        
    `}
`;

export const BodyContainer = styled.div<BodyContainerProps>`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    margin-left: 25px;
    margin-top: -2px;

    h1 {
        font-size: 16px;
        color: black; //#f23b5c;
        font-weight: 500;        

        span {
            margin-left: 10px;
            background-color: #f23b5d;
            color: white;
            font-size: 10px;
            text-transform: uppercase;
            padding: 2px 5px;
            border-radius: 5px;
            font-weight: 500;
        }
    }

    h2 {
        font-size: 14px;
        color: gray;
    }

    ${props => props.alignCenter && css`
        text-align: center !important;
        margin-left: 0px !important;

        h1 {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `}

`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    width: auto;
    min-width: 35px;
    left: 0;

    p {
        font-size: 12px;
        font-weight: 400px;
        color: gray;
    }
`;

export const ContainerOrder = styled.div`
    display: table-cell;
    height: 50px;
    margin-top: 14px;
    margin-left: 5px;
`;

export const ButtonUp = styled.button`
    display: flex;
    border: none;
    background-color: transparent;
    height: 20px;
    width: 20px;
    margin-bottom: 3px;

    svg {
        height: 20px;
        width: 20px;
    }
`;

export const ButtonDown = styled.button`
    display: flex;
    border: none;
    background-color: transparent;
    height: 20px;
    width: 20px;

    svg {
        height: 20px;
        width: 20px;
    }
`;


import {
    BtnGoToPage,
    ButtonUserAdd,
    ContainerBody,
    ContainerBodyWelcome,
    ContainerContent,
    ContainerHeader,
    ContainerHeaderRight,
    ContainerWelcome,
    ContainerFocus,
    ContainerFocusDisplay
} from "./styles";
import React, { useCallback, useEffect, useState } from 'react';

import Description from "../../components/Description";
import { FiHome, FiTarget } from 'react-icons/fi';
import { IconList } from 'react-fa-icon-picker'
import InviteUser from '../../dialogs/InviteUser';
import { IoMdPersonAdd } from 'react-icons/io';
import LayoutContainer from '../../components/LayoutContainer';
import ListContainer from '../../components/ListContainer';
import MyFlows from '../../components/Widget/MyFlows';
import MyTasks from '../../components/Widget/MyTasks';
import MyTeam from '../../components/Widget/MyTeam';
import MyTemplate from '../../components/Widget/MyTemplates';
import PageHeader from '../../components/PageHeader';
import StatsUser from '../../components/Stats/StatsUser';
import Title from "../../components/Title";
import { format } from 'date-fns';
import getAccessControl from '../../middlewares/AccessControl';
import pt from 'date-fns/locale/pt-BR';
import { useAuth } from '../../hooks/auth';
import { Link, useHistory } from 'react-router-dom';
import MyWorkspaces from "../../components/Widget/MyWorkspaces";
import StartTrial from "../../dialogs/StartTrial";
import WelcomePaid from "../../dialogs/WelcomePaid";
import EndTrial from "../../dialogs/EndTrial";
import ChoosePlan from "../../dialogs/Billing/ChoosePlan";
import MyRequests from "../../components/Widget/MyRequests";

interface TasksFlowSectionProps {
    typeUser: string,
    handleGoToMyTasks: () => void,
    handleGoToMyFlows: () => void
}

interface FlowSectionProps {
    typeUser: string,
}

interface MyTeamSectionProps {
    handleAddNewUser: () => Promise<void>,
    typeUser: string
}

interface TemplatesSectionProps {
    handleGoToTemplateStore: () => void
}

const WorkspacesSection: React.FC = () => {

    return (
        <ContainerBody>
            <Link to={'/task-list/focus'} style={{ textDecoration: 'none' }}>
                <ContainerFocus>
                    <FiTarget />
                    <ContainerFocusDisplay className="container-focus-display">
                        MEU FOCO
                    </ContainerFocusDisplay>
                </ContainerFocus>
            </Link>

            {/* My Workspaces */}
            <ContainerContent style={{ padding: '0px' }}>
                <MyWorkspaces />
            </ContainerContent>
        </ContainerBody>
    );
}

const TasksRequestSection: React.FC<TasksFlowSectionProps> = ({ typeUser, handleGoToMyTasks }) => {

    return (
        <ContainerBody>
            {/* My Requests */}
            <ContainerContent style={{ maxHeight: "305px", overflow: "auto", minHeight: "305px", marginRight: "15px" }}>
                <ContainerHeader>
                    <Title
                        title="Minhas Solicitações"
                        required={false}
                        font_size="18px"
                    />
                    <ContainerHeaderRight style={{ width: 'calc(100% - 170px)' }}>
                        {getAccessControl(87, typeUser) && (
                            <BtnGoToPage onClick={() => handleGoToMyTasks()}>Ir para lá</BtnGoToPage>
                        )}
                    </ContainerHeaderRight>
                </ContainerHeader>
                <MyRequests />
            </ContainerContent>
            {/* My Tasks */}
            <ContainerContent style={{ maxHeight: "305px", overflow: "auto", minHeight: "305px", marginRight: "15px" }}>
                <ContainerHeader>
                    <Title
                        title="Minhas Tarefas"
                        required={false}
                        font_size="18px"
                    />
                    <ContainerHeaderRight style={{ width: 'calc(100% - 130px)' }}>
                        {getAccessControl(87, typeUser) && (
                            <BtnGoToPage onClick={() => handleGoToMyTasks()}>Ir para lá</BtnGoToPage>
                        )}
                    </ContainerHeaderRight>
                </ContainerHeader>
                <MyTasks />
            </ContainerContent>
        </ContainerBody>
    );
}

const TasksFlowSection: React.FC<TasksFlowSectionProps> = ({ typeUser, handleGoToMyTasks, handleGoToMyFlows }) => {

    return (
        <ContainerBody>
            {/* My Tasks */}
            <ContainerContent style={{ maxHeight: "305px", overflow: "auto", minHeight: "305px", marginRight: "15px" }}>
                <ContainerHeader>
                    <Title
                        title="Minhas Tarefas"
                        required={false}
                        font_size="18px"
                    />
                    <ContainerHeaderRight style={{ width: 'calc(100% - 130px)' }}>
                        {getAccessControl(87, typeUser) && (
                            <BtnGoToPage onClick={() => handleGoToMyTasks()}>Ir para lá</BtnGoToPage>
                        )}
                    </ContainerHeaderRight>
                </ContainerHeader>
                <MyTasks />
            </ContainerContent>
            {/* My Flows */}
            <ContainerContent style={{ maxHeight: "305px", overflow: "auto", minHeight: "305px" }}>
                <ContainerHeader>
                    <Title
                        title="Meus Fluxos"
                        required={false}
                        font_size="18px"
                    />
                    <ContainerHeaderRight>
                        <BtnGoToPage onClick={() => handleGoToMyFlows()}>Ir para lá</BtnGoToPage>
                    </ContainerHeaderRight>
                </ContainerHeader>
                <MyFlows typeUser={typeUser} />
            </ContainerContent>
        </ContainerBody>
    );
}

const FlowSection: React.FC<FlowSectionProps> = ({ typeUser }) => {

    return (
        <ContainerBody>
            {/* My Flows */}
            <ContainerContent style={{ maxHeight: "305px", overflow: "auto", minHeight: "305px" }}>
                <ContainerHeader style={{ justifyContent: 'flex-start' }}>
                    <Title
                        title="Fluxos"
                        required={false}
                        font_size="18px"
                    />
                    <ContainerHeaderRight>
                        .
                    </ContainerHeaderRight>
                </ContainerHeader>
                <MyFlows typeUser={typeUser} columns={3} />
            </ContainerContent>
        </ContainerBody>
    );
}

const MyTeamSection: React.FC<MyTeamSectionProps> = ({ handleAddNewUser, typeUser }) => {

    return (
        <ContainerBody>
            <ContainerContent>
                <Title
                    title="Meu time"
                    required={false}
                    font_size="18px"
                />
                <MyTeam handleAddNewUser={handleAddNewUser} typeUser={typeUser} />
            </ContainerContent>
        </ContainerBody>
    )
}

const TemplatesSection: React.FC<TemplatesSectionProps> = ({ handleGoToTemplateStore }) => {

    return (
        <ContainerBody style={{ marginBottom: "15px", border: "2px solid #f23c5d", borderRadius: "10px" }}>
            <ContainerContent>
                <ContainerHeader>
                    <Title
                        title="Templates sugeridos para você"
                        required={false}
                        font_size="18px"
                    />
                    <ContainerHeaderRight style={{ width: 'calc(100% - 260px)' }}>
                        <BtnGoToPage onClick={() => handleGoToTemplateStore()}>Ver todos templates</BtnGoToPage>
                    </ContainerHeaderRight>
                </ContainerHeader>
                <MyTemplate />
            </ContainerContent>
        </ContainerBody>
    );
}

const Dashboard: React.FC = () => {

    const [dtWelcome, setDtWelcome] = useState<string>();
    const [openInviteUser, setOpenInviteUser] = useState(false);
    const [openStartTrial, setOpenStartTrial] = useState<boolean>(false);
    const [openEndTrial, setOpenEndTrial] = useState<boolean>(false);
    const [openBilling, setOpenBilling] = useState(false);
    const [openWelcomePaid, setOpenWelcomePaid] = useState<boolean>(false);
    const [menuLayout, setMenuLayout] = useState<'default' | 'trial'>('default');

    const { user, renewUser } = useAuth();
    const history = useHistory();

    const reloadPage = useCallback(() => {
        window.location.reload();
    }, []);

    const handleCloseEndTrial = useCallback(() => {

        setOpenEndTrial(false);

        history.replace('/dashboard');

        setOpenBilling(true);

    }, [history]);

    const handleGoToMyFlows = useCallback(async () => {

        history.push('/flow-list');

    }, [history]);

    const handleGoToMyTasks = useCallback(async (tab?: string) => {

        if (tab !== undefined) {
            history.push('/task-list/' + tab);
        } else {
            history.push('/task-list');
        }


    }, [history]);

    const handleGoToTemplateStore = useCallback(async () => {

        history.push('/template-store');

    }, [history]);

    const handleAddNewUser = useCallback(async () => {

        setOpenInviteUser(!openInviteUser)

    }, [openInviteUser]);

    useEffect(() => {

        const dtToday = new Date();

        const dtWelcome = format(
            dtToday,
            "eeee',' dd 'de' MMMM",
            { locale: pt }
        );

        setDtWelcome(dtWelcome.substring(0, 1).toUpperCase() + dtWelcome.substring(1));

    }, []);

    useEffect(() => {

        let isStartTrial = false;
        let isNewPlan = false;

        //Valid if there is any plan
        if (user !== undefined && user.company !== undefined && user.company.company_plan !== undefined && user.company.company_plan.length > 0) {
            if (user.company.company_plan[0].plan_id === 6 || user.company.company_plan[0].plan_id === 1) {
                setMenuLayout('trial');
            }
        }

        //Valid the query url checking if ?action=start-trial
        const action = new URLSearchParams(window.location.search).get('action');
        if (action !== undefined && action !== null && user !== undefined) {
            if (action === "start-trial" && !user.isStartedTrial) {
                isStartTrial = true;
            } else if (action === "stripe-paid") {
                isNewPlan = true;
            } else if (action === "trial-ended") {
                history.replace('/dashboard');
                setOpenEndTrial(true);
            }
        }

        //If isStartTrial is true, open the dialog
        if (isStartTrial) {
            setOpenStartTrial(true);
        }

        //If isNewPlan is true, update the objects
        if (isNewPlan) {
            setOpenWelcomePaid(true);
        }

    }, [user, renewUser, history]);

    return (
        <LayoutContainer>
            <title>Página Inicial | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <PageHeader
                    title={"Página Inicial"}
                    icon={FiHome}
                    iconDynamic={"FaHome" as IconList}
                    iconDynamicColor={"gray"}
                >

                    {/* Usuários */}
                    {getAccessControl(1, user.type) ?
                        <>
                            <ButtonUserAdd
                                icon={IoMdPersonAdd}
                                type="button"
                                color="#4680B8"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                onClick={() => setOpenInviteUser(!openInviteUser)}
                            >
                                Convidar
                            </ButtonUserAdd>
                            {openInviteUser ?
                                <InviteUser
                                    key="invite-user-dashboard"
                                    open={openInviteUser}
                                    onClose={() => setOpenInviteUser(false)}
                                    typeUser={user.type}
                                    fullScreen={true}
                                    title="Convide membros para colaborar"
                                /> :
                                <></>
                            }
                        </> : <></>
                    }
                </PageHeader>

                <StartTrial
                    open={openStartTrial}
                    onClose={() => setOpenStartTrial(false)}
                />

                <EndTrial
                    open={openEndTrial}
                    onClose={() => handleCloseEndTrial()}
                />

                <ChoosePlan
                    open={openBilling}
                    onClose={() => {
                        renewUser();
                        setOpenBilling(false);
                        reloadPage();
                    }}
                    isEndTrial={true}
                />

                <WelcomePaid
                    open={openWelcomePaid}
                    onClose={() => setOpenWelcomePaid(false)}
                />

                {/* Welcome Page */}
                <ContainerBodyWelcome>
                    <ContainerWelcome>
                        <Title
                            title={"Olá, " + user.name + "!"}
                            required={false}
                            font_size={"28px"}
                            justify_content="center"
                        />
                        <Description>{dtWelcome}</Description>
                    </ContainerWelcome>
                </ContainerBodyWelcome>

                {menuLayout === 'trial' ?
                    <>
                        <StatsUser filter="dashboard" />
                        <TasksFlowSection typeUser={user.type} handleGoToMyFlows={handleGoToMyFlows} handleGoToMyTasks={handleGoToMyTasks} />
                        <TemplatesSection handleGoToTemplateStore={handleGoToTemplateStore} />
                        <MyTeamSection typeUser={user.type} handleAddNewUser={handleAddNewUser} />
                    </> : user.type === "S" ?
                        <>
                            <TasksRequestSection typeUser={user.type} handleGoToMyFlows={handleGoToMyFlows} handleGoToMyTasks={handleGoToMyTasks} />
                            <FlowSection typeUser={user.type} />
                        </> : user.type === "M" || user.type === "V" ?
                            <>
                                <WorkspacesSection />
                                <TasksFlowSection typeUser={user.type} handleGoToMyFlows={handleGoToMyFlows} handleGoToMyTasks={handleGoToMyTasks} />
                                <StatsUser filter="dashboard" />
                                <TemplatesSection handleGoToTemplateStore={handleGoToTemplateStore} />
                            </> :
                            <>
                                <WorkspacesSection />
                                <TasksFlowSection typeUser={user.type} handleGoToMyFlows={handleGoToMyFlows} handleGoToMyTasks={handleGoToMyTasks} />
                                <StatsUser filter="dashboard" />
                                <MyTeamSection typeUser={user.type} handleAddNewUser={handleAddNewUser} />
                                <TemplatesSection handleGoToTemplateStore={handleGoToTemplateStore} />
                            </>
                }

                <div style={{ marginBottom: "15px", color: "transparent" }}>.</div>

            </ListContainer>
        </LayoutContainer>
    );
};

export default Dashboard;
import {
    Animation,
    Container,
    ContainerLoader,
    FlowContainer,
    FlowIcon,
    TaskAlert,
    TitleContainer
} from "./style";
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Card } from "../../../interfaces/Card";
import DataList from '../../../components/DataList';
import EmptyTask from '../../EmptyState/EmptyTask';
import { FaMailBulk } from 'react-icons/fa';
import api from '../../../services/api';
import lottie from 'lottie-web';
import { useToast } from '../../../hooks/toast';

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            display: 'none',
        },
    },
    headCells: {
        style: {
            display: 'none',
            color: 'black',
            fontSize: '17px',
            fontWeight: 500
        },
    },
    rows: {
        marginTop: '1px',
        highlightOnHoverStyle: {
            backgroundColor: '#f4f4f4',
            borderBottomColor: '#FFFFFF',
            borderRadius: '10px',
            outline: '1px solid white',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const columns: IDataTableColumn[] = [
    {
        name: "",
        cell: (row) => {
            return (
                <Link to={"/flow/" + row.flow.hash + "/card/" + row.id_card} style={{ textDecoration: 'none' }}>
                    <FlowContainer>
                        <FlowIcon color={row.flow.color}>

                            {row.flow.icon !== undefined ?
                                <IconPickerItem
                                    icon={row.flow.icon as IconList}
                                    color={row.flow.color}
                                /> :
                                <FaMailBulk />
                            }

                        </FlowIcon>
                    </FlowContainer>
                </Link>
            )
        },
        allowOverflow: true,
        center: true,
        button: true,
        width: '40px',
    },
    {
        name: "",
        sortable: true,
        allowOverflow: true,
        cell: (row) => {
            return (
                <Link to={"/flow/" + row.flow.hash + "/card/" + row.id_card} style={{ textDecoration: 'none', overflow: "auto" }}>
                    <TitleContainer>
                        <h3 style={{ width: '100%' }}>{row.title}</h3>
                        <span>{row.flow.name}   [{row.flow_step.name}]</span>
                    </TitleContainer>
                </Link>
            )
        },
        center: false
    },
    {
        name: "",
        cell: (row) => {
            return (
                <Link to={"/flow/" + row.flow.hash + "/card/" + row.id_card} style={{ textDecoration: 'none' }}>
                    {row.dt_due_card_string !== undefined && row.dt_due_card_string !== "" ?
                        <TaskAlert color={row.dt_due_color !== undefined ? row.dt_due_color : "#4681b8"}>{row.dt_due_card_string}</TaskAlert> :
                        <></>
                    }
                </Link>
            )
        },
        center: false,
        maxWidth: '210px'
    }
];

const MyRequests: React.FC = () => {

    const [cards, setCards] = useState<Card[]>([]);
    const { addToast } = useToast();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const lottieContainer = useRef<HTMLDivElement>(null);

    const [subHeaderAlign] = React.useState('right');
    const [noHeader] = React.useState(true);
    const [fixedHeaderScrollHeight] = React.useState('230px');

    const goToFlow = useCallback(async (row: Card) => {

        if (row.flow !== undefined) {
            history.push('/flow/' + row.flow.hash + "/card/" + row.id_card);
        }

    }, [history]);

    useEffect(() => {

        setLoading(true);

        api.get(`/card/my-requests`, {
        }).then(response => {
            if (response.data !== null) {

                let cardsApi: Card[] = response.data;

                //Order by status_dt_due ASC
                cardsApi = cardsApi.filter((c) => c.flow !== undefined).sort(function (a, b) {
                    return Number(a.status_dt_due) - Number(b.status_dt_due);
                });

                if (cardsApi !== undefined) {
                    setCards(cardsApi);
                }

            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addToast({
                type: 'error',
                title: 'Erro ao atualizar o objeto',
                description: 'Ocorreu um erro ao tentar atualizar o objeto!',
            });
        });

    }, [addToast]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, []);

    const onRowClicked = (row: Card) => { goToFlow(row); };

    return (
        <>
            {loading ?
                <ContainerLoader>
                    < Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <Container>
                    {/* Data List */}
                    <DataList>
                        <DataTable
                            columns={columns}
                            data={cards}
                            subHeaderAlign={subHeaderAlign}
                            noHeader={noHeader}
                            customStyles={customStyles}
                            fixedHeaderScrollHeight={fixedHeaderScrollHeight}
                            fixedHeader={true}
                            onRowClicked={onRowClicked}
                            highlightOnHover
                            noDataComponent={<EmptyTask />}
                            pointerOnHover
                        />
                    </DataList>
                </Container>
            }
        </>
    );

}

export default MyRequests;
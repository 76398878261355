import styled from "styled-components";

export const IframeComponent = styled.iframe`

    html {
        font-size: '13px';
        font-family: 'Roboto, sans-serif';
    }

`;

import Grid from '@material-ui/core/Grid';
import styled from "styled-components";

import Button from "../../../components/Button";
import FormBuilder from "../../../components/Forms/FormBuilder";

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
`;

export const Content = styled(Grid)`
    display: flex;
    justify-content: center;
    margin-top: 15px !important;
    border-radius: 10px;
    height: auto;
`;

export const ContainerFields = styled(Grid)`
    background-color: white;
    border-radius: 10px 10px 10px 10px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 1%;
    padding-bottom: 2%;
    display: flex;
    justify-content: center;
    min-height: 66.5vh;
`;

export const ContentList = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
`;

export const BreakArea = styled.div`
    margin-bottom: 30px !important;
`;

/* Container Steps */
export const StepContainer = styled.div`
    display: flex;
    align-items: left;

    height: 130px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 15px;

    overflow-x: auto;
    
`;

export const StepItemNew = styled.button`
    display: inline-flex;
    align-items: left;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 15px;
    height: auto;
    width: 140px;
    min-width: 120px;
    background-color: white;
    border: 1px dashed #f23b5c;
    border-radius: 10px;
    padding: 15px;

    &:hover {
        background: ${'#f23b5c1a'};
        
        h2 {
            color: ${"black"};
        }
    } 

    h2 {
        font-size: 14px;
        color: #4d4d4d;
    }
`;

export const StepItemNewContainer = styled.div`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    width: 50px;
    height: 50px;
    margin-left: 20%;
    margin-right: 20%;

    background-color: #f23b5c;
    border-radius: 10px;

    svg {
        color: white;
        height: 20px;
        margin-top: 5px;
    }

`;

export const StepNewContainerBottom = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    white-space: normal;
    overflow-wrap: anywhere;

`;

export const ContainerPreview = styled(Grid)`
    padding-left: 2%;
    padding-bottom: 0%;
    padding-right: 0;
    background-color: transparent;
    height: auto;
`;

export const FormField = styled(FormBuilder)`

`;

export const ContainerRightButton = styled.div`
    display: flex;
    align-items: unset;
    justify-content: right;
    margin-left: auto;

    button {
        
        width: 160px;
    }
`;

export const FieldsContainer = styled.div`
    margin-left: 10px;
`;

export const FormPreview = styled(FormBuilder)`
    
`;

export const ButtonNextStep = styled(Button)`
    float: right;
    width: 130px;
`;
import styled, { css } from "styled-components";
import { Box } from "@material-ui/core";

interface ItemProps {
    active?: boolean;
    color?: string;
}

export const BoxContainer = styled(Box)`
    position: fixed;
    bottom: 28px;
    z-index: 1;
    width: auto;
    min-width: 300px;
    height: auto;
    left: 65px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #f4f5f7;
    padding-right: 35px;
`;

export const HeaderLeft = styled.div`
    margin-right: 15px;
    margin-left: 15px;

    img {
        height: 30px
    }
`;

export const HeaderRight = styled.div`
    width: 100%;
    text-align: center; 

    h1 {
        font-size: 16px;
        font-weight: 400;
        color: #323338;
    }

    span {
        font-size: 13px;
        color: grey;
    }
`;

export const Body = styled.div`
   padding: 5px 15px 5px 15px;
`;

export const TitleSection = styled.div`
    color: gray;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
`;

export const Item = styled.div<ItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    padding-left: 15px;
    border-radius: 5px;

    :hover {
        background-color: #f3e7fe;
    }

    span {
        font-size: 15px;
        color: #323338;
    }

    ${(props) => props.color && css`
        :hover {
            background-color: ${props.color + "1e"};

            span {
                font-weight: 500;
            }
        }
    `}

    ${(props) => props.color && props.active && css`
        background-color: ${props.color + "1e"};
        span {
            font-weight: 500;
        }
    `}
`;

export const ItemIcon = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;

    svg {
        height: 20px;
        width: 20px;
        color: #323338;
    }
`;

export const Divider = styled.div`
    border-bottom: 1px solid #f4f5f7;
    margin-bottom: 10px;
    margin-top: 10px;
`;
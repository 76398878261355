import React, { useEffect } from "react";

import {
    Container,
    ImageViewer
} from "./style";

interface FilePreviewProps {
    file: { url: string, fileType: string, name: string };
}

const FilePreview: React.FC<FilePreviewProps> = ({ file }) => {

    const [visible, setVisible] = React.useState(true);

    const getViewerByFileType = (fileExtension: string) => {

        switch (fileExtension) {
            case "application/pdf":
                return 'pdfViwer';
            case "image/jpg":
            case "image/jpeg":
            case "image/png":
            case "image/gif":
                return 'imageViewer';
            default:
                return null;
        }
    };

    const renderFilePreview = (url: string) => {
        const viewerName = getViewerByFileType(file.fileType);

        if (viewerName) {
            if (viewerName === 'pdfViwer') {

                return (<></>);

            } else if (viewerName === 'imageViewer') {
                return (
                    <div>
                        <ImageViewer
                            visible={visible}
                            onClose={() => { setVisible(false); }}
                            images={[{ src: url, alt: file.name }]}
                            zIndex={99999999999999999999999999}
                            className="image-viewer"
                            rotatable={false}
                            scalable={false}
                            noImgDetails={false}
                            showTotal={false}
                            maxScale={3}
                            minScale={0.5}
                            noNavbar={true}
                            noFooter={true}
                        />
                    </div>
                )
            }
        }

        return <div>Formato de arquivo não suportado</div>;
    };

    useEffect(() => {
        setVisible(true);
    }, [file]);

    return (
        <Container className="preview-attach-cange">
            {renderFilePreview(file.url)}
        </Container>
    );

}

export default FilePreview;
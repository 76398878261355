import React from "react";

import { DescriptionContainer } from "./styles";

interface DescriptionProps {
    children?: React.ReactNode;
}

const Description: React.FC<DescriptionProps> = ({ children }) => {
    return (
        <DescriptionContainer className="cange-form-description">
            {children}
        </DescriptionContainer>
    )
};

export default Description;
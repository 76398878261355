import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import {
    BtnEditForm,
    BtnInsertNewAnswer,
    Content,
    ContentBody,
    DialogContainer,
    DialogFooter,
    DialogFooterCenter,
    DialogTop,
    DialogTopCenter,
    DialogTopLeft,
    DialogTopRight,
    HelpContainer,
    HelpIcon,
    HelpItem,
    HelpItems,
    HelpText,
    IconHeader
} from "./styles";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";

import ContentLoader from "react-content-loader";
import EmptyField from "../../components/EmptyState/EmptyField";
import { FaLink, FaPen } from "react-icons/fa";
import { FieldProps } from "../../components/Forms/Fields/FieldBuilder";
import { Flow } from '../../interfaces/Flow';
import { FormAnswerApi } from '../../interfaces/FormAnswerApi';
import { FormAnswerFieldApi } from "../../interfaces/FormAnswerFieldApi";
import FormBuilder from "../../components/Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import { IoMdListBox } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Register } from "../../interfaces/Register";
import api from '../../services/api';
import getAccessControl from "../../middlewares/AccessControl";
import { useToast } from '../../hooks/toast';
import formAnswerToObjectFormInit from "../../utils/formAnswerToObjectFormInit";
import { FormAnswerField } from "../../interfaces/FormAnswerField";
import { FormAnswer } from "../../interfaces/FormAnswer";
import getAutoCompleteRule from "../../utils/getAutoCompleteRule";
import { useAuth } from "../../hooks/auth";
import FormPublicConfig from "../FormPublicConfig";

interface objectInit {
    [x: string]: string | object
}

export interface NewFieldDialogProps {
    open: boolean;
    typeUser: string;
    flow?: Flow | undefined;
    register?: Register | undefined;
    testModel?: boolean;
    onSubmmit: () => Promise<void>;
    onClose: (updateData: boolean, id_card?: number) => Promise<void>;
}

const LoaderContainer = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'350px'}
        viewBox="0 0 700 350"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="350" />
    </ContentLoader>
)

const NewAnswer: React.FC<NewFieldDialogProps> = ({ onSubmmit, onClose, open, flow, register, testModel, typeUser }) => {

    const formRef = useRef<FormHandles>(null);
    const [fields, setFields] = useState<FieldProps[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
    const [openFormPublicConfig, setOpenFormPublicConfig] = useState(false);
    const [firstOpen, setFirstOpen] = useState<boolean>(true);
    const [initValue, setInitValue] = useState({});
    const { addToast } = useToast();

    const { user } = useAuth();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const loadFormApi = useCallback(async (formId: number, forceSetData?: boolean) => {

        try {

            const responseApi = await api.get(`/field/by-form`, {
                params: {
                    form_id: formId
                }
            });

            if (responseApi !== undefined && responseApi.data !== null) {

                const fieldsApi: FieldProps[] = responseApi.data;

                const formAnswerFields: FormAnswerField[] = await getAutoCompleteRule('answer', fieldsApi, user.id_user);

                const newObjData: objectInit = formAnswerToObjectFormInit(formAnswerFields);

                setInitValue(newObjData);

                //When the form already is mounted
                if (forceSetData) {
                    formRef.current?.setData(newObjData);
                }

                setFields(fieldsApi);

            }

            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
            addToast({
                type: 'error',
                title: 'Erro ao carregar o formulário',
                description: 'Ocorreu ao tentar carregar o formulário!',
            });
        }

    }, [addToast, user.id_user]);

    const updateFields = useCallback(async () => {

        const formId = flow !== undefined ? flow.form_init_id : register !== undefined ? register.form_id : undefined;

        if (formId !== undefined) {
            loadFormApi(formId, true);
        }

    }, [flow, loadFormApi, register]);

    const handleAddAnswer = useCallback(async () => {

        if (formRef.current !== null) {
            await formRef.current.submitForm();
        }

    }, []);

    const handleSubmit = useCallback(async (data: object[]) => {

        //Variables
        const dataNormalized = data as unknown as FormAnswerFieldApi[];
        let objApi: FormAnswerApi | undefined = undefined;
        let nameAdd: string;

        if (flow !== undefined && flow.form_init_id !== undefined) { //If is a Flow

            objApi = {
                id_form: flow?.form_init_id,
                origin: "/Flow/NewAnswer",
                values: dataNormalized,
                flow_id: flow?.id_flow,
                deleted: testModel !== undefined && testModel ? "T" : undefined
            }

            nameAdd = flow?.button_add_name !== undefined &&
                flow?.button_add_name !== null &&
                flow?.button_add_name !== "" ? flow?.button_add_name : "Novo Item"

        } else if (register !== undefined && register.form_id !== undefined) { //If is a Register

            objApi = {
                id_form: register?.form_id,
                origin: "/Register/NewAnswer",
                values: dataNormalized,
                register_id: register?.id_register
            }

            nameAdd = register?.button_add_name !== undefined &&
                register?.button_add_name !== null &&
                register?.button_add_name !== "" ? register?.button_add_name : "Novo Registro"

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao inserir um novo item [2]',
                description: 'Ocorreu um erro ao inserir o registro!',
            });
        }

        if (objApi !== undefined) {

            setLoadingInsert(true);
            await api
                .post('/form/new-answer', objApi)
                .then(response => {

                    const newFormAnswer: FormAnswer = response.data;

                    if (newFormAnswer !== undefined && newFormAnswer.card_id !== undefined) {
                        onClose(true, newFormAnswer.card_id);
                    } else {
                        onClose(true);
                    }

                    addToast({
                        type: 'success',
                        title: 'Inserido com sucesso o(a) ' + nameAdd,
                        description: 'Congrats! Você inseriu um ' + nameAdd + ' :)',
                    });

                    if (formRef.current !== null) {
                        formRef.current.reset();
                    }
                    setLoadingInsert(false);
                }).catch(error => {
                    setLoadingInsert(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir um novo item [1]',
                        description: 'Ocorreu um erro ao inserir o registro!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao inserir um novo item [3]',
                description: 'Ocorreu um erro ao inserir o registro!',
            });
        }

    }, [flow, register, addToast, onClose, testModel]);

    const handleClose = useCallback(async (event: {}, reason: "backdropClick" | "escapeKeyDown") => {

        if (reason !== "backdropClick") {
            onClose(false);
        }

    }, [onClose]);

    useEffect(() => {

        const formId = flow !== undefined ? flow.form_init_id : register !== undefined ? register.form_id : undefined;

        if (formId !== undefined && (open || firstOpen)) {
            setLoading(true);

            loadFormApi(formId);

            setFirstOpen(false)
        }


    }, [flow, register, open, firstOpen, loadFormApi]);

    return (
        <DialogContainer
            id="new-answer-dialog"
            fullWidth={true}
            maxWidth="sm"
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            style={{ zIndex: '99999' }}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader>
                        <div>
                            {flow !== undefined && flow.icon !== undefined ? //Flow
                                <IconPickerItem
                                    icon={flow.icon as IconList}
                                    color={flow.color}
                                /> :
                                register !== undefined && register.icon !== undefined ? //Register
                                    <IconPickerItem
                                        icon={register.icon as IconList}
                                        color={register.color}
                                    /> :
                                    <IoMdListBox />
                            }
                        </div>
                    </IconHeader>
                    <h1>
                        {flow !== undefined ? flow.name : register !== undefined ? register.name : "Formulário Inicial"}
                    </h1>
                </DialogTopLeft>


                <DialogTopCenter>
                    {getAccessControl(32, typeUser) ?
                        flow !== undefined ?
                            <>
                                <BtnEditForm
                                    style={{ backgroundColor: '#e9f1ff', marginRight: '10px' }}
                                    onClick={() => {
                                        setOpenFormPublicConfig(true);
                                    }}
                                >
                                    <FaLink />
                                    <h3>
                                        Compartilhar
                                    </h3>
                                </BtnEditForm>
                                <Link to={'/flow/2/' + flow.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                    <BtnEditForm>
                                        <FaPen />
                                        <h3>
                                            Editar Formulário
                                        </h3>
                                    </BtnEditForm>
                                </Link>
                            </>
                            : register !== undefined ?
                                <Link to={'/register/2/' + register.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                    <BtnEditForm>
                                        <FaPen />
                                        <h3>
                                            Editar Formulário
                                        </h3>
                                    </BtnEditForm>
                                </Link> :
                                <></> : <></>
                    }

                    {flow !== undefined && flow.form_init_id !== undefined && openFormPublicConfig && (
                        <FormPublicConfig
                            open={openFormPublicConfig}
                            onClose={() => setOpenFormPublicConfig(false)}
                            flow={flow}
                            form_id={flow.form_init_id}
                        />
                    )}
                </DialogTopCenter>


                <DialogTopRight>
                    <button onClick={() => onClose(false)}><AiOutlineClose></AiOutlineClose></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    {loading ?
                        <LoaderContainer />
                        : <></>
                    }

                    {fields !== undefined && fields?.length > 0 ?
                        <>
                            {testModel ? (
                                <HelpContainer color="#fe8c2d1e">
                                    <HelpItems>
                                        <HelpItem>
                                            <HelpIcon color="#fe8c2d">
                                                <IoWarningOutline />
                                            </HelpIcon>
                                            <HelpText>
                                                {"Você está inserindo um novo item em"}<b>{" MODO TESTE"}</b>{"! Esta opção está disponível para testar quando quiser o seu fluxo. Para desativar clique no interruptor 'Modo Teste' no cabeçalho do flow ;)"}
                                            </HelpText>
                                        </HelpItem>
                                    </HelpItems>
                                </HelpContainer>
                            ) : null}

                            {!loading && fields !== undefined ?
                                <FormBuilder
                                    id="formNewAnswer"
                                    formRef={formRef}
                                    fields={fields}
                                    handleSubmit={handleSubmit}
                                    hideContainer={fullScreen}
                                    initialValue={initValue}
                                    flow_id={flow?.id_flow}
                                    register_id={register?.id_register}
                                    hideAutoComplete={register?.id_register !== undefined ? true : false}
                                    isNewAnswer={true}
                                    typeUser={typeUser}
                                    updateFields={updateFields}
                                    activeHiddenFields={true}
                                    setFields={setFields}
                                />
                                : <></>
                            }
                        </> :
                        <>
                            {getAccessControl(32, typeUser) ?
                                <>
                                    {!loading && flow !== undefined ?
                                        <Link to={'/flow/2/' + flow.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                            <EmptyField />
                                        </Link>
                                        : !loading && register !== undefined ?
                                            <Link to={'/register/2/' + register.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                <EmptyField />
                                            </Link> :
                                            <></>
                                    }
                                </> :
                                <></>
                            }
                        </>
                    }
                </ContentBody>
            </Content>
            {fields !== undefined && fields?.length > 0 ?
                <DialogFooter>
                    <DialogFooterCenter>
                        <BtnInsertNewAnswer className="btn-new-item" icon={AiOutlinePlus} onClick={handleAddAnswer} isLoading={loadingInsert}>
                            Novo Item
                        </BtnInsertNewAnswer>
                    </DialogFooterCenter>
                </DialogFooter> :
                <></>
            }
        </DialogContainer >
    );
};

export default NewAnswer;

